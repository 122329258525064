@charset "UTF-8";
/****************** Cores gerais ******************/
/****************** FIM Cores gerais ******************/
/****************** Alertas e notificações ******************/
/****************** FIM Alertas e notificações ******************/
/****************** Elementos formulário ******************/
/****************** FIM Elementos formulário ******************/
/****************** Datatables ******************/
/****************** FIM Datatables ******************/
/****************** Geral - Estrutura ******************/
/****************** FIM Estrutura ******************/
/****************** Menu - Imagem Pefil ******************/
/****************** FIM Menu - Imagem Pefil ******************/
/****************** Agenda ******************/
/****************** FIM Agenda ******************/
/****************** Geral - Estrutura ******************/
.left_col {
  background: #191919;
}
.left_col .mCSB_scrollTools {
  width: 6px;
}
.left_col .mCSB_dragger {
  max-height: 400px !important;
}
.nav-sm .container.body .col-md-3.left_col {
  min-height: 100%;
  width: 70px;
  padding: 0;
  z-index: 9999;
  position: absolute;
}
.nav-sm .container.body .col-md-3.left_col .mCSB_container {
  overflow: visible;
}
.nav-sm .container.body .col-md-3.left_col .mCustomScrollBox {
  overflow: visible;
}
.nav-sm .container.body .col-md-3.left_col.menu_fixed {
  position: fixed;
  height: 100%;
}
.nav-sm .container.body .right_col {
  padding: 10px 20px;
  margin-left: 70px;
  z-index: 2;
}
.nav-sm .hidden-small {
  visibility: hidden;
}
.nav-sm .navbar.nav_title {
  width: 70px;
}
.nav-sm .navbar.nav_title a span {
  display: none;
}
.nav-sm .navbar.nav_title a i {
  font-size: 27px;
  margin: 13px 0 0 3px;
}
.nav-sm .main_container .top_nav {
  display: block;
  margin-left: 70px;
  z-index: 2;
}
.nav-sm .nav.side-menu li a {
  text-align: center !important;
  font-weight: 400;
  font-size: 10px;
  padding: 10px 5px;
}
.nav-sm .nav.side-menu li a i.toggle-up {
  display: none !important;
}
.nav-sm .nav.side-menu li a i {
  font-size: 25px !important;
  text-align: center;
  margin-bottom: 5px;
}
.nav-sm .nav.side-menu li.active-sm {
  border-right: 5px solid #FDC402;
}
.nav-sm .nav.side-menu li.active-sm ul ul {
  position: static;
  width: 200px;
  background: none;
}
.nav-sm .nav.child_menu li.active {
  border-right: 5px solid #FDC402;
}
.nav-sm ul.nav.child_menu {
  left: 100%;
  position: absolute;
  top: 0;
  width: 210px;
  z-index: 4000;
  background: #191919;
  display: none;
}
.nav-sm ul.nav.child_menu ul {
  position: static;
  width: 200px;
  background: none;
}
.nav-sm ul.nav.child_menu li {
  padding: 0 10px;
}
.nav-sm ul.nav.child_menu li a {
  text-align: left !important;
}
.nav-sm > .nav.side-menu > li.active-sm > a {
  color: #1ABB9C !important;
}
.nav-sm .profile {
  display: none;
}
.nav-sm .menu_section {
  margin: 0;
}
.nav-sm .menu_section h3 {
  display: none;
}
.nav-sm span.fa {
  display: none;
}
.nav-sm li li span.fa {
  display: inline-block;
}
.nav-sm footer {
  margin-left: 70px;
}
.site_title {
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 400;
  font-size: 22px;
  width: 100%;
  color: #ECF0F1 !important;
  margin-left: 0 !important;
  line-height: 59px;
  display: block;
  height: 55px;
  margin: 0;
  /*padding-left: 10px;*/
}
.site_title i {
  border: 10px solid #EAEAEA;
  padding: 5px 6px;
  border-radius: 50%;
}
.site_title:hover {
  text-decoration: none;
}
.site_title:focus {
  text-decoration: none;
}
.site_title img {
  /*margin: 6px;*/
  /*max-height: 85%;*/
  text-align: center;
  margin: 0 auto;
}
.menu_section {
  margin-bottom: 35px;
}
.menu_section h3 {
  padding-left: 23px;
  color: #EDEDED;
  text-transform: uppercase;
  letter-spacing: .5px;
  font-weight: bold;
  font-size: 11px;
  margin-bottom: 0;
  margin-top: 0;
  text-shadow: none;
}
.menu_section > ul {
  margin-top: 10px;
}
.profile_pic {
  width: 35%;
  float: left;
}
.img-circle.profile_img {
  width: 70%;
  background: #FFFFFF;
  margin-left: 15%;
  z-index: 1000;
  position: inherit;
  margin-top: 20px;
  border: 1px solid rgba(52, 73, 94, 0.44);
  padding: 4px;
}
.profile_info {
  padding: 25px 10px 10px;
  width: 65%;
  float: left;
}
.profile_info span {
  font-size: 13px;
  line-height: 30px;
  color: #BAB8B8;
}
.profile_info h2 {
  font-size: 14px;
  color: #ECF0F1;
  margin: 0;
  font-weight: 300;
}
.profile.img_2 {
  text-align: center;
}
.profile.img_2 .profile_pic {
  width: 100%;
}
.profile.img_2 .profile_pic .img-circle.profile_img {
  width: 50%;
  margin: 10px 0 0;
}
.profile.img_2 .profile_info {
  padding: 15px 10px 0;
  width: 100%;
  margin-bottom: 10px;
  float: left;
}
.main_menu span.fa {
  float: right;
  text-align: center;
  margin-top: 5px;
  font-size: 10px;
  min-width: inherit;
  color: #FDC402;
}
.main_menu .fa {
  width: 26px;
  opacity: .99;
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.active a span.fa {
  text-align: right !important;
  margin-right: 4px;
}
.nav_menu {
  float: left;
  background: #EDEDED;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}
.nav-md .container.body .col-md-3.left_col {
  min-height: 100%;
  width: 230px;
  padding: 0;
  position: absolute;
  display: -ms-flexbox;
  display: flex;
  z-index: 1;
}
.nav-md .container.body .col-md-3.left_col.menu_fixed {
  height: 100%;
  position: fixed;
}
.nav-md .container.body .right_col {
  padding: 10px 20px 0;
  margin-left: 230px;
}
.nav-md ul.nav.child_menu li:before {
  background: #3f3f3f;
  bottom: auto;
  content: "";
  height: 8px;
  left: 23px;
  margin-top: 15px;
  position: absolute;
  right: auto;
  width: 8px;
  z-index: 1;
  border-radius: 50%;
}
.nav-md ul.nav.child_menu li:after {
  border-left: 1px solid #3f3f3f;
  bottom: 0;
  content: "";
  left: 27px;
  position: absolute;
  top: 0;
}
body {
  color: #3f2f00;
  background: #191919;
  font-family: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
}
body .container.body .right_col {
  background: #fafafa;
}
.nav_title {
  width: 230px;
  float: left;
  background: #191919;
  border-radius: 0;
  height: 58px;
}
.blue {
  color: #3498DB;
}
.purple {
  color: #9B59B6;
}
.green {
  color: #1ABB9C;
}
.aero {
  color: #9CC2CB;
}
.red {
  color: #E74C3C;
}
.dark {
  color: #34495E;
}
.orange {
  color: #F39C12;
}
.border-blue {
  border-color: #3498DB !important;
}
.border-purple {
  border-color: #9B59B6 !important;
}
.border-green {
  border-color: #1ABB9C !important;
}
.border-aero {
  border-color: #9CC2CB !important;
}
.border-red {
  border-color: #E74C3C !important;
}
.border-dark {
  border-color: #34495E !important;
}
.bg-white {
  background: #FFFFFF !important;
  border: 1px solid #FFFFFF !important;
  color: #3f2f00;
}
.bg-green {
  background: #1ABB9C !important;
  border: 1px solid #1ABB9C !important;
  color: #EDEDED;
}
.bg-red {
  background: #E74C3C !important;
  border: 1px solid #E74C3C !important;
  color: #EDEDED;
}
.bg-blue {
  background: #3498DB !important;
  border: 1px solid #3498DB !important;
  color: #EDEDED;
}
.bg-orange {
  background: #F39C12 !important;
  border: 1px solid #F39C12 !important;
  color: #EDEDED;
}
.bg-purple {
  background: #9B59B6 !important;
  border: 1px solid #9B59B6 !important;
  color: #EDEDED;
}
.bg-blue-sky {
  background: #50C1CF !important;
  border: 1px solid #50C1CF !important;
  color: #EDEDED;
}
.bg-aero {
  background: #9CC2CB !important;
  border: 1px solid #9CC2CB !important;
  color: #3f2f00;
}
.bg-planocontas {
  background: #F2F5F7 !important;
  border: 1px solid #F2F5F7 !important;
  color: #3f2f00;
}
.bg-white {
  background: #FFFFFF !important;
  border: 1px solid #FFFFFF !important;
  color: #EDEDED;
}
.container {
  width: 100%;
  padding: 0;
}
.navbar-nav > li > a {
  color: #EDEDED !important;
  font-weight: 500;
  color: #ECF0F1 !important;
  margin-left: 0 !important;
  line-height: 32px;
}
.navbar-nav .open .dropdown-menu {
  position: absolute;
  background: #FFFFFF;
  margin-top: 0;
  border: 1px solid #D9DEE4;
  -webkit-box-shadow: none;
  right: 0;
  left: auto;
}
.navbar-nav .open .dropdown-menu.msg_list {
  width: 300px;
}
.navbar-brand {
  color: #EDEDED !important;
  font-weight: 500;
  color: #ECF0F1 !important;
  margin-left: 0 !important;
  line-height: 32px;
}
.top_nav .nav > li > a:focus {
  color: #333;
  background: rgba(0, 0, 0, 0.07);
}
.top_nav .nav > li > a:hover {
  color: #333;
  background: rgba(0, 0, 0, 0.07);
}
.top_nav .nav .open > a {
  background: #333;
}
.top_nav .nav .open > a:focus {
  background: rgba(0, 0, 0, 0.07);
}
.top_nav .nav .open > a:hover {
  background: rgba(0, 0, 0, 0.07);
}
.top_nav .navbar-right {
  margin: 0;
  width: 70%;
  float: right;
}
.top_nav .navbar-right li {
  display: inline-block;
  float: right;
  position: static;
}
.top_nav .dropdown-menu li {
  width: 100%;
}
.top_nav .dropdown-menu li a {
  width: 100%;
  padding: 12px 20px;
}
.top_nav .dropdown-menu li.active {
  width: 100%;
}
.top_nav .dropdown-menu li.active a {
  color: #3f2f00;
  text-decoration: none;
  background-color: #FDC402;
  outline: 0;
}
.top_nav li a i {
  font-size: 15px;
}
.main_container .top_nav {
  display: block;
  margin-left: 230px;
}
.no-padding {
  padding: 0 !important;
}
.page-title {
  width: 100%;
  height: 65px;
  padding: 10px 0;
}
.page-title .title_left {
  width: 45%;
  float: left;
  display: block;
}
.page-title .title_left h3 {
  margin: 9px 0;
}
.page-title .title_right {
  width: 55%;
  float: left;
  display: block;
}
.page-title .title_right .pull-right {
  margin: 10px 0;
}
.fixed_height_320 {
  height: 320px;
}
.fixed_height_390 {
  height: 390px;
}
.fixed_height_200 {
  height: 200px;
}
.overflow_hidden {
  overflow: hidden;
}
.progress-bar-dark {
  background-color: #34495E !important;
}
.progress-bar-gray {
  background-color: #BDC3C7 !important;
}
table.no-margin .progress {
  margin-bottom: 0;
}
.main_content {
  padding: 10px 20px;
}
.col-md-55 {
  width: 50%;
  margin-bottom: 10px;
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.center-margin {
  margin: 0 auto;
  float: none !important;
}
.col-xs-1 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-sm-1 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-1 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-1 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-2 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-sm-2 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-2 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-2 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-3 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-sm-3 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-3 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-3 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-4 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-sm-4 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-4 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-4 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-5 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-sm-5 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-5 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-5 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-6 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-sm-6 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-6 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-6 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-7 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-sm-7 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-7 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-7 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-8 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-sm-8 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-8 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-8 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-9 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-sm-9 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-9 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-9 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-10 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-sm-10 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-10 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-10 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-11 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-sm-11 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-11 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-11 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-xs-12 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-sm-12 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-md-12 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.col-lg-12 {
  position: relative;
  min-height: 1px;
  float: left;
  padding-right: 10px;
  padding-left: 10px;
}
.row {
  margin-right: -10px;
  margin-left: -10px;
}
.grid_slider .col-md-6 {
  padding: 0 40px;
}
h1 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.h1 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h2 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: 400;
}
.h2 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.h3 {
  margin-top: 10px;
  margin-bottom: 10px;
}
a {
  color: #3f2f00;
  text-decoration: none;
  outline: 0;
}
a:visited {
  outline: 0;
}
a:focus {
  outline: 0;
  text-decoration: none;
  outline: none;
}
a:active {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
:visited {
  outline: 0;
}
:focus {
  outline: 0;
}
:active {
  outline: 0;
}
.btn {
  margin-bottom: 5px;
  margin-right: 5px;
  border-radius: 0px;
}
.btn:focus {
  outline: 0;
}
.btn:active:focus {
  outline: 0;
}
.btn:active.focus {
  outline: 0;
}
.btn.active:focus {
  outline: 0;
}
.btn.focus {
  outline: 0;
}
.btn.active.focus {
  outline: 0;
}
.navbar {
  margin-bottom: 0;
}
.navbar-header {
  background: #34495E;
}
.navbar-right {
  margin-right: 0;
}
.navbar-static-top {
  position: fixed;
  top: 0;
  width: 100%;
}
.sidebar-header {
  border-bottom: 0;
  margin-top: 46px;
}
.sidebar-header:first-of-type {
  margin-top: 0;
}
.nav.side-menu > li {
  position: relative;
  display: block;
  cursor: pointer;
}
.nav.side-menu > li > a {
  margin-bottom: 6px;
  color: #E7E7E7;
  font-weight: 500;
}
.nav.side-menu > li > a:hover {
  color: #f4f4f4 !important;
  text-decoration: none;
  background: transparent;
}
.nav.side-menu > li.current-page {
  border-right: 5px solid #FDC402;
}
.nav.side-menu > li.active {
  border-right: 5px solid #FDC402;
}
.nav.side-menu > li.active > a {
  text-shadow: rgba(0, 0, 0, 0.25) 0 -1px 0;
  background: linear-gradient(#333333, #262626), #191919;
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
  color: #FFF;
}
.nav.side-menu > li.active > a:hover {
  color: #FFF !important;
  text-decoration: none;
}
.nav > li > a {
  position: relative;
  display: block;
  padding: 13px 15px 12px;
}
.nav > li > a:focus {
  text-decoration: none;
  background: transparent;
  background-color: transparent;
}
.nav > li > a:hover {
  background-color: transparent;
}
.nav li.current-page {
  background: #333;
}
.nav li.current-page a {
  color: #FFF;
}
.nav li li li.current-page {
  background: none;
}
.nav li li.current-page a {
  color: #EDEDED;
}
.nav.child_menu {
  display: none;
}
.nav.child_menu li {
  padding-left: 36px;
}
.nav.child_menu li:hover {
  background-color: #333;
}
.nav.child_menu li:hover a {
  color: #FFF;
}
.nav.child_menu li li:hover {
  background: none;
}
.nav.child_menu li li a:hover {
  color: #EDEDED;
}
.nav.child_menu li li a.active {
  color: #EDEDED;
}
.nav.child_menu li li.active {
  background: none;
}
.nav.child_menu li.active {
  background-color: #333;
}
.nav.child_menu li.current-page a {
  color: #FFF;
}
.nav.child_menu > li > a {
  color: #E7E7E7;
  font-weight: 500;
  font-size: 12px;
  padding: 9px;
}
.nav.navbar-nav > li > a {
  color: #333 !important;
}
.nav.top_menu > li > a {
  position: relative;
  display: block;
  padding: 10px 15px;
  color: #34495E !important;
}
.top_search {
  padding: 0;
}
.top_search .form-control {
  border-right: 0;
  box-shadow: inset 0 1px 0px rgba(0, 0, 0, 0.075);
  border-radius: 25px 0px 0px 25px;
  padding-left: 20px;
  border: 1px solid rgba(221, 226, 232, 0.49);
}
.top_search .form-control:focus {
  border: 1px solid rgba(221, 226, 232, 0.49);
  border-right: 0;
}
.top_search .input-group-btn button {
  border-radius: 0px 25px 25px 0px;
  border: 1px solid rgba(221, 226, 232, 0.49);
  border-left: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  color: #93A2B2;
  margin-bottom: 0 !important;
  height: 38px !important;
}
.toggle {
  float: left;
  margin: 0;
  padding-top: 16px;
  width: 70px;
}
.toggle a {
  padding: 15px 15px 0;
  margin: 0;
  cursor: pointer;
  color: #333;
}
.toggle a i {
  font-size: 26px;
}
.toggle a:hover {
  color: #E7E7E7;
}
.panel_toolbox {
  float: right;
  min-width: 10px;
}
.panel_toolbox > li {
  float: left;
  cursor: pointer;
}
.panel_toolbox > li > a {
  padding: 6px;
  color: #C5C7CB;
  font-size: 14px;
}
.panel_toolbox > li > a:hover {
  background: #F5F7FA;
}
.line_30 {
  line-height: 30px;
}
.main_menu_side {
  padding: 0;
}
.bs-docs-sidebar .nav > li > a {
  display: block;
  padding: 4px 6px;
}
footer {
  background: #FFFFFF;
  padding: 0px 20px;
  display: block;
  color: #3f2f00;
  height: 40px;
  vertical-align: middle;
}
footer div {
  text-align: right;
  vertical-align: middle;
  height: 40px;
  line-height: 40px;
}
.footer_fixed footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
.tile-stats.sparkline {
  padding: 10px;
  text-align: center;
}
.jqstooltip {
  background: #34495E !important;
  width: 30px !important;
  height: 22px !important;
  text-decoration: none;
}
.tooltip {
  display: block !important;
}
.tiles {
  border-top: 1px solid #ccc;
  margin-top: 15px;
  padding-top: 5px;
  margin-bottom: 0;
}
.tile {
  overflow: hidden;
}
.tile:hover {
  text-decoration: none;
}
.top_tiles {
  margin-bottom: 0;
}
.top_tiles .tile h2 {
  font-size: 30px;
  line-height: 30px;
  margin: 3px 0 7px;
  font-weight: bold;
}
article.media {
  width: 100%;
}
* {
  box-sizing: border-box;
}
*:before {
  box-sizing: border-box;
}
*:after {
  box-sizing: border-box;
}
#integration-list {
  width: 100%;
  margin: 0 auto;
  display: table;
}
#integration-list ul {
  padding: 0;
  margin: 20px 0;
  color: #555;
}
#integration-list ul > li {
  list-style: none;
  border-top: 1px solid #ddd;
  display: block;
  padding: 15px;
  overflow: hidden;
}
#integration-list ul > li:hover {
  background: #efefef;
}
#integration-list ul:last-child {
  border-bottom: 1px solid #ddd;
}
.expand {
  display: block;
  text-decoration: none;
  color: #555;
  cursor: pointer;
}
.expand h2 {
  width: 85%;
  float: left;
}
#left {
  display: table;
}
#right {
  display: table;
}
#sup {
  display: table-cell;
  vertical-align: middle;
  width: 80%;
}
.detail {
  margin: 10px 0 10px 0px;
  display: none;
  line-height: 22px;
  height: 150px;
}
.detail a {
  text-decoration: none;
  color: #C0392B;
  border: 1px solid #C0392B;
  padding: 6px 10px 5px;
  font-size: 13px;
  margin-right: 7px;
}
.detail span {
  margin: 0;
}
.right-arrow {
  width: 10px;
  float: right;
  font-weight: bold;
  font-size: 20px;
}
.accordion .panel {
  margin-bottom: 5px;
  border-radius: 0;
  border-bottom: 1px solid #efefef;
}
.accordion .panel:hover {
  background: #F2F5F7;
}
.accordion .panel-heading {
  background: #F2F5F7;
  padding: 13px;
  width: 100%;
  display: block;
}
.x_panel {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 17px;
  display: inline-block;
  background: #FFFFFF;
  border: 1px solid #E1E1E1;
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  column-break-inside: avoid;
  opacity: 1;
  transition: all .2s ease;
}
.x_title {
  border-bottom: 2px solid #E1E1E1;
  padding: 1px 5px 6px;
  margin-bottom: 10px;
}
.x_title .filter {
  width: 40%;
  float: right;
}
.x_title h2 {
  margin: 5px 0 6px;
  float: left;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-height: 20px;
}
.x_title h2 small {
  margin-left: 10px;
}
.x_title span {
  color: #BDBDBD;
}
.x_content {
  padding: 0 5px 6px;
  position: relative;
  width: 100%;
  float: left;
  clear: both;
  margin-top: 5px;
}
.x_content h4 {
  font-size: 16px;
  font-weight: 500;
}
legend {
  padding-bottom: 7px;
  font-size: 18px;
  color: inherit;
}
.demo-placeholder {
  height: 280px;
}
.profile_details:nth-child(3n) {
  clear: both;
}
.profile_details .profile_view {
  display: inline-block;
  padding: 10px 0 0;
  background: #FFFFFF;
}
.profile_details .profile_view .divider {
  border-top: 1px solid #e5e5e5;
  padding-top: 5px;
  margin-top: 5px;
}
.profile_details .profile_view .ratings {
  margin-bottom: 0;
  text-align: left;
  font-size: 16px;
}
.profile_details .profile_view .bottom {
  background: #F2F5F7;
  padding: 9px 0;
  border-top: 1px solid #E6E9ED;
}
.profile_details .profile_view .left {
  margin-top: 20px;
}
.profile_details .profile_view .left p {
  margin-bottom: 3px;
}
.profile_details .profile_view .right {
  margin-top: 0px;
  padding: 10px;
}
.profile_details .profile_view .img-circle {
  border: 1px solid #E6E9ED;
  padding: 2px;
}
.profile_details .profile_view h2 {
  margin: 5px 0;
}
.profile_details .profile_view .brief {
  margin: 0;
  font-weight: 300;
}
.profile_details .profile_left {
  background: white;
}
.pagination.pagination-split li {
  display: inline-block;
  margin-right: 3px;
}
.pagination.pagination-split li a {
  border-radius: 4px;
  color: #768399;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
}
table.tile h3 {
  font-weight: bold;
  vertical-align: middle !important;
}
table.tile h4 {
  font-weight: bold;
  vertical-align: middle !important;
}
table.tile span {
  font-weight: bold;
  vertical-align: middle !important;
}
table.tile th {
  text-align: center;
  border-bottom: 1px solid #E6ECEE;
}
table.tile td {
  text-align: center;
  padding: 5px 0;
}
table.tile td ul {
  text-align: left;
  padding-left: 0;
}
table.tile td ul li {
  list-style: none;
  width: 100%;
}
table.tile td ul li a {
  width: 100%;
}
table.tile td ul li a big {
  right: 0;
  float: right;
  margin-right: 13px;
}
table.tile_info {
  width: 100%;
  padding: 10px 15px;
}
table.tile_info td {
  text-align: left;
  padding: 1px;
  font-size: 15px;
}
table.tile_info td p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  line-height: 28px;
}
table.tile_info td i {
  margin-right: 8px;
  font-size: 17px;
  float: left;
  width: 18px;
  line-height: 28px;
}
table.tile_info td:first-child {
  width: 83%;
}
table.tile_info span.right {
  margin-right: 0;
  float: right;
  position: absolute;
  right: 4%;
}
td span {
  line-height: 28px;
}
.sidebar-widget {
  overflow: hidden;
}
.error-number {
  font-size: 90px;
  line-height: 90px;
  margin: 20px 0;
}
.col-middle {
  margin-top: 5%;
}
.mid_center {
  width: 370px;
  margin: 0 auto;
  text-align: center;
  padding: 10px 20px;
}
h3.degrees {
  font-size: 22px;
  font-weight: 400;
  text-align: center;
}
.degrees:after {
  content: "o";
  position: relative;
  top: -12px;
  font-size: 13px;
  font-weight: 300;
}
.daily-weather .day {
  font-size: 14px;
  border-top: 2px solid rgba(115, 135, 156, 0.36);
  text-align: center;
  border-bottom: 2px solid rgba(115, 135, 156, 0.36);
  padding: 5px 0;
}
.weather-days .col-sm-2 {
  overflow: hidden;
  width: 16.66666667%;
}
.weather .row {
  margin-bottom: 0;
}
.bulk-actions {
  display: none;
}
table.countries_list {
  width: 100%;
}
table.countries_list td {
  padding: 0 10px;
  line-height: 30px;
  border-top: 1px solid #eeeeee;
}
.dataTables_paginate {
  float: right;
  text-align: right;
}
.dataTables_paginate a {
  padding: 6px 9px !important;
  background: #ddd !important;
  border-color: #ddd !important;
  background: #ff0000;
}
.paging_full_numbers {
  width: 400px;
  height: 22px;
  line-height: 22px;
}
.paging_full_numbers a.paginate_active {
  background-color: rgba(38, 185, 154, 0.59) !important;
  border-color: rgba(38, 185, 154, 0.59) !important;
  border: 1px solid #aaa;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 2px 5px;
  margin: 0 3px;
  cursor: pointer;
  background-color: #99B3FF;
}
.paging_full_numbers a:active {
  outline: none;
}
.paging_full_numbers a:hover {
  text-decoration: none;
}
.paging_full_numbers a.paginate_button {
  border: 1px solid #aaa;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 2px 5px;
  margin: 0 3px;
  cursor: pointer;
  background-color: #ddd;
}
.paging_full_numbers a.paginate_button:hover {
  background-color: #ccc;
  text-decoration: none !important;
}
.paging_full_numbers a.paginate_button.current {
  font-weight: bold;
  background-color: #191919 !important;
}
button.DTTT_button {
  border: 1px solid #E7E7E7 !important;
  background: #E7E7E7 !important;
  box-shadow: none !important;
}
div.DTTT_button {
  border: 1px solid #E7E7E7 !important;
  background: #E7E7E7 !important;
  box-shadow: none !important;
}
a.DTTT_button {
  border: 1px solid #E7E7E7 !important;
  background: #E7E7E7 !important;
  box-shadow: none !important;
}
table.jambo_table {
  border: 1px solid rgba(221, 221, 221, 0.78);
}
table.jambo_table thead {
  background: rgba(52, 73, 94, 0.94);
  color: #ECF0F1;
}
table.jambo_table tbody tr:hover td {
  background: rgba(38, 185, 154, 0.07);
  border-top: 1px solid rgba(38, 185, 154, 0.11);
  border-bottom: 1px solid rgba(38, 185, 154, 0.11);
}
table.jambo_table tbody tr.selected {
  background: rgba(38, 185, 154, 0.16);
}
table.jambo_table tbody tr.selected td {
  border-top: 1px solid rgba(38, 185, 154, 0.4);
  border-bottom: 1px solid rgba(38, 185, 154, 0.4);
}
.dataTables_wrapper {
  position: relative;
  clear: both;
  zoom: 1;
}
.dataTables_wrapper .dataTables_processing {
  position: absolute;
  top: 50% !important;
  left: 0% !important;
  width: 100% !important;
  height: 50px !important;
  margin-left: 0px !important;
  margin-top: -35px !important;
  padding: 1em 0;
  border: 1px solid #ddd;
  text-align: center;
  color: #999;
  font-size: 14px;
  background-color: #EDEDED;
  z-index: 9999;
}
.dataTables_processing {
  position: absolute;
  top: 50% !important;
  left: 0% !important;
  width: 100% !important;
  height: 50px !important;
  margin-left: 0px !important;
  margin-top: -35px !important;
  padding: 1em 0;
  border: 1px solid #ddd;
  text-align: center;
  color: #999;
  font-size: 14px;
  background-color: #EDEDED;
  z-index: 9999;
}
.dataTables_length {
  width: 40%;
  float: left;
}
.dataTables_filter {
  width: 50%;
  float: right;
  text-align: right;
  display: none !important;
}
.dataTables_info {
  width: 60%;
  float: left;
}
table.dataTable th.focus {
  outline: 2px solid #1ABB9C !important;
  outline-offset: -1px;
}
table.dataTable td.focus {
  outline: 2px solid #1ABB9C !important;
  outline-offset: -1px;
}
table.display {
  margin: 0 auto;
  clear: both;
  width: 100%;
}
table.display thead th {
  padding: 8px 18px 8px 10px;
  border-bottom: 1px solid black;
  font-weight: bold;
  cursor: pointer;
}
table.display thead th:active {
  outline: none;
}
table.display thead td:active {
  outline: none;
}
table.display tfoot th {
  padding: 3px 18px 3px 10px;
  border-top: 1px solid black;
  font-weight: bold;
}
table.display tr.heading2 td {
  border-bottom: 1px solid #aaa;
}
table.display td {
  padding: 3px 10px;
}
table.display td.center {
  text-align: center;
}
table.display tr.even td {
  background-color: #FFFFFF;
}
table.display tr.odd td {
  background-color: #F9F9F9;
}
table.display tr.even.row_selected td {
  background-color: #EDEDED;
}
table.display tr.odd.row_selected td {
  background-color: #EDEDED;
}
.dataTables_scroll {
  clear: both;
}
.dataTables_scrollBody {
  *margin-top: -1px;
  -webkit-overflow-scrolling: touch;
}
.top .dataTables_info {
  float: none;
}
.clear {
  clear: both;
}
.dataTables_empty {
  text-align: center;
}
tfoot input {
  margin: 0.5em 0;
  width: 100%;
  color: #444;
}
tfoot input.search_init {
  color: #999;
}
td.group {
  background-color: #d1cfd0;
  border-bottom: 2px solid #A19B9E;
  border-top: 2px solid #A19B9E;
}
td.details {
  background-color: #d1cfd0;
  border: 2px solid #A19B9E;
}
.example_alt_pagination div.dataTables_info {
  width: 40%;
}
div.box {
  height: 100px;
  padding: 10px;
  overflow: auto;
  border: 1px solid #8080FF;
  background-color: #E5E5FF;
}
ul.msg_list li {
  background: #f7f7f7;
  padding: 5px;
  display: -ms-flexbox;
  display: flex;
  margin: 6px 6px 0;
  width: 96% !important;
}
ul.msg_list li:last-child {
  margin-bottom: 6px;
  padding: 10px;
}
ul.msg_list li a {
  /*padding: 3px 5px !important;*/
}
ul.msg_list li a .image img {
  border-radius: 2px 2px 2px 2px;
  -webkit-border-radius: 2px 2px 2px 2px;
  float: left;
  margin-right: 10px;
  width: 11%;
}
ul.msg_list li a .time {
  font-size: 11px;
  font-style: italic;
  font-weight: bold;
  position: absolute;
  right: 35px;
}
ul.msg_list li a .message {
  display: block !important;
  font-size: 11px;
}
.dropdown-menu.msg_list span {
  white-space: normal;
}
.dropdown-menu {
  border: medium none;
  box-shadow: none;
  display: none;
  float: left;
  font-size: 12px;
  left: 0;
  list-style: none outside none;
  padding: 0;
  position: absolute;
  text-shadow: none;
  top: 100%;
  z-index: 9998;
  border: 1px solid #D9DEE4;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.dropdown-menu > li > a {
  color: #3f2f00;
  padding-top: 8px;
  padding-bottom: 8px;
}
.dropdown-menu > li > a:hover {
  background-color: #333;
  color: #FFF;
}
.dropdown-menu a {
  cursor: pointer;
}
.info-number .badge {
  font-size: 10px;
  font-weight: normal;
  line-height: 13px;
  padding: 2px 6px;
  position: absolute;
  right: 2px;
  top: 8px;
}
ul.to_do {
  padding: 0;
}
ul.to_do li {
  background: #f3f3f3;
  border-radius: 3px;
  position: relative;
  padding: 7px;
  margin-bottom: 5px;
  list-style: none;
}
ul.to_do p {
  margin: 0;
}
.dashboard-widget {
  background: #f6f6f6;
  border-top: 5px solid #79C3DF;
  border-radius: 3px;
  padding: 5px 10px 10px;
}
.dashboard-widget .dashboard-widget-title {
  font-weight: normal;
  border-bottom: 1px solid #c1cdcd;
  margin: 0 0 10px 0;
  padding-bottom: 5px;
  padding-left: 40px;
  line-height: 30px;
}
.dashboard-widget .dashboard-widget-title i {
  font-size: 100%;
  margin-left: -35px;
  margin-right: 10px;
  color: #33a1c9;
  padding: 3px 6px;
  border: 1px solid #abd9ea;
  border-radius: 5px;
  background: #FFFFFF;
}
ul.quick-list {
  width: 45%;
  padding-left: 0;
  display: inline-block;
}
ul.quick-list li {
  padding-left: 10px;
  list-style: none;
  margin: 0;
  padding-bottom: 6px;
  padding-top: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
ul.quick-list li i {
  padding-right: 10px;
  color: #757679;
}
.dashboard-widget-content {
  padding-top: 9px;
}
.dashboard-widget-content .sidebar-widget {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  background: #FFFFFF;
  border: 1px solid #abd9ea;
  border-radius: 5px;
  text-align: center;
  float: right;
  padding: 2px;
  margin-top: 10px;
}
.widget_summary {
  width: 100%;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.widget_summary .w_left {
  float: left;
  text-align: left;
}
.widget_summary .w_center {
  float: left;
}
.widget_summary .w_right {
  float: left;
  text-align: right;
}
.widget_summary .w_right span {
  font-size: 20px;
}
.w_20 {
  width: 20%;
}
.w_25 {
  width: 25%;
}
.w_55 {
  width: 55%;
}
h5.graph_title {
  text-align: left;
  margin-left: 10px;
}
h5.graph_title i {
  margin-right: 10px;
  font-size: 17px;
}
span.right {
  float: right;
  font-size: 14px !important;
}
.tile_info a {
  text-overflow: ellipsis;
}
.sidebar-footer {
  bottom: 0px;
  clear: both;
  display: block;
  padding: 5px 0 0 0;
  position: fixed;
  width: 230px;
  line-height: 1.471;
  background: #191919;
}
.sidebar-footer a {
  padding: 7px 0 3px;
  text-align: center;
  width: 33.3333%;
  font-size: 17px;
  display: block;
  float: left;
  background: #000000;
  color: #FFF;
}
.sidebar-footer a:hover {
  background: #333;
}
.tile_count {
  margin-bottom: 20px;
  margin-top: 20px;
}
.tile_count .tile_stats_count {
  border-bottom: 1px solid #D9DEE4;
  padding: 0 10px 0 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}
.tile_count .tile_stats_count:before {
  content: "";
  position: absolute;
  left: 0;
  height: 65px;
  border-left: 2px solid #ADB2B5;
  margin-top: 10px;
}
.tile_count .tile_stats_count .count {
  font-size: 30px;
  line-height: 47px;
  font-weight: 600;
}
.tile_count .tile_stats_count span {
  font-size: 12px;
}
.tile_count .tile_stats_count .count_bottom i {
  width: 12px;
}
.dashboard_graph {
  background: #FFFFFF;
  padding: 7px 10px;
}
.dashboard_graph .col-md-9 {
  padding: 0;
}
.dashboard_graph .col-md-3 {
  padding: 0;
}
.dashboard_graph .x_title {
  padding: 5px 5px 7px;
}
.dashboard_graph .x_title h3 {
  margin: 0;
  font-weight: normal;
}
.dashboard_graph p {
  margin: 0 0 4px;
}
a.user-profile {
  color: #333 !important;
}
.user-profile img {
  width: 29px;
  height: 29px;
  border-radius: 50%;
  margin-right: 10px;
}
ul.top_profiles {
  height: 330px;
  width: 100%;
}
ul.top_profiles li {
  margin: 0;
  padding: 3px 5px;
}
ul.top_profiles li:nth-child(odd) {
  background-color: #eee;
}
.media .profile_thumb {
  border: 1px solid;
  width: 50px;
  height: 50px;
  margin: 5px 10px 5px 0;
  border-radius: 50%;
  padding: 9px 12px;
}
.media .profile_thumb i {
  font-size: 30px;
}
.media .date {
  background: #ccc;
  width: 52px;
  margin-right: 10px;
  border-radius: 10px;
  padding: 5px;
}
.media .date .month {
  margin: 0;
  text-align: center;
  color: #EDEDED;
}
.media .date .day {
  text-align: center;
  color: #EDEDED;
  font-size: 27px;
  margin: 0;
  line-height: 27px;
  font-weight: bold;
}
.event .media-body a.title {
  font-weight: bold;
}
.event .media-body p {
  margin-bottom: 0;
}
h4.graph_title {
  margin: 7px;
  text-align: center;
}
.fontawesome-icon-list .fa-hover a {
  display: block;
  line-height: 32px;
  height: 32px;
  padding-left: 10px;
  border-radius: 4px;
}
.fontawesome-icon-list .fa-hover a:hover {
  background-color: #ddd;
  color: #EDEDED;
  text-decoration: none;
}
.fontawesome-icon-list .fa-hover a:hover .fa {
  font-size: 28px;
  vertical-align: -6px;
}
.fontawesome-icon-list .fa-hover a .fa {
  width: 32px;
  font-size: 16px;
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}
.tile-stats {
  position: relative;
  display: block;
  margin-bottom: 12px;
  border: 1px solid #E4E4E4;
  -webkit-border-radius: 5px;
  overflow: hidden;
  padding-bottom: 5px;
  -webkit-background-clip: padding-box;
  -moz-border-radius: 5px;
  -moz-background-clip: padding;
  border-radius: 5px;
  background-clip: padding-box;
  background: #FFFFFF;
  transition: all 300ms ease-in-out;
}
.tile-stats:hover .icon i {
  animation-name: tansformAnimation;
  animation-duration: .5s;
  animation-iteration-count: 1;
  color: rgba(58, 58, 58, 0.41);
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  -webkit-animation-name: tansformAnimation;
  -webkit-animation-duration: .5s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-name: tansformAnimation;
  -moz-animation-duration: .5s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: ease;
  -moz-animation-fill-mode: forwards;
}
.tile-stats .icon {
  width: 20px;
  height: 20px;
  color: #BAB8B8;
  position: absolute;
  right: 53px;
  top: 22px;
  z-index: 1;
}
.tile-stats .icon i {
  margin: 0;
  font-size: 60px;
  line-height: 0;
  vertical-align: bottom;
  padding: 0;
}
.tile-stats .count {
  font-size: 38px;
  font-weight: bold;
  line-height: 1.65857;
  position: relative;
  margin: 0;
  margin-left: 10px;
  z-index: 5;
  padding: 0;
}
.tile-stats h3 {
  position: relative;
  margin: 0;
  margin-left: 10px;
  z-index: 5;
  padding: 0;
  color: #BAB8B8;
}
.tile-stats p {
  position: relative;
  margin: 0;
  margin-left: 10px;
  z-index: 5;
  padding: 0;
  margin-top: 5px;
  font-size: 12px;
}
.tile-stats > .dash-box-footer {
  position: relative;
  text-align: center;
  margin-top: 5px;
  padding: 3px 0;
  color: #EDEDED;
  color: rgba(255, 255, 255, 0.8);
  display: block;
  z-index: 10;
  background: rgba(0, 0, 0, 0.1);
  text-decoration: none;
}
.tile-stats > .dash-box-footer:hover {
  color: #EDEDED;
  background: rgba(0, 0, 0, 0.15);
}
.tile_header {
  border-bottom: transparent;
  padding: 7px 15px;
  margin-bottom: 15px;
  background: #E7E7E7;
}
.tile_head h4 {
  margin-top: 0;
  margin-bottom: 5px;
}
.tiles-bottom {
  padding: 5px 10px;
  margin-top: 10px;
  background: rgba(194, 194, 194, 0.3);
  text-align: left;
}
a.star {
  color: #428bca !important;
}
.mail_content {
  background: none repeat scroll 0 0 #FFFFFF;
  border-radius: 4px;
  margin-top: 20px;
  min-height: 500px;
  padding: 10px 11px;
  width: 100%;
}
.list-btn-mail {
  margin-bottom: 15px;
}
.list-btn-mail > i {
  float: left;
  font-size: 18px;
  font-style: normal;
  width: 33px;
}
.list-btn-mail > .cn {
  background: none repeat scroll 0 0 #39B3D7;
  border-radius: 12px;
  color: #FFFFFF;
  float: right;
  font-style: normal;
  padding: 0 5px;
}
.list-btn-mail.active {
  border-bottom: 1px solid #39B3D7;
  padding: 0 0 14px;
}
.button-mail {
  margin: 0 0 15px !important;
  text-align: left;
  width: 100%;
}
button {
  margin-bottom: 5px;
  margin-right: 5px;
}
.buttons {
  margin-bottom: 5px;
  margin-right: 5px;
}
.modal-footer .btn + .btn {
  margin-bottom: 5px;
  margin-right: 5px;
}
.btn-group-vertical .btn {
  margin-bottom: 0;
  margin-right: 0;
}
.btn-group .btn {
  margin-bottom: 0;
  margin-right: 0;
}
.btn-group .parsley-errors-list {
  display: none;
}
.mail_list_column {
  border-left: 1px solid #DBDBDB;
}
.mail_view {
  border-left: 1px solid #DBDBDB;
}
.mail_list {
  width: 100%;
  border-bottom: 1px solid #DBDBDB;
  margin-bottom: 2px;
  display: inline-block;
}
.mail_list .left {
  width: 5%;
  float: left;
  margin-right: 3%;
}
.mail_list .right {
  width: 90%;
  float: left;
}
.mail_list h3 {
  font-size: 15px;
  font-weight: bold;
  margin: 0px 0 6px;
}
.mail_list h3 small {
  float: right;
  color: #ADABAB;
  font-size: 11px;
  line-height: 20px;
}
.mail_list .badge {
  padding: 3px 6px;
  font-size: 8px;
  background: #BAB7B7;
}
.mail_heading h4 {
  font-size: 18px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-top: 20px;
}
.attachment {
  margin-top: 30px;
}
.attachment ul {
  width: 100%;
  list-style: none;
  padding-left: 0;
  display: inline-block;
  margin-bottom: 30px;
}
.attachment ul li {
  float: left;
  width: 150px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.attachment ul li img {
  height: 150px;
  border: 1px solid #ddd;
  padding: 5px;
  margin-bottom: 10px;
}
.attachment ul li span {
  float: right;
}
.attachment .file-name {
  float: left;
}
.attachment .links {
  width: 100%;
  display: inline-block;
}
.compose {
  padding: 0;
  position: fixed;
  bottom: 0;
  right: 0;
  background: #FFFFFF;
  border: 1px solid #D9DEE4;
  border-right: 0;
  border-bottom: 0;
  border-top-left-radius: 5px;
  z-index: 9999;
  display: none;
}
.compose .compose-header {
  padding: 5px;
  background: #169F85;
  color: #EDEDED;
  border-top-left-radius: 5px;
}
.compose .compose-header .close {
  text-shadow: 0 1px 0 #FFFFFF;
  line-height: .8;
}
.compose .compose-body .editor.btn-toolbar {
  margin: 0;
}
.compose .compose-body .editor-wrapper {
  height: 100%;
  min-height: 50px;
  max-height: 180px;
  border-radius: 0;
  border-left: none;
  border-right: none;
  overflow: auto;
}
.compose .compose-footer {
  padding: 10px;
}
.editor.btn-toolbar {
  zoom: 1;
  background: #F7F7F7;
  margin: 5px 2px;
  padding: 3px 0;
  border: 1px solid #EFEFEF;
}
.input-group {
  /*margin-bottom: 10px;*/
}
.ln_solid {
  border-top: 1px solid #e5e5e5;
  color: #FFFFFF;
  background-color: #FFFFFF;
  height: 1px;
  margin: 20px 0;
}
span.section {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}
.form-control {
  border-radius: 0;
  width: 100%;
}
.form-control:focus {
  border-color: #CCD0D7;
  box-shadow: none !important;
}
.form-horizontal .control-label {
  padding-top: 8px;
}
.form-horizontal .form-group {
  margin-right: 0;
  margin-left: 0;
}
.form-control-feedback {
  margin-top: 8px;
  height: 23px;
  color: #bbb;
  line-height: 24px;
  font-size: 15px;
}
.form-control-feedback.left {
  border-right: 1px solid #ccc;
  left: 13px;
}
.form-control-feedback.right {
  border-left: 1px solid #ccc;
  right: 13px;
}
.form-control.has-feedback-left {
  padding-left: 45px;
}
.form-control.has-feedback-right {
  padding-right: 45px;
}
.form-group {
  margin-bottom: 10px;
}
.validate {
  margin-top: 10px;
}
.invalid-form-error-message {
  margin-top: 10px;
  padding: 5px;
}
.invalid-form-error-message.filled {
  border-left: 2px solid #E74C3C;
}
p.parsley-success {
  color: #468847;
  background-color: #DFF0D8;
  border: 1px solid #D6E9C6;
}
p.parsley-error {
  color: #B94A48;
  background-color: #F2DEDE;
  border: 1px solid #EED3D7;
}
ul.parsley-errors-list {
  list-style: none;
  color: #E74C3C;
  padding-left: 0;
}
input.parsley-error {
  background: #FAEDEC;
  border: 1px solid #E85445;
}
textarea.parsley-error {
  background: #FAEDEC;
  border: 1px solid #E85445;
}
select.parsley-error {
  background: #FAEDEC;
  border: 1px solid #E85445;
}
.bad input {
  border: 1px solid #CE5454;
  box-shadow: 0 0 4px -2px #CE5454;
  position: relative;
  left: 0;
  -moz-animation: .7s 1 shake linear;
  -webkit-animation: 0.7s 1 shake linear;
}
.bad select {
  border: 1px solid #CE5454;
  box-shadow: 0 0 4px -2px #CE5454;
  position: relative;
  left: 0;
  -moz-animation: .7s 1 shake linear;
  -webkit-animation: 0.7s 1 shake linear;
}
.bad textarea {
  border: 1px solid #CE5454;
  box-shadow: 0 0 4px -2px #CE5454;
  position: relative;
  left: 0;
  -moz-animation: .7s 1 shake linear;
  -webkit-animation: 0.7s 1 shake linear;
}
.item input {
  transition: 0.42s;
}
.item textarea {
  transition: 0.42s;
}
.item .alert {
  float: left;
  margin: 0 0 0 20px;
  padding: 3px 10px;
  color: #FFFFFF;
  border-radius: 3px 4px 4px 3px;
  background-color: #CE5454;
  max-width: 170px;
  white-space: pre;
  position: relative;
  left: -15px;
  opacity: 0;
  z-index: 1;
  transition: 0.15s ease-out;
}
.item .alert::after {
  content: '';
  display: block;
  height: 0;
  width: 0;
  border-color: transparent #CE5454 transparent transparent;
  border-style: solid;
  border-width: 11px 7px;
  position: absolute;
  left: -13px;
  top: 1px;
}
.item.bad .alert {
  left: 0;
  opacity: 1;
}
.inl-bl {
  display: inline-block;
}
.well .markup {
  background: #FFFFFF;
  color: #777;
  position: relative;
  padding: 45px 15px 15px;
  margin: 15px 0 0 0;
  background-color: #FFFFFF;
  border-radius: 0 0 4px 4px;
  box-shadow: none;
}
.well .markup::after {
  content: "Example";
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
  font-weight: bold;
  color: #bbb;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.autocomplete-suggestions {
  border: 1px solid #e4e4e4;
  background: #F4F4F4;
  cursor: default;
  overflow: auto;
}
.autocomplete-suggestions strong {
  font-weight: normal;
  color: #3399ff;
  font-weight: bolder;
}
.autocomplete-suggestion {
  padding: 2px 5px;
  font-size: 1.2em;
  white-space: nowrap;
  overflow: hidden;
}
.autocomplete-selected {
  background: #f0f0f0;
}
a.btn-success {
  color: #EDEDED;
}
a.btn-primary {
  color: #EDEDED;
}
a.btn-warning {
  color: #EDEDED;
}
a.btn-danger {
  color: #EDEDED;
}
.btn-success {
  background: #26B99A;
  border: 1px solid #169F85;
}
.btn-success:hover {
  background: #169F85;
}
.btn-success:focus {
  background: #169F85;
}
.btn-success:active {
  background: #169F85;
}
.btn-success.active {
  background: #169F85;
}
.open .dropdown-toggle.btn-success {
  background: #169F85;
}
.open .dropdown-toggle.btn-dark {
  color: #FFFFFF;
  background-color: #394D5F;
  border-color: #394D5F;
}
.btn-dark {
  color: #E9EDEF;
  background-color: #4B5F71;
  border-color: #364B5F;
}
.btn-dark:hover {
  color: #FFFFFF;
  background-color: #394D5F;
  border-color: #394D5F;
}
.btn-dark:focus {
  color: #FFFFFF;
  background-color: #394D5F;
  border-color: #394D5F;
}
.btn-dark:active {
  color: #FFFFFF;
  background-color: #394D5F;
  border-color: #394D5F;
}
.btn-dark.active {
  color: #FFFFFF;
  background-color: #394D5F;
  border-color: #394D5F;
}
.btn-round {
  border-radius: 30px;
}
.btn.btn-app {
  position: relative;
  padding: 15px 5px;
  margin: 0 0 10px 10px;
  min-width: 80px;
  height: 60px;
  box-shadow: none;
  border-radius: 0;
  text-align: center;
  color: #666;
  border: 1px solid #ddd;
  font-size: 12px;
}
.btn.btn-app > .fa {
  font-size: 20px;
  display: block;
}
.btn.btn-app > .glyphicon {
  font-size: 20px;
  display: block;
}
.btn.btn-app > .ion {
  font-size: 20px;
  display: block;
}
.btn.btn-app:hover {
  background: #f4f4f4;
  color: #444;
  border-color: #aaa;
}
.btn.btn-app:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.btn-app:focus {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn.btn-app > .badge {
  position: absolute;
  top: -3px;
  right: -10px;
  font-size: 10px;
  font-weight: 400;
}
textarea {
  padding: 10px;
  vertical-align: top;
  width: 200px;
}
textarea:focus {
  outline-style: solid;
  outline-width: 2px;
}
.btn_ {
  display: inline-block;
  padding: 3px 9px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #333333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #FFFFFF, #e6e6e6);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border: 1px solid #cccccc;
  border-bottom-color: #b3b3b3;
  border-radius: 4px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.bs-glyphicons {
  margin: 0 -10px 20px;
  overflow: hidden;
}
.bs-glyphicons li {
  float: left;
  width: 25%;
  height: 115px;
  padding: 10px;
  font-size: 10px;
  line-height: 1.4;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid #FFFFFF;
}
.bs-glyphicons li:hover {
  color: #EDEDED;
  background-color: #1ABB9C;
}
.bs-glyphicons .glyphicon {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 24px;
}
.bs-glyphicons .glyphicon-class {
  display: block;
  text-align: center;
  word-wrap: break-word;
}
.bs-glyphicons-list {
  padding-left: 0;
  list-style: none;
}
.tagsinput {
  border: 1px solid #CCC;
  background: #FFFFFF;
  padding: 6px 6px 0;
  width: 300px;
  overflow-y: auto;
}
.tagsinput span.tag a {
  font-weight: bold;
  color: #82ad2b;
  text-decoration: none;
  font-size: 11px;
}
.tagsinput input {
  width: 80px;
  margin: 0px;
  font-family: helvetica;
  font-size: 13px;
  border: 1px solid transparent;
  padding: 3px;
  background: transparent;
  color: #000;
  outline: 0px;
}
.tagsinput div {
  display: block;
  float: left;
}
span.tag {
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  display: block;
  float: left;
  padding: 5px 9px;
  text-decoration: none;
  background: #1ABB9C;
  color: #F1F6F7;
  margin-right: 5px;
  font-weight: 500;
  margin-bottom: 5px;
  font-family: helvetica;
}
span.tag a {
  color: #F1F6F7 !important;
}
.tags_clear {
  clear: both;
  width: 100%;
  height: 0px;
}
.not_valid {
  background: #FBD8DB !important;
  color: #90111A !important;
}
ul.bar_tabs {
  overflow: visible;
  background: #F5F7FA;
  height: 25px;
  margin: 21px 0 14px;
  padding-left: 14px;
  position: relative;
  z-index: 1;
  width: 100%;
  border-bottom: 1px solid #E6E9ED;
}
ul.bar_tabs > li {
  border: 1px solid #E6E9ED;
  color: #333 !important;
  margin-top: -17px;
  margin-left: 8px;
  background: #FFFFFF;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
}
ul.bar_tabs > li a {
  padding: 10px 17px;
  background: #F5F7FA;
  margin: 0;
  border-top-right-radius: 0;
}
ul.bar_tabs > li a:hover {
  border: 1px solid transparent;
}
ul.bar_tabs > li.active {
  border-right: 6px solid #FDC402;
  border-top: 0;
  margin-top: -15px;
}
ul.bar_tabs > li.active a {
  border-bottom: none;
}
ul.bar_tabs.right {
  padding-right: 14px;
}
ul.bar_tabs.right li {
  float: right;
}
ul.timeline li {
  position: relative;
  border-bottom: 1px solid #e8e8e8;
  clear: both;
}
.timeline .block {
  margin: 0;
  border-left: 3px solid #e8e8e8;
  overflow: visible;
  padding: 10px 15px;
  margin-left: 105px;
}
.timeline .tags {
  position: absolute;
  top: 15px;
  left: 0;
  width: 84px;
}
.timeline .tag {
  display: block;
  height: 30px;
  font-size: 13px;
  padding: 8px;
}
.timeline .tag span {
  display: block;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.timeline h2.title {
  position: relative;
  font-size: 16px;
  margin: 0;
}
.timeline h2.title:before {
  content: "";
  position: absolute;
  left: -23px;
  top: 3px;
  display: block;
  width: 14px;
  height: 14px;
  border: 3px solid #d2d3d2;
  border-radius: 14px;
  background: #f9f9f9;
}
.timeline .byline {
  padding: .25em 0;
}
.timeline.widget {
  min-width: 0;
  max-width: inherit;
}
.timeline.widget .block {
  margin-left: 5px;
}
.tag {
  line-height: 1;
  background: #1ABB9C;
  color: #EDEDED !important;
}
.tag:after {
  content: " ";
  height: 30px;
  width: 0;
  position: absolute;
  left: 100%;
  top: 0;
  margin: 0;
  pointer-events: none;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 11px solid #1ABB9C;
}
.byline {
  -webkit-font-smoothing: antialiased;
  font-style: italic;
  font-size: .9375em;
  line-height: 1.3;
  color: #aab6aa;
}
ul.social li {
  border: 0;
}
.social-sidebar {
  float: right;
  background: #EDEDED;
  width: 22%;
}
.social-body {
  float: right;
  border: 1px solid #ccc;
  width: 78%;
}
.thumb img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.chat .thumb img {
  width: 27px;
  height: 27px;
  border-radius: 50%;
}
.chat .status {
  float: left;
  margin: 16px 0 0 -16px;
  font-size: 14px;
  font-weight: bold;
  width: 12px;
  height: 12px;
  display: block;
  border: 2px solid #FFFFFF;
  z-index: 12312;
  border-radius: 50%;
}
.chat .status.online {
  background: #1ABB9C;
}
.chat .status.away {
  background: #F39C12;
}
.chat .status.offline {
  background: #ccc;
}
.chat .media-body {
  padding-top: 5px;
}
.chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}
.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;
  font-size: 18px;
}
.percent:after {
  content: '%';
  margin-left: 0.1em;
  font-size: .8em;
}
.angular {
  margin-top: 100px;
}
.angular .chart {
  margin-top: 0;
}
.widget {
  min-width: 250px;
  max-width: 310px;
}
.widget .verticle_bars li .progress.vertical.progress_wide {
  width: 65%;
}
.widget_tally_box .btn-group button {
  text-align: center;
  color: inherit;
  font-weight: 500;
  background-color: #f5f5f5;
  border: 1px solid #e7e7e7;
}
.widget_tally_box .flex {
  display: -ms-flexbox;
  display: flex;
}
.widget_tally_box p {
  text-align: center;
}
.widget_tally_box span {
  text-align: center;
}
.widget_tally_box .name {
  text-align: center;
  margin: 25px;
}
.widget_tally_box .name_title {
  text-align: center;
  margin: 5px;
}
.widget_tally_box ul.legend {
  margin: 0;
}
.widget_tally_box ul.legend p {
  text-align: left;
  display: inline-block;
  margin: 0;
}
.widget_tally_box ul.legend span {
  text-align: left;
}
.widget_tally_box ul.legend li .icon {
  font-size: 20px;
  float: left;
  width: 14px;
}
.widget_tally_box ul.legend li .name {
  font-size: 14px;
  margin: 5px 0 0 14px;
  text-overflow: ellipsis;
  float: left;
}
.widget_tally_box ul.verticle_bars li {
  height: 140px;
  width: 23%;
}
ul.widget_tally {
  width: 100%;
}
ul.widget_tally li {
  width: 100%;
  padding: 2px 10px;
  border-bottom: 1px solid #ECECEC;
  padding-bottom: 4px;
}
ul.widget_tally .month {
  width: 70%;
  float: left;
}
ul.widget_tally .count {
  width: 30%;
  float: left;
  text-align: right;
}
.pie_bg {
  border-bottom: 1px solid rgba(101, 204, 182, 0.16);
  padding-bottom: 15px;
  border-radius: 4px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  padding-bottom: 10px;
  box-shadow: 0 4px 6px -6px #222;
}
ul.widget_profile_box {
  width: 100%;
  height: 42px;
  padding: 3px;
  background: #ececec;
  margin-top: 40px;
  margin-left: 1px;
}
ul.widget_profile_box li:first-child {
  width: 25%;
  float: left;
}
ul.widget_profile_box li:first-child a {
  float: left;
}
ul.widget_profile_box li:last-child {
  width: 25%;
  float: right;
}
ul.widget_profile_box li:last-child a {
  float: right;
}
ul.widget_profile_box li a {
  font-size: 22px;
  text-align: center;
  width: 35px;
  height: 35px;
  border: 1px solid rgba(52, 73, 94, 0.44);
  display: block;
  border-radius: 50%;
  padding: 0px;
}
ul.widget_profile_box li a:hover {
  color: #1ABB9C !important;
  border: 1px solid #26b99a;
}
ul.widget_profile_box li .profile_img {
  width: 85px;
  height: 85px;
  margin: 0;
  margin-top: -28px;
}
ul.count2 {
  width: 100%;
  margin-left: 1px;
  border: 1px solid #ddd;
  border-left: 0;
  border-right: 0;
  padding: 10px 0;
}
ul.count2 li {
  width: 30%;
  text-align: center;
}
ul.count2 li h3 {
  font-weight: 400;
  margin: 0;
}
ul.count2 li span {
  font-weight: 300;
}
.divider {
  border-bottom: 1px solid #ddd;
  margin: 10px;
}
.divider-dashed {
  border-top: 1px dashed #e7eaec;
  background-color: #FFFFFF;
  height: 1px;
  margin: 10px 0;
}
ul.messages {
  padding: 0;
  list-style: none;
}
ul.messages li {
  border-bottom: 1px dotted #e6e6e6;
  padding: 8px 0;
}
ul.messages li img.avatar {
  height: 32px;
  width: 32px;
  float: left;
  display: inline-block;
  border-radius: 2px;
  padding: 2px;
  background: #f7f7f7;
  border: 1px solid #e6e6e6;
}
ul.messages li .message_date {
  float: right;
  text-align: right;
}
ul.messages li .message_wrapper {
  margin-left: 50px;
  margin-right: 40px;
}
ul.messages li .message_wrapper h4.heading {
  font-weight: 600;
  margin: 0;
  cursor: pointer;
  margin-bottom: 10px;
  line-height: 100%;
}
ul.messages li .message_wrapper blockquote {
  padding: 0px 10px;
  margin: 0;
  border-left: 5px solid #eee;
}
.tasks li {
  border-bottom: 1px dotted #e6e6e6;
  padding: 8px 0;
}
img.avatar {
  height: 32px;
  width: 32px;
  float: left;
  display: inline-block;
  border-radius: 2px;
  padding: 2px;
  background: #f7f7f7;
  border: 1px solid #e6e6e6;
}
ul.user_data li {
  margin-bottom: 6px;
}
ul.user_data li p {
  margin-bottom: 0;
}
ul.user_data li .progress {
  width: 90%;
}
.project_progress .progress {
  margin-bottom: 3px !important;
  margin-top: 5px;
}
.projects .list-inline {
  margin: 0;
}
.profile_title {
  background: #F5F7FA;
  border: 0;
  padding: 7px 0;
  display: -ms-flexbox;
  display: flex;
}
ul.stats-overview {
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
ul.stats-overview li {
  display: inline-block;
  text-align: center;
  padding: 0 15px;
  width: 30%;
  font-size: 14px;
  border-right: 1px solid #e8e8e8;
}
ul.stats-overview li:last-child {
  border-right: 0;
}
ul.stats-overview li .name {
  font-size: 12px;
}
ul.stats-overview li .value {
  font-size: 14px;
  font-weight: bold;
  display: block;
}
ul.stats-overview li:first-child {
  padding-left: 0;
}
ul.project_files li {
  margin-bottom: 5px;
}
ul.project_files li a i {
  width: 20px;
}
.project_detail p {
  margin-bottom: 10px;
}
.project_detail p.title {
  font-weight: bold;
  margin-bottom: 0;
}
.avatar img {
  border-radius: 50%;
  max-width: 45px;
}
.pricing {
  background: #FFFFFF;
}
.pricing .title {
  background: #1ABB9C;
  height: 110px;
  color: #EDEDED;
  padding: 15px 0 0;
  text-align: center;
}
.pricing .title h2 {
  text-transform: capitalize;
  font-size: 18px;
  border-radius: 5px 5px 0 0;
  margin: 0;
  font-weight: 400;
}
.pricing .title h1 {
  font-size: 30px;
  margin: 12px;
}
.pricing .title span {
  background: rgba(51, 51, 51, 0.28);
  padding: 2px 5px;
}
.pricing_features {
  background: #FAFAFA;
  padding: 20px 15px;
  min-height: 230px;
  font-size: 13.5px;
}
.pricing_features ul li {
  margin-top: 10px;
}
.pricing_footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  text-align: center;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.pricing_footer p {
  font-size: 13px;
  padding: 10px 0 2px;
  display: block;
}
.ui-ribbon-container {
  position: relative;
}
.ui-ribbon-container .ui-ribbon-wrapper {
  position: absolute;
  overflow: hidden;
  width: 85px;
  height: 88px;
  top: -3px;
  right: -3px;
}
.ui-ribbon-container .ui-ribbon {
  position: relative;
  display: block;
  text-align: center;
  font-size: 15px;
  font-weight: 700;
  color: #EDEDED;
  transform: rotate(45deg);
  padding: 7px 0;
  left: -5px;
  top: 15px;
  width: 120px;
  line-height: 20px;
  background-color: #555;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}
.ui-ribbon-container .ui-ribbon:before {
  left: 0;
  bottom: -1px;
  right: 0;
  position: absolute;
  content: " ";
  line-height: 0;
  border-top: 2px solid #555;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
.ui-ribbon-container .ui-ribbon:after {
  position: absolute;
  content: " ";
  line-height: 0;
  border-top: 2px solid #555;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
}
.ui-ribbon-container.ui-ribbon-primary .ui-ribbon {
  background-color: #5b90bf;
}
.ui-ribbon-container.ui-ribbon-primary .ui-ribbon:after {
  border-top: 2px solid #5b90bf;
}
.ui-ribbon-container.ui-ribbon-primary .ui-ribbon:before {
  border-top: 2px solid #5b90bf;
}
.thumbnail {
  height: 190px;
  overflow: hidden;
}
.thumbnail .image {
  height: 120px;
  overflow: hidden;
}
.caption {
  padding: 9px 5px;
  background: #F7F7F7;
}
.caption p {
  margin-bottom: 5px;
}
.view {
  overflow: hidden;
  position: relative;
  text-align: center;
  box-shadow: 1px 1px 2px #e6e6e6;
  cursor: default;
}
.view .mask {
  position: absolute;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}
.view .content {
  position: absolute;
  width: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
}
.view img {
  display: block;
  position: relative;
}
.view .tools {
  text-transform: uppercase;
  color: #EDEDED;
  text-align: center;
  position: relative;
  font-size: 17px;
  padding: 3px;
  background: rgba(0, 0, 0, 0.35);
  margin: 43px 0 0 0;
}
.view .tools a {
  display: inline-block;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 400;
  padding: 0 4px;
}
.view p {
  font-family: Georgia, serif;
  font-style: italic;
  font-size: 12px;
  position: relative;
  color: #EDEDED;
  padding: 10px 20px 20px;
  text-align: center;
}
.view a.info {
  display: inline-block;
  text-decoration: none;
  padding: 7px 14px;
  background: #000;
  color: #EDEDED;
  text-transform: uppercase;
  box-shadow: 0 0 1px #000;
}
.mask.no-caption .tools {
  margin: 90px 0 0 0;
}
.view-first img {
  transition: all 0.2s linear;
}
.view-first .mask {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s ease-in-out;
}
.view-first .tools {
  transform: translateY(-100px);
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.view-first p {
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.2s linear;
}
.view-first:hover img {
  transform: scale(1.1);
}
.view-first:hover .mask {
  opacity: 1;
}
.view-first:hover .tools {
  opacity: 1;
  transform: translateY(0px);
}
.view-first:hover p {
  opacity: 1;
  transform: translateY(0px);
  transition-delay: 0.1s;
}
.tabs-left {
  border-bottom: none;
  padding-top: 2px;
  border-right: 1px solid #F7F7F7;
}
.tabs-left > li {
  float: none;
  margin-bottom: 2px;
  margin-right: -1px;
}
.tabs-left > li > a {
  border-radius: 4px 0 0 4px;
  margin-right: 0;
  display: block;
  background: #F7F7F7;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tabs-left > li.active > a {
  border-bottom-color: #F7F7F7;
  border-right-color: transparent;
}
.tabs-left > li.active > a:hover {
  border-bottom-color: #F7F7F7;
  border-right-color: transparent;
}
.tabs-left > li.active > a:focus {
  border-bottom-color: #F7F7F7;
  border-right-color: transparent;
}
.tabs-right {
  border-bottom: none;
  padding-top: 2px;
  border-left: 1px solid #F7F7F7;
}
.tabs-right > li {
  float: none;
  margin-bottom: 2px;
  margin-left: -1px;
}
.tabs-right > li > a {
  border-radius: 0 4px 4px 0;
  margin-right: 0;
  background: #F7F7F7;
  text-overflow: ellipsis;
  overflow: hidden;
}
.tabs-right > li.active > a {
  border-bottom: 1px solid #F7F7F7;
  border-left-color: transparent;
}
.tabs-right > li.active > a:hover {
  border-bottom: 1px solid #F7F7F7;
  border-left-color: transparent;
}
.tabs-right > li.active > a:focus {
  border-bottom: 1px solid #F7F7F7;
  border-left-color: transparent;
}
.sideways {
  margin-top: 50px;
  border: none;
  position: relative;
}
.sideways > li {
  height: 20px;
  width: 120px;
  margin-bottom: 100px;
}
.sideways > li > a {
  border-bottom: 1px solid #ddd;
  border-right-color: transparent;
  text-align: center;
  border-radius: 4px 4px 0px 0px;
}
.sideways > li.active > a {
  border-bottom-color: transparent;
  border-right-color: #ddd;
  border-left-color: #ddd;
}
.sideways > li.active > a:hover {
  border-bottom-color: transparent;
  border-right-color: #ddd;
  border-left-color: #ddd;
}
.sideways > li.active > a:focus {
  border-bottom-color: transparent;
  border-right-color: #ddd;
  border-left-color: #ddd;
}
.sideways.tabs-left {
  left: -50px;
}
.sideways.tabs-left > li {
  transform: rotate(-90deg);
}
.sideways.tabs-right {
  right: -50px;
}
.sideways.tabs-right > li {
  transform: rotate(90deg);
}
.morris-hover {
  position: absolute;
  z-index: 1000;
}
.morris-hover.morris-default-style {
  padding: 6px;
  color: #666;
  background: rgba(243, 242, 243, 0.8);
  border: solid 2px rgba(195, 194, 196, 0.8);
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}
.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}
.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}
.price {
  font-size: 40px;
  font-weight: 400;
  color: #26B99A;
  margin: 0;
}
.prod_title {
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 5px;
  margin: 30px 0;
  font-size: 20px;
  font-weight: 400;
}
.product-image img {
  width: 90%;
}
.prod_color li {
  margin: 0 10px;
}
.prod_color li p {
  margin-bottom: 0;
}
.prod_color .color {
  width: 25px;
  height: 25px;
  border: 2px solid rgba(51, 51, 51, 0.28) !important;
  padding: 2px;
  border-radius: 50px;
}
.prod_size li {
  padding: 0;
}
.product_gallery a {
  width: 100px;
  height: 100px;
  float: left;
  margin: 10px;
  border: 1px solid #e5e5e5;
}
.product_gallery a img {
  width: 100%;
  margin-top: 15px;
}
.product_price {
  margin: 20px 0;
  padding: 5px 10px;
  background-color: #FFFFFF;
  text-align: left;
  border: 2px dashed #E0E0E0;
}
.price-tax {
  font-size: 18px;
}
.product_social {
  margin: 20px 0;
}
.product_social ul li a i {
  font-size: 35px;
}
.login {
  background: #F7F7F7;
}
.login .fa-paw {
  font-size: 26px;
}
a.hiddenanchor {
  display: none;
}
.login_wrapper {
  right: 0px;
  margin: 0px auto;
  margin-top: 5%;
  max-width: 350px;
  position: relative;
}
.registration_form {
  position: absolute;
  top: 0px;
  z-index: 21;
  opacity: 0;
  width: 100%;
}
.login_form {
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 22;
}
#signup:target ~ .login_wrapper .registration_form {
  z-index: 22;
  animation-name: fadeInLeft;
  animation-delay: .1s;
}
#signup:target ~ .login_wrapper .login_form {
  animation-name: fadeOutLeft;
}
#signin:target ~ .login_wrapper .login_form {
  z-index: 22;
  animation-name: fadeInLeft;
  animation-delay: .1s;
}
#signin:target ~ .login_wrapper .registration_form {
  animation-name: fadeOutLeft;
}
.animate {
  -webkit-animation-duration: 0.5s;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: both;
  -moz-animation-duration: 0.5s;
  -moz-animation-timing-function: ease;
  -moz-animation-fill-mode: both;
  -o-animation-duration: 0.5s;
  -o-animation-timing-function: ease;
  -o-animation-fill-mode: both;
  -ms-animation-duration: 0.5s;
  -ms-animation-timing-function: ease;
  -ms-animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: both;
}
.login_box {
  padding: 20px;
  margin: auto;
}
.left {
  float: left;
}
.alignleft {
  float: left;
  margin-right: 15px;
}
.alignright {
  float: right;
  margin-left: 15px;
}
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
form:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.login_content {
  margin: 0 auto;
  padding: 25px 0 0;
  position: relative;
  text-align: center;
  text-shadow: 0 1px 0 #FFFFFF;
  min-width: 280px;
}
.login_content a {
  text-decoration: none;
}
.login_content a:hover {
  text-decoration: underline;
}
.login_content .btn-default:hover {
  text-decoration: none;
}
.login_content h1 {
  font: normal 25px Helvetica, Arial, sans-serif;
  letter-spacing: -0.05em;
  line-height: 20px;
  margin: 10px 0 30px;
}
.login_content h1:before {
  width: 27%;
  content: "";
  height: 1px;
  position: absolute;
  top: 10px;
  width: 20%;
  background: #7e7e7e;
  background: linear-gradient(right, #7e7e7e 0%, white 100%);
  left: 0;
}
.login_content h1:after {
  width: 27%;
  content: "";
  height: 1px;
  position: absolute;
  top: 10px;
  width: 20%;
  background: #7e7e7e;
  background: linear-gradient(left, #7e7e7e 0%, white 100%);
  right: 0;
}
.login_content form {
  margin: 20px 0;
  position: relative;
}
.login_content form input[type="text"] {
  border-radius: 0px;
  -ms-box-shadow: 0 1px 0 #FFFFFF, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  -o-box-shadow: 0 1px 0 #FFFFFF, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  box-shadow: 0 1px 0 #FFFFFF, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  border: 1px solid #c8c8c8;
  color: #777;
  margin: 0 0 0px;
  width: 100%;
}
.login_content form input[type="text"]:focus {
  -ms-box-shadow: 0 0 2px #ed1c24 inset;
  -o-box-shadow: 0 0 2px #ed1c24 inset;
  box-shadow: 0 0 2px #05473D inset;
  background-color: #FFFFFF;
  border: 1px solid #05473D;
  outline: none;
}
.login_content form input[type="email"] {
  border-radius: 0px;
  -ms-box-shadow: 0 1px 0 #FFFFFF, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  -o-box-shadow: 0 1px 0 #FFFFFF, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  box-shadow: 0 1px 0 #FFFFFF, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  border: 1px solid #c8c8c8;
  color: #777;
  margin: 0 0 0px;
  width: 100%;
}
.login_content form input[type="email"]:focus {
  -ms-box-shadow: 0 0 2px #ed1c24 inset;
  -o-box-shadow: 0 0 2px #ed1c24 inset;
  box-shadow: 0 0 2px #05473D inset;
  background-color: #FFFFFF;
  border: 1px solid #05473D;
  outline: none;
}
.login_content form input[type="password"] {
  border-radius: 0px;
  -ms-box-shadow: 0 1px 0 #FFFFFF, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  -o-box-shadow: 0 1px 0 #FFFFFF, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  box-shadow: 0 1px 0 #FFFFFF, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
  border: 1px solid #c8c8c8;
  color: #777;
  margin: 0 0 0px;
  width: 100%;
}
.login_content form input[type="password"]:focus {
  -ms-box-shadow: 0 0 2px #ed1c24 inset;
  -o-box-shadow: 0 0 2px #ed1c24 inset;
  box-shadow: 0 0 2px #05473D inset;
  background-color: #FFFFFF;
  border: 1px solid #05473D;
  outline: none;
}
.login_content form div a {
  font-size: 12px;
  margin: 10px 15px 0 0;
}
.login_content form input[type="submit"] {
  float: left;
  margin-left: 38px;
}
.login_content div .reset_pass {
  margin-top: 13px !important;
  margin-right: 39px;
  float: right;
}
#username {
  background-position: 10px 10px !important;
}
#password {
  background-position: 10px -53px !important;
}
.reset_pass {
  margin-top: 10px !important;
}
.separator {
  border-top: 1px solid #D8D8D8;
  margin-top: 10px;
  padding-top: 10px;
}
/*.button {
    background: #f7f9fa;
    background: linear-gradient(top, #f7f9fa 0%, #f0f0f0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f9fa', endColorstr='#f0f0f0', GradientType=0);
    -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid #CFD5D9;
    padding: 15px 0;
    a {
        background: url(http://cssdeck.com/uploads/media/items/8/8bcLQqF.png) 0 -112px no-repeat;
        color: @color_51;
        font-size: 17px;
        padding: 2px 0 2px 40px;
        text-decoration: none;
        transition: all 0.3s ease;
        &:hover {
            background-position: 0 -135px;
            color: @color_52;
        }
    }
}*/
#content form .submit {
  float: left;
  margin-left: 38px;
}
header {
  width: 100%;
}
#nprogress .bar {
  background: #FDC402;
}
#nprogress .peg {
  box-shadow: 0 0 10px #FDC402, 0 0 5px #FDC402;
}
#nprogress .spinner-icon {
  border-top-color: #FDC402;
  border-left-color: #FDC402;
}
.editor-wrapper {
  min-height: 250px;
  background-color: white;
  border-collapse: separate;
  border: 1px solid #cccccc;
  padding: 4px;
  box-sizing: content-box;
  box-shadow: rgba(0, 0, 0, 0.07451) 0px 1px 1px 0px inset;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  overflow: scroll;
  outline: none;
}
.voiceBtn {
  width: 20px;
  color: transparent;
  background-color: transparent;
  transform: scale(2, 2);
  -webkit-transform: scale(2, 2);
  -moz-transform: scale(2, 2);
  border: transparent;
  cursor: pointer;
  box-shadow: none;
  -webkit-box-shadow: none;
}
div[data-role="editor-toolbar"] {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.select2-container--default .select2-selection--single {
  background-color: #FFFFFF;
  border: 1px solid #ccc;
  border-radius: 0;
  min-height: 34px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #3f2f00;
  padding-top: 2px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 36px;
}
.select2-container--default .select2-selection--multiple {
  background-color: #FFFFFF;
  border: 1px solid #ccc;
  border-radius: 0;
  min-height: 34px;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding-top: 3px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin-top: 0px;
  border: none;
  border-radius: 0;
  padding: 8px;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  margin-top: 8px;
  border: none;
  border-radius: 0;
  padding: 3px 5px;
  font-size: 1.2em;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #ccc;
}
.switchery {
  width: 32px;
  height: 20px;
}
.switchery > small {
  width: 20px;
  height: 20px;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
.cropper .img-container {
  background-color: #f7f7f7;
  width: 100%;
  text-align: center;
  min-height: 200px;
  max-height: 516px;
  margin-bottom: 20px;
}
.cropper .img-container > img {
  max-width: 100%;
}
.cropper .img-preview {
  background-color: #f7f7f7;
  width: 100%;
  text-align: center;
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}
.cropper .img-preview > img {
  max-width: 100%;
}
.cropper .docs-preview {
  margin-right: -15px;
}
.cropper .preview-lg {
  width: 263px;
  height: 148px;
}
.cropper .preview-md {
  width: 139px;
  height: 78px;
}
.cropper .preview-sm {
  width: 69px;
  height: 39px;
}
.cropper .preview-xs {
  width: 35px;
  height: 20px;
  margin-right: 0;
}
.cropper .docs-data > .input-group {
  margin-bottom: 10px;
}
.cropper .docs-data > .input-group > label {
  min-width: 80px;
}
.cropper .docs-data > .input-group > span {
  min-width: 50px;
}
.cropper .docs-buttons > .btn {
  margin-right: 5px;
  margin-bottom: 10px;
}
.cropper .docs-buttons > .btn-group {
  margin-right: 5px;
  margin-bottom: 10px;
}
.cropper .docs-buttons > .form-control {
  margin-right: 5px;
  margin-bottom: 10px;
}
.cropper .docs-toggles > .btn {
  margin-bottom: 10px;
}
.cropper .docs-toggles > .btn-group {
  margin-bottom: 10px;
}
.cropper .docs-toggles > .dropdown {
  margin-bottom: 10px;
}
.cropper .docs-tooltip {
  display: block;
  margin: -6px -12px;
  padding: 6px 12px;
}
.cropper .docs-tooltip > .icon {
  margin: 0 -3px;
  vertical-align: top;
}
.cropper .tooltip-inner {
  white-space: normal;
}
.cropper .btn-upload .tooltip-inner {
  white-space: nowrap;
}
.cropper .btn-toggle {
  padding: 6px;
}
.cropper .btn-toggle .tooltip-inner {
  white-space: nowrap;
}
.cropper .btn-toggle > .docs-tooltip {
  margin: -6px;
  padding: 6px;
}
.cropper .docs-options .dropdown-menu {
  width: 100%;
}
.cropper .docs-options .dropdown-menu > li {
  padding: 3px 20px;
}
.cropper .docs-options .dropdown-menu > li:hover {
  background-color: #f7f7f7;
}
.cropper .docs-options .dropdown-menu > li > label {
  display: block;
}
.cropper .docs-cropped .modal-body {
  text-align: center;
}
.cropper .docs-cropped .modal-body > img {
  max-width: 100%;
}
.cropper .docs-cropped .modal-body > canvas {
  max-width: 100%;
}
.cropper .docs-cropped canvas {
  max-width: 100%;
}
.cropper .docs-diagram .modal-dialog {
  max-width: 352px;
}
.form_wizard .stepContainer {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0 solid #CCC;
  overflow-x: hidden;
}
.form_wizard .loader {
  display: none;
}
.form_wizard .msgBox {
  display: none;
}
.wizard_horizontal ul.wizard_steps {
  display: table;
  list-style: none;
  position: relative;
  width: 100%;
  margin: 0 0 20px;
}
.wizard_horizontal ul.wizard_steps li {
  display: table-cell;
  text-align: center;
}
.wizard_horizontal ul.wizard_steps li a {
  display: block;
  position: relative;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
  opacity: 1;
  color: #666;
}
.wizard_horizontal ul.wizard_steps li a:before {
  content: "";
  position: absolute;
  height: 4px;
  background: #ccc;
  top: 20px;
  width: 100%;
  z-index: 4;
  left: 0;
}
.wizard_horizontal ul.wizard_steps li a .step_no {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
  display: block;
  margin: 0 auto 5px;
  font-size: 16px;
  text-align: center;
  position: relative;
  z-index: 5;
}
.wizard_horizontal ul.wizard_steps li:hover {
  display: block;
  position: relative;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
  opacity: 1;
  color: #666;
}
.wizard_horizontal ul.wizard_steps li a.disabled .step_no {
  background: #ccc;
}
.wizard_horizontal ul.wizard_steps li a.selected:before {
  background: #34495E;
  color: #EDEDED;
}
.wizard_horizontal ul.wizard_steps li a.done:before {
  background: #1ABB9C;
  color: #EDEDED;
}
.wizard_horizontal ul.wizard_steps li a.done .step_no {
  background: #1ABB9C;
  color: #EDEDED;
}
.wizard_horizontal ul.wizard_steps li:first-child a:before {
  left: 50%;
}
.wizard_horizontal ul.wizard_steps li:last-child a:before {
  right: 50%;
  width: 50%;
  left: auto;
}
.step_no {
  background: #34495E;
  color: #EDEDED;
}
.wizard_verticle .stepContainer {
  width: 80%;
  float: left;
  padding: 0 10px;
}
.wizard_verticle .wizard_content {
  width: 80%;
  float: left;
  padding-left: 20px;
}
.wizard_verticle ul.wizard_steps {
  display: table;
  list-style: none;
  position: relative;
  width: 20%;
  float: left;
  margin: 0 0 20px;
}
.wizard_verticle ul.wizard_steps li {
  display: list-item;
  text-align: center;
}
.wizard_verticle ul.wizard_steps li a {
  height: 80px;
  display: block;
  position: relative;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
  opacity: 1;
  color: #666;
}
.wizard_verticle ul.wizard_steps li a:first-child {
  margin-top: 20px;
}
.wizard_verticle ul.wizard_steps li a:before {
  content: "";
  position: absolute;
  height: 100%;
  background: #ccc;
  top: 20px;
  width: 4px;
  z-index: 4;
  left: 49%;
}
.wizard_verticle ul.wizard_steps li a .step_no {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
  display: block;
  margin: 0 auto 5px;
  font-size: 16px;
  text-align: center;
  position: relative;
  z-index: 5;
}
.wizard_verticle ul.wizard_steps li:hover {
  display: block;
  position: relative;
  -moz-opacity: 1;
  filter: alpha(opacity=100);
  opacity: 1;
  color: #666;
}
.wizard_verticle ul.wizard_steps li a.disabled .step_no {
  background: #ccc;
}
.wizard_verticle ul.wizard_steps li a.selected:before {
  background: #34495E;
  color: #EDEDED;
}
.wizard_verticle ul.wizard_steps li a.done:before {
  background: #1ABB9C;
  color: #EDEDED;
}
.wizard_verticle ul.wizard_steps li a.done .step_no {
  background: #1ABB9C;
  color: #EDEDED;
}
.wizard_verticle ul.wizard_steps li:first-child a:before {
  left: 49%;
}
.wizard_verticle ul.wizard_steps li:last-child a:before {
  left: 49%;
  left: auto;
  width: 0;
}
.actionBar {
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 10px 5px;
  text-align: right;
  margin-top: 10px;
}
.actionBar .buttonDisabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: .65;
  filter: alpha(opacity=65);
  box-shadow: none;
}
.actionBar a {
  margin: 0 3px;
}
.progress {
  border-radius: 0;
}
.progress-bar-info {
  background-color: #3498DB;
}
.progress-bar-success {
  background-color: #26B99A;
}
.progress_summary .progress {
  margin: 5px 0 12px !important;
}
.progress_summary .row {
  margin-bottom: 5px;
}
.progress_summary .row .col-xs-2 {
  padding: 0;
}
.progress_summary .more_info span {
  text-align: right;
  float: right;
}
.progress_summary .data span {
  text-align: right;
  float: right;
}
.progress_summary p {
  margin-bottom: 3px;
  width: 100%;
}
.progress_title .left {
  float: left;
  text-align: left;
}
.progress_title .right {
  float: right;
  text-align: right;
  font-weight: 300;
}
.progress.progress_sm {
  border-radius: 0;
  margin-bottom: 18px;
  height: 10px !important;
}
.progress.progress_sm .progress-bar {
  height: 10px !important;
}
ul.verticle_bars {
  width: 100%;
}
ul.verticle_bars li {
  width: 23%;
  height: 200px;
  margin: 0;
}
.progress.vertical.progress_wide {
  width: 35px;
}
.alert.alert-danger ul li {
  padding: 3px 0px;
  margin-left: 15px;
}
.alert-success {
  color: #E9EDEF;
  background-color: rgba(38, 185, 154, 0.88);
  border-color: rgba(38, 185, 154, 0.88);
}
.alert-info {
  color: #E9EDEF;
  background-color: rgba(52, 152, 219, 0.88);
  border-color: rgba(52, 152, 219, 0.88);
}
.alert-warning {
  color: #E9EDEF;
  background-color: rgba(243, 156, 18, 0.88);
  border-color: rgba(243, 156, 18, 0.88);
}
.alert-danger {
  color: #E9EDEF;
  background-color: rgba(231, 76, 60, 0.88);
  border-color: rgba(231, 76, 60, 0.88);
}
.alert-error {
  color: #E9EDEF;
  background-color: rgba(231, 76, 60, 0.88);
  border-color: rgba(231, 76, 60, 0.88);
}
.ui-pnotify.dark .ui-pnotify-container {
  color: #E9EDEF;
  background-color: rgba(52, 73, 94, 0.88);
  border-color: rgba(52, 73, 94, 0.88);
}
.custom-notifications {
  position: fixed;
  margin: 15px;
  right: 0;
  float: right;
  width: 400px;
  z-index: 4000;
  bottom: 0;
}
ul.notifications {
  float: right;
  display: block;
  margin-bottom: 7px;
  padding: 0;
  width: 100%;
}
.notifications li {
  float: right;
  margin: 3px;
  width: 36px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
}
.notifications li:last-child {
  margin-left: 0;
}
.notifications a {
  display: block;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 9px 8px;
}
.tabbed_notifications .text {
  padding: 5px 15px;
  height: 140px;
  border-radius: 7px;
  box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.3);
}
.tabbed_notifications div p {
  display: inline-block;
}
.tabbed_notifications h2 {
  font-weight: bold;
  text-transform: uppercase;
  width: 80%;
  float: left;
  height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
}
.tabbed_notifications .close {
  padding: 5px;
  color: #E9EDEF;
  float: right;
  opacity: 1;
}
.daterangepicker.dropdown-menu {
  font-size: 13px;
  padding: 0;
  overflow: hidden;
}
.daterangepicker.picker_1 {
  background: #34495E;
  color: #ECF0F1;
}
.daterangepicker.picker_1 table.table-condensed thead tr {
  background: #213345;
}
.daterangepicker.picker_1 table.table-condensed thead tr:first-child {
  background: #1ABB9C;
}
.daterangepicker table.table-condensed thead tr {
  line-height: 14px;
}
.daterangepicker table.table-condensed thead tr:first-child th {
  line-height: 28px;
  text-align: center;
}
.daterangepicker table.table-condensed tbody tr:first-child td {
  padding-top: 10px;
}
.daterangepicker table.table-condensed tbody tr:last-child td {
  padding-bottom: 10px;
}
.daterangepicker table.table-condensed th:first-child {
  padding-left: 12px;
}
.daterangepicker table.table-condensed th:last-child {
  padding-right: 12px;
}
.daterangepicker table.table-condensed td:first-child {
  padding-left: 12px;
}
.daterangepicker table.table-condensed td:last-child {
  padding-right: 12px;
}
.daterangepicker table {
  width: 100%;
  margin: 0;
}
.daterangepicker td {
  text-align: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  white-space: nowrap;
}
.daterangepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  white-space: nowrap;
}
.daterangepicker td.off {
  color: #999;
}
.daterangepicker td.disabled {
  color: #999;
}
.daterangepicker td.available:hover {
  background: #eee;
  color: #34495E;
}
.daterangepicker td.available + td.start-date {
  border-radius: 4px 0 0 4px;
}
.daterangepicker th.available:hover {
  background: #eee;
  color: #34495E;
}
.daterangepicker td.in-range {
  background: #E4E7EA;
  border-radius: 0;
}
.daterangepicker td.in-range + td.end-date {
  border-radius: 0 4px 4px 0;
}
.daterangepicker td.start-date.end-date {
  border-radius: 4px !important;
}
.daterangepicker td.active {
  background-color: #536A7F;
  color: #EDEDED;
}
.daterangepicker td.active:hover {
  background-color: #536A7F;
  color: #EDEDED;
}
.daterangepicker td.week {
  font-size: 80%;
  color: #ccc;
}
.daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}
.daterangepicker select.monthselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
  height: 30px;
  border: 1px solid #ADB2B5;
  line-height: 30px;
  border-radius: 0px !important;
  margin-right: 2%;
  width: 56%;
}
.daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
  height: 30px;
  border: 1px solid #ADB2B5;
  line-height: 30px;
  border-radius: 0px !important;
  width: 40%;
}
.daterangepicker select.hourselect {
  width: 50px;
  margin-bottom: 0;
}
.daterangepicker select.minuteselect {
  width: 50px;
  margin-bottom: 0;
}
.daterangepicker select.ampmselect {
  width: 50px;
  margin-bottom: 0;
}
.daterangepicker th.month {
  width: auto;
}
.daterangepicker .daterangepicker_start_input label {
  color: #333;
  display: block;
  font-size: 11px;
  font-weight: normal;
  height: 20px;
  line-height: 20px;
  margin-bottom: 2px;
  text-shadow: #FFFFFF 1px 1px 0px;
  text-transform: uppercase;
  width: 74px;
}
.daterangepicker .daterangepicker_end_input label {
  color: #333;
  display: block;
  font-size: 11px;
  font-weight: normal;
  height: 20px;
  line-height: 20px;
  margin-bottom: 2px;
  text-shadow: #FFFFFF 1px 1px 0px;
  text-transform: uppercase;
  width: 74px;
}
.daterangepicker .ranges {
  width: 160px;
  text-align: left;
  margin: 4px;
}
.daterangepicker .ranges input {
  font-size: 11px;
}
.daterangepicker .ranges .input-mini {
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555;
  display: block;
  font-size: 11px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  margin: 0 0 10px 0;
  padding: 0 6px;
  width: 74px;
}
.daterangepicker .ranges .input-mini:hover {
  cursor: pointer;
}
.daterangepicker .ranges ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.daterangepicker .ranges li {
  font-size: 13px;
  background: #f5f5f5;
  border: 1px solid #f5f5f5;
  color: #536A7F;
  padding: 3px 12px;
  margin-bottom: 8px;
  border-radius: 5px;
  cursor: pointer;
}
.daterangepicker .ranges li:hover {
  background: #536A7F;
  border: 1px solid #536A7F;
  color: #EDEDED;
}
.daterangepicker .ranges li.active {
  background: #536A7F;
  border: 1px solid #536A7F;
  color: #EDEDED;
}
.daterangepicker .ranges .range_inputs > div {
  float: left;
}
.daterangepicker .ranges .range_inputs > div:nth-child(2) {
  padding-left: 11px;
}
.daterangepicker .calendar {
  display: none;
  max-width: 270px;
}
.daterangepicker .calendar.single .calendar-date {
  border: none;
}
.daterangepicker .icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}
.table-condensed > thead > tr > th {
  padding: 5px 7px;
  text-align: center;
}
.table-condensed > thead > tr > td {
  padding: 5px 7px;
  text-align: center;
}
.table-condensed > tbody > tr > th {
  padding: 5px 7px;
  text-align: center;
}
.table-condensed > tbody > tr > td {
  padding: 5px 7px;
  text-align: center;
}
.table-condensed > tfoot > tr > th {
  padding: 5px 7px;
  text-align: center;
}
.table-condensed > tfoot > tr > td {
  padding: 5px 7px;
  text-align: center;
}
.daterangepicker.picker_2 table.table-condensed thead tr {
  color: #1ABB9C;
}
.daterangepicker.picker_2 table.table-condensed thead tr:first-child {
  color: inherit;
}
.daterangepicker.picker_3 table.table-condensed thead tr:first-child {
  background: #1ABB9C;
  color: #ECF0F1;
}
.daterangepicker.picker_4 table.table-condensed tbody td {
  background: #ECF0F1;
  color: #34495E;
  border: 1px solid #FFFFFF;
  padding: 4px 7px;
}
.daterangepicker.picker_4 table.table-condensed tbody td.active {
  background: #536A7F;
  color: #EDEDED;
}
.daterangepicker.picker_4 table.table-condensed thead tr:first-child {
  background: #34495E;
  color: #ECF0F1;
}
.xdisplay_input {
  width: 240px;
  overflow: hidden;
  padding: 0;
}
.xdisplay {
  background-color: #FFFFFF;
  background-clip: padding-box;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  width: 230px;
  overflow: hidden;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.daterangepicker.opensright .ranges {
  float: right;
}
.daterangepicker.opensright .calendar {
  float: right;
}
.daterangepicker.openscenter .ranges {
  float: right;
}
.daterangepicker.openscenter .calendar {
  float: right;
}
.daterangepicker_start_input {
  float: left;
}
.daterangepicker_end_input {
  float: left;
  padding-left: 11px;
}
.daterangepicker.show-calendar .calendar {
  display: block;
}
.daterangepicker.single .ranges {
  float: none;
}
.daterangepicker.single .calendar {
  float: none;
}
.daterangepicker.opensleft .ranges {
  float: left;
  margin: 4px;
}
.daterangepicker.opensleft .calendar {
  float: left;
  margin: 4px;
}
.fc-state-default {
  background: #f5f5f5;
  color: #3f2f00;
}
.fc-state-down {
  color: #333;
  background: #ccc;
}
.fc-state-active {
  color: #333;
  background: #ccc;
}
.dropzone {
  min-height: 300px;
  border: 1px solid #e5e5e5;
}
@media (min-width: 480px) {
  .nav_menu {
    position: static;
  }
  .top_nav .navbar-right li {
    position: relative;
  }
}
@media (max-width: 991px) {
  .nav-md .container.body .right_col {
    margin: 0;
    width: 100%;
    padding-right: 0;
  }
  .nav-md .container.body .top_nav {
    width: 100%;
    margin: 0;
  }
  .nav-md .container.body .col-md-3.left_col {
    display: none;
  }
  .right_col {
    padding: 10px !important;
  }
}
@media (max-width: 1200px) {
  .x_title h2 {
    width: 62%;
    font-size: 17px;
  }
  .tile {
    zoom: 85%;
    height: inherit;
  }
  .graph {
    zoom: 85%;
    height: inherit;
  }
}
@media (max-width: 1270px) and (min-width: 192px) {
  .x_title h2 small {
    display: none;
  }
}
@media (min-width: 768px) {
  .col-md-55 {
    width: 20%;
  }
  .footer_fixed footer {
    margin-left: 0;
  }
  .footer_fixed .nav-sm footer {
    margin-left: 0;
  }
  .tile_count .tile_stats_count .count {
    font-size: 40px;
  }
  .tile_count .tile_stats_count span {
    font-size: 13px;
  }
  .bs-glyphicons {
    margin-right: 0;
    margin-left: 0;
  }
  .bs-glyphicons li {
    width: 12.5%;
    font-size: 12px;
  }
  .cropper .img-container {
    min-height: 516px;
  }
}
@media (min-width: 992px) {
  .col-md-55 {
    width: 20%;
  }
  footer {
    margin-left: 230px;
  }
  .tile_count .tile_stats_count {
    margin-bottom: 10px;
    border-bottom: 0;
    padding-bottom: 10px;
  }
  .tile_count .tile_stats_count:first-child:before {
    border-left: 0;
  }
}
@media (min-width: 1200px) {
  .col-md-55 {
    width: 20%;
  }
}
@media (min-width: 192px) and (max-width: 1270px) {
  table.tile_info span.right {
    margin-right: 7px;
    float: left;
  }
}
@media (min-width: 992px) and (max-width: 1100px) {
  .tile_count .tile_stats_count .count {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .mail_list {
    margin-bottom: 5px;
    display: inline-block;
  }
}
@media (max-width: 400px) {
  .cropper .btn-group-crop {
    margin-right: -15px !important;
  }
  .cropper .btn-group-crop > .btn {
    padding-left: 5px;
    padding-right: 5px;
  }
  .cropper .btn-group-crop .docs-tooltip {
    margin-left: -5px;
    margin-right: -5px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
.nav_title {
  border: 0;
}
.nav_title p {
  font-weight: bold;
  font-size: 0.7em;
  font-family: inherit;
  vertical-align: middle;
  height: 50px;
  line-height: 50px;
}
.nav_title p img {
  float: left;
  margin: 5px;
  width: 50px;
}
.select2-result-repository__title,
.select2-result-repository__description {
  padding-top: 3px;
  padding-bottom: 3px;
}
.select2-result-repository__title {
  font-weight: bold;
}
.resizable_textarea {
  max-width: 100%;
}
.tt-menu {
  width: auto;
  min-width: 100%;
  padding: 5px;
  background-color: #F7F7F7;
}
.tt-menu .tt-dataset .tt-suggestion {
  padding: 5px;
}
.tt-menu .tt-dataset .tt-suggestion:hover,
.tt-menu .tt-dataset .tt-suggestion:focus,
.tt-menu .tt-dataset .tt-suggestion:active,
.tt-menu .tt-dataset .tt-suggestion.tt-cursor {
  background-color: #73879C;
  color: #FFFFFF;
  cursor: move;
}
.twitter-typeahead {
  width: 100%;
}
.colunaAcoes {
  width: 60px;
}
.buttons-columnVisibility {
  text-decoration: line-through;
  font-weight: bold;
}
.buttons-columnVisibility.active {
  text-decoration: none;
  font-weight: normal;
}
.gritter-item-wrapper.dt-button-info {
  padding: 12px 11px 8px;
  z-index: 1999;
  position: fixed;
  top: 30%;
  left: 50%;
  background-color: #191919;
}
.gritter-item-wrapper.dt-button-info > h2 {
  margin-top: 5px;
  margin-left: 5px;
}
.daterangepicker .calendar {
  max-width: 300px;
}
.btn-opcoes {
  min-width: 15px !important;
}
.tableTools-container {
  margin-bottom: 5px;
}
.tableTools-container .dt-buttons {
  background-color: #191919;
  border-color: #4c4c4c;
}
.tableTools-container .dt-buttons .dt-button {
  border: 1px solid #000000;
}
.campo-arquivo {
  padding-left: 10px;
  padding-right: 10px;
  display: inline-block;
  position: relative;
}
.campo-arquivo i {
  font-size: 2em;
  padding-left: 10px;
  padding-right: 10px;
  float: left;
}
.campo-arquivo span {
  height: 26px;
  vertical-align: middle;
  display: inline-block;
  margin: 0;
  padding: 0;
  float: left;
  line-height: 2;
}
.campo-arquivo span:last-of-type {
  padding-left: 15px;
  color: #172d44;
  font-style: italic;
  display: none;
}
.campo-arquivo span:last-of-type:before {
  content: "\f016";
  font-family: FontAwesome;
  margin-right: 5px;
}
.cke,
.cke_combo_button,
.cke_toolgroup {
  border-radius: 0 !important;
}
.input-group-addon {
  border-radius: 0px;
}
.tab-pane {
  padding-top: 10px;
}
.form-inline .form-group {
  margin-bottom: 10px;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
}
.form-inline div[class^="col-md-"] .form-group,
.form-inline div[class^="col-md-"] .form-control,
.form-inline div[class^="col-md-"] .input-group {
  width: 100%;
}
.dataTable tr.even td {
  color: #3f2f00;
  background-color: #FFFFFF;
}
.dataTable tr.odd td {
  color: #3f2f00;
  background-color: #F9F9F9;
}
.dataTable tr.even.selected td {
  color: #3f2f00;
  background-color: #EDEDED;
}
.dataTable tr.odd.selected td {
  color: #3f2f00;
  background-color: #EDEDED;
}
.form-control {
  color: #333;
}
.control-label {
  color: #3f2f00;
}
.fc-event {
  font-size: .85em;
  line-height: 1.3;
  border-radius: 1px;
  border: 1px solid #FDC402;
  background-color: #FDC402;
  font-weight: 400;
  color: #333;
}
.fc-event:hover {
  color: #000000;
}
.ui-widget .fc-event {
  color: #333;
}
.fc-unthemed .fc-today {
  background: #EDEDED;
}
.fc-button-group button {
  border-radius: 0px !important;
}
.evento-finalizado {
  background-color: #ECF0F1;
  color: #333;
  border: 0px;
}
.evento-atrasado {
  background-color: #E74C3C;
  color: #FFFFFF;
  border: 0px;
}
.evento-hoje {
  background-color: #3498DB;
  color: #FFFFFF;
  border: 0px;
}
.evento-cpa {
  background-color: #FFB100;
  color: #FFFFFF;
  border: 0px;
}
.evento-cpa:before {
  content: "\f146";
  font-family: FontAwesome;
}
.evento-cre {
  background-color: #1ABB9C;
  color: #FFFFFF;
  border: 0px;
}
.evento-cre:before {
  content: "\f0fe";
  font-family: FontAwesome;
}
.meu-evento::before {
  color: #484848;
  content: "\f007";
  font-family: FontAwesome;
}
/***************** Nestable *******************/
.cf:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.small {
  color: #666;
  font-size: 0.875em;
}
.large {
  font-size: 1.25em;
}
/**
 * Nestable
 */
.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}
.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.dd-list .dd-list {
  padding-left: 30px;
}
.dd-collapsed .dd-list {
  display: none;
}
.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}
.dd-handle {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #ccc;
  background: #fafafa;
  background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: linear-gradient(top, #fafafa 0%, #eee 100%);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.dd-handle:hover {
  color: #2ea8e5;
  background: #fff;
}
.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}
.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}
.dd-item > button[data-action="collapse"]:before {
  content: '-';
}
.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}
.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}
.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}
.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}
/**
 * Nestable Extras
 */
.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
  border: 0;
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
}
#nestable-menu {
  padding: 0;
  margin: 20px 0;
}
#nestable-output,
#nestable2-output {
  width: 100%;
  height: 7em;
  font-size: 0.75em;
  line-height: 1.333333em;
  font-family: Consolas, monospace;
  padding: 5px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
#nestable2 .dd-handle {
  color: #fff;
  border: 1px solid #999;
  background: #bbb;
  background: -webkit-linear-gradient(top, #bbb 0%, #999 100%);
  background: -moz-linear-gradient(top, #bbb 0%, #999 100%);
  background: linear-gradient(top, #bbb 0%, #999 100%);
}
#nestable2 .dd-handle:hover {
  background: #bbb;
}
#nestable2 .dd-item > button:before {
  color: #fff;
}
@media only screen and (min-width: 700px) {
  .dd {
    float: left;
    width: 48%;
  }
  .dd + .dd {
    margin-left: 2%;
  }
}
.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}
/**
 * Nestable Draggable Handles
 */
.dd3-content {
  display: block;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px 5px 40px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
  border: 1px solid #ccc;
  background: #fafafa;
  background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
  background: linear-gradient(top, #fafafa 0%, #eee 100%);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.dd3-content:hover {
  color: #2ea8e5;
  background: #fff;
}
.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}
.dd3-item > button {
  margin-left: 30px;
}
.dd3-handle {
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  cursor: pointer;
  width: 30px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 1px solid #aaa;
  background: #ddd;
  background: -webkit-linear-gradient(top, #ddd 0%, #bbb 100%);
  background: -moz-linear-gradient(top, #ddd 0%, #bbb 100%);
  background: linear-gradient(top, #ddd 0%, #bbb 100%);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.dd3-handle:before {
  content: '≡';
  display: block;
  position: absolute;
  left: 0;
  top: 3px;
  width: 100%;
  text-align: center;
  text-indent: 0;
  color: #fff;
  font-size: 20px;
  font-weight: normal;
}
.dd3-handle:hover {
  background: #ddd;
}
/***************** FIM Nestable *******************/
.switchery.switchery-small {
  background-color: #FDC402 !important;
  border-color: #FDC402 !important;
  box-shadow: #FDC402 0px 0px 0px 11px inset !important;
}
.negrito {
  font-weight: bold;
}
.widget-loading {
  color: #333;
}
.importacao-noticia .titulo {
  color: #333;
  font-weight: bold;
  padding-left: 15px;
  padding-top: 15px;
}
.importacao-noticia .titulo p {
  font-size: 1.1em;
  color: #FDC402;
}
.noticia-completa {
  padding: 20px;
}
.noticia-completa p {
  padding: 10px 5px !important;
  line-height: 20px !important;
}
.noticia-completa a {
  font-weight: bold;
}
.noticia-completa .titulo {
  color: #333;
  font-weight: bold;
  padding: 5px;
}
input[type="checkbox"] {
  margin-top: 0px;
}
.nomeUsuarioLogoffViaID {
  padding: 20px;
  font-weight: bold;
  font-size: 1.2em;
  color: #3f3f3f;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #585858;
  margin-right: 0px;
  padding-right: 5px;
  font-size: 1.2em;
}
.imgBandeira {
  max-width: 200px;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}
.limpar_arquivo {
  display: block;
  padding-left: 0;
  margin-left: 0;
}
