@charset "UTF-8";

@color_5: #C4CFDA;
@color_13: #F2F5F7;
@color_14: #E7E7E7;
@color_15: #515356;
@color_16: #93A2B2;
@color_17: rgba(255, 255, 255, 0.75);
@color_18: #C5C7CB;
@color_19: #555;
@color_20: #C0392B;
@color_21: #BDBDBD;
@color_22: #768399;
@color_23: #999;
@color_24: #444;
@color_25: #33a1c9;
@color_26: #757679;
@color_27: #5E6974;
@color_28: rgba(58, 58, 58, 0.41);
@color_29: rgba(255, 255, 255, 0.8);
@color_30: #428bca;
@color_32: #ADABAB;
@color_34: #333;
@color_35: inherit;
@color_36: #bbb;
@color_37: #468847;
@color_38: #B94A48;
@color_40: #777;
@color_41: #3399ff;
@color_42: #E9EDEF;
@color_43: #666;
@color_44: #333333;
@color_45: #F1F6F7;
@color_46: #82ad2b;
@color_47: #000;
@color_48: #90111A;
@color_49: #aab6aa;
@color_51: #7E7E7E;
@color_52: #00aeef;
@color_53: transparent;
@color_54: #ccc;
@color_55: #536A7F;
@font-family_1: "Helvetica Neue", Roboto, Arial, "Droid Sans", sans-serif;
@font_awesome: FontAwesome;
@font-family_3: helvetica;
@font-family_4: Georgia, serif;
@font-family_5: sans-serif;

/****************** Cores gerais ******************/
@cor_azul: #3498DB;
@cor_roxo: #9B59B6;
@cor_verde: #1ABB9C;
@cor_aero: #9CC2CB;
@cor_vermelho: #E74C3C;
@cor_escuro: #34495E;
@cor_branco: #FFFFFF;
@cor_laranja: #F39C12;
@cor_azul_ceu: #50C1CF;
@cor_cinza: #BDC3C7;
/****************** FIM Cores gerais ******************/

/****************** Alertas e notificações ******************/
@cor_texto_alert: @color_42;
@cor_fundo_alert_sucesso: rgba(38, 185, 154, 0.88);
@cor_borda_alert_sucesso: rgba(38, 185, 154, 0.88);
@cor_fundo_alert_info: rgba(52, 152, 219, 0.88);
@cor_borda_alert_info: rgba(52, 152, 219, 0.88);
@cor_fundo_alert_aviso: rgba(243, 156, 18, 0.88);
@cor_borda_alert_aviso: rgba(243, 156, 18, 0.88);
@cor_fundo_alert_perigo: rgba(231, 76, 60, 0.88);
@cor_borda_alert_perigo: rgba(231, 76, 60, 0.88);
@cor_fundo_alert_erro: rgba(231, 76, 60, 0.88);
@cor_borda_alert_erro: rgba(231, 76, 60, 0.88);
@cor_fundo_notify: rgba(52, 73, 94, 0.88);
@cor_borda_notify: rgba(52, 73, 94, 0.88);
/****************** FIM Alertas e notificações ******************/

/****************** Elementos formulário ******************/
@cor_fundo_botao_sucesso: #26B99A;
//Form-control
@box_shadow_form_control: inset 0 1px 0px rgba(0, 0, 0, 0.075);
@border_form_control: 1px solid rgba(221, 226, 232, 0.49);
@border_form_control_focus: 1px solid rgba(221, 226, 232, 0.49);

//Form input-group
@box_shadow_input_group_button: inset 0 1px 1px rgba(0, 0, 0, 0.075);
@border_input_group_button: 1px solid rgba(221, 226, 232, 0.49);
@border_radius_input_group_button: 0px 25px 25px 0px;
/****************** FIM Elementos formulário ******************/

/****************** Datatables ******************/
@cor_linha1_datatable: #F9F9F9;
@cor_linha2_datatable: @cor_branco;
@cor_linha1_selecionada_datatable: #EDEDED;
@cor_linha2_selecionada_datatable: #EDEDED;
@cor_texto_linha2_datatable: @cor_texto_geral;
@cor_texto_linha1_datatable: @cor_texto_geral;
@cor_texto_linha2_selecionada_datatable: @cor_texto_geral;
@cor_texto_linha1_selecionada_datatable: @cor_texto_geral;
/****************** FIM Datatables ******************/

/****************** Geral - Estrutura ******************/
@cor_fundo_painel_ferramentas: #F5F7FA;
@cor_texto_geral: #73879C;
@cor_fundo_cabecalho: #EDEDED;
@cor_fundo_rodape: @cor_branco;
@cor_texto_rodape: @cor_texto_geral;
@cor_fundo_container_coluna_direita: #F7F7F7;
@cor_fundo_container_coluna_direita: lighten(@cor_fundo_cabecalho, 5%);
@cor_fundo_body: #2A3F54;
@cor_fundo_link_selecionado: #333; //e menu hover
@cor_fundo_menu: @cor_fundo_body;
@cor_link_menu: #E7E7E7;
@cor_link_menu_hover: lighten(#E7E7E7, 5%);
@cor_link_menu_filho: #E7E7E7;
@cor_link_menu_filho_hover: lighten(#E7E7E7, 10%);
@cor_rodape_menu: @cor_fundo_menu;
@cor_rodape_menu_link: darken(@cor_fundo_menu, 10%);
@cor_rodape_menu_link_hover: @cor_fundo_link_selecionado;
@cor_fundo_menu_ativo: linear-gradient(lighten(@cor_fundo_body, 10%), lighten(@cor_fundo_body, 5%)), @cor_fundo_body;
@cor_borda_menus: @cor_verde;
@cor_palavra_menu: @color_2;
@text_shadow_link_menu_ativo: rgba(0, 0, 0, 0.25) 0 -1px 0;
@box_shadow_link_menu_ativo: rgba(0, 0, 0, 0.25) 0 1px 0, inset rgba(255, 255, 255, 0.16) 0 1px 0;
@cor_fundo_nav_title: #191919;
@cor_texto_menu_link_hover: #FFF;
@cor_rodape_menu_link_hover: @cor_fundo_link_selecionado;
@cor_linha_x_title: #E1E1E1;
@cor_nome_usuario: #333;
@cor_borda_x_panel: #E1E1E1;
@cor_link_toggle: #333;
@cor_link_toggle_hover: #E7E7E7;
@cor_link_menu_rodape: #FFF;
/****************** FIM Estrutura ******************/

/****************** Menu - Imagem Pefil ******************/
@cor_fundo_imagem_perfil: @cor_branco;
@borda_imagem_perfil: 1px solid rgba(52, 73, 94, 0.44);
@cor_nome_empresa: #ECF0F1;
@cor_texto_bem_vindo: #BAB8B8;
@sombra_palavra_menu: none; //0px 0px #333;
/****************** FIM Menu - Imagem Pefil ******************/

/****************** Agenda ******************/
@cor_fundo_evento_agenda: @cor_borda_menus;
@cor_borda_evento_agenda: @cor_borda_menus;
@cor_texto_evento_agenda: #333;
@cor_dia_atual_agenda: @cor_fundo_cabecalho;
@cor_fundo_evento_finalizado_agenda: #ECF0F1;
@cor_texto_evento_finalizado_agenda: #333;
@cor_fundo_evento_atrasado_agenda: @cor_vermelho;
@cor_texto_evento_atrasado_agenda: @cor_branco;
@cor_fundo_evento_hoje_agenda: @cor_azul;
@cor_texto_evento_hoje_agenda: @cor_branco;
@cor_icone_meu_evento: #484848;

@cor_fundo_evento_cpa_agenda: #FFB100;
@cor_texto_evento_cpa_agenda: @cor_branco;
@cor_fundo_evento_cre_agenda: #1ABB9C;
@cor_texto_evento_cre_agenda: @cor_branco;

/****************** FIM Agenda ******************/

@color_2: #EDEDED;

/****************** Geral - Estrutura ******************/
@cor_texto_geral: #3f2f00;
@cor_fundo_cabecalho: #EDEDED;
@cor_fundo_rodape: #FFFFFF;
@cor_texto_rodape: @cor_texto_geral;
@cor_fundo_container_coluna_direita: #F7F7F7;
@cor_fundo_container_coluna_direita: lighten(@cor_fundo_cabecalho, 5%);
@cor_fundo_body: #191919;
//@cor_fundo_link_selecionado: rgba(255, 255, 255, 0.05); //e menu hover

@cor_texto_link_cabecalho_hover: #333;
@cor_fundo_link_cabecalho_hover: rgba(0, 0, 0, 0.07);

@cor_fundo_menu: @cor_fundo_body;
@cor_rodape_menu: @cor_fundo_menu;
@cor_rodape_menu_link: darken(@cor_fundo_menu, 10%);
@cor_rodape_menu_link_hover: @cor_fundo_link_selecionado;
@cor_fundo_menu_ativo: linear-gradient(lighten(@cor_fundo_body, 10%), lighten(@cor_fundo_body, 5%)), @cor_fundo_body;
@cor_borda_menus: #FDC402;
@borda_menu_lateral: 5px solid @cor_borda_menus;
@cor_inputs: #333;
@cor_labels: @cor_texto_geral;
//Cor da linha que une os links (bolinhas)
@cor_linha_links: lighten(@cor_fundo_menu, 15%);

.left_col {
    background: @cor_fundo_menu;
    .mCSB_scrollTools {
        width: 6px;
    }
    .mCSB_dragger {
        max-height: 400px !important;
    }
}

.nav-sm {
    .container.body {
        .col-md-3.left_col {
            min-height: 100%;
            width: 70px;
            padding: 0;
            z-index: 9999;
            position: absolute;
            .mCSB_container {
                overflow: visible;
            }
            .mCustomScrollBox {
                overflow: visible;
            }
        }
        .col-md-3.left_col.menu_fixed {
            position: fixed;
            height: 100%;
        }
        .right_col {
            padding: 10px 20px;
            margin-left: 70px;
            z-index: 2;
        }
    }
    .hidden-small {
        visibility: hidden;
    }
    .navbar.nav_title {
        width: 70px;
        a {
            span {
                display: none;
            }
            i {
                font-size: 27px;
                margin: 13px 0 0 3px;
            }
        }
    }
    .main_container {
        .top_nav {
            display: block;
            margin-left: 70px;
            z-index: 2;
        }
    }
    .nav.side-menu {
        li {
            a {
                text-align: center !important;
                font-weight: 400;
                font-size: 10px;
                padding: 10px 5px;
                i.toggle-up {
                    display: none !important;
                }
                i {
                    font-size: 25px !important;
                    text-align: center;
                    //width: 100% !important;
                    margin-bottom: 5px;
                }
            }
        }
        li.active-sm {
            border-right: @borda_menu_lateral;
            ul {
                ul {
                    position: static;
                    width: 200px;
                    background: none;
                }
            }
        }
    }
    .nav.child_menu {
        li.active {
            border-right: @borda_menu_lateral;
        }
    }
    ul.nav.child_menu {
        ul {
            position: static;
            width: 200px;
            background: none;
        }
        left: 100%;
        position: absolute;
        top: 0;
        width: 210px;
        z-index: 4000;
        background: @cor_fundo_menu;
        display: none;
        li {
            padding: 0 10px;
            a {
                text-align: left !important;
            }
        }
    }
    > .nav.side-menu {
        > li.active-sm {
            > a {
                color: @cor_verde !important;
            }
        }
    }
    .profile {
        display: none;
    }
    .menu_section {
        margin: 0;
        h3 {
            display: none;
        }
    }
    span.fa {
        display: none;
    }
    li {
        li {
            span.fa {
                display: inline-block;
            }
        }
    }
    footer {
        margin-left: 70px;
    }
}

.site_title {
    i {
        border: 10px solid #EAEAEA;
        padding: 5px 6px;
        border-radius: 50%;
    }
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 400;
    font-size: 22px;
    width: 100%;
    color: @cor_nome_empresa !important;
    margin-left: 0 !important;
    line-height: 59px;
    display: block;
    height: 55px;
    margin: 0;
    /*padding-left: 10px;*/
    &:hover {
        text-decoration: none;
    }
    &:focus {
        text-decoration: none;
    }

    img {
        /*margin: 6px;*/
        /*max-height: 85%;*/
        text-align: center;
        margin: 0 auto;
    }
}

.menu_section {
    margin-bottom: 35px;
    h3 {

        padding-left: 23px;
        color: @cor_palavra_menu;
        text-transform: uppercase;
        letter-spacing: .5px;
        font-weight: bold;
        font-size: 11px;
        margin-bottom: 0;
        margin-top: 0;
        text-shadow: @sombra_palavra_menu;
    }
    > ul {
        margin-top: 10px;
    }
}

.profile_pic {
    width: 35%;
    float: left;
}

.img-circle.profile_img {
    width: 70%;
    background: @cor_fundo_imagem_perfil;
    margin-left: 15%;
    z-index: 1000;
    position: inherit;
    margin-top: 20px;
    border: @borda_imagem_perfil;
    padding: 4px;
}

.profile_info {
    padding: 25px 10px 10px;
    width: 65%;
    float: left;
    span {
        font-size: 13px;
        line-height: 30px;
        color: @cor_texto_bem_vindo;
    }
    h2 {
        font-size: 14px;
        color: @cor_nome_empresa;
        margin: 0;
        font-weight: 300;
    }
}

.profile.img_2 {
    text-align: center;
    .profile_pic {
        width: 100%;
        .img-circle.profile_img {
            width: 50%;
            margin: 10px 0 0;
        }
    }
    .profile_info {
        padding: 15px 10px 0;
        width: 100%;
        margin-bottom: 10px;
        float: left;
    }
}

.main_menu {
    span.fa {
        float: right;
        text-align: center;
        margin-top: 5px;
        font-size: 10px;
        min-width: inherit;
        color: @cor_borda_menus;
    }
    .fa {
        width: 26px;
        opacity: .99;
        display: inline-block;
        font-family: @font_awesome;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.active {
    a {
        span.fa {
            text-align: right !important;
            margin-right: 4px;
        }
    }
}

.nav_menu {
    float: left;
    background: @cor_fundo_cabecalho;
    border-bottom: 1px solid darken(@cor_fundo_cabecalho, 5%);
    margin-bottom: 10px;
    width: 100%;
    position: relative;
}

.nav-md {
    .container.body {
        .col-md-3.left_col {
            min-height: 100%;
            width: 230px;
            padding: 0;
            position: absolute;
            display: -ms-flexbox;
            display: flex;
            z-index: 1;
        }
        .col-md-3.left_col.menu_fixed {
            height: 100%;
            position: fixed;
        }
        .right_col {
            padding: 10px 20px 0;
            margin-left: 230px;
        }
    }
    ul.nav.child_menu {
        li {
            &:before {
                background: @cor_linha_links;
                bottom: auto;
                content: "";
                height: 8px;
                left: 23px;
                margin-top: 15px;
                position: absolute;
                right: auto;
                width: 8px;
                z-index: 1;
                border-radius: 50%;
            }
            &:after {
                border-left: 1px solid @cor_linha_links;
                bottom: 0;
                content: "";
                left: 27px;
                position: absolute;
                top: 0;
            }
        }
    }
}

body {
    .container.body {
        .right_col {
            background: @cor_fundo_container_coluna_direita;
        }
    }
    color: @cor_texto_geral;
    background: @cor_fundo_body;
    font-family: @font-family_1;
    font-size: 13px;
    font-weight: 400;
    //line-height: 1.471;
    line-height: 1;
}

.nav_title {
    width: 230px;
    float: left;
    background: @cor_fundo_nav_title;
    border-radius: 0;
    height: 58px;
}

.blue {
    color: @cor_azul;
}

.purple {
    color: @cor_roxo;
}

.green {
    color: @cor_verde;
}

.aero {
    color: @cor_aero;
}

.red {
    color: @cor_vermelho;
}

.dark {
    color: @cor_escuro;
}

.orange {
    color: @cor_laranja;
}

.border-blue {
    border-color: @cor_azul !important;
}

.border-purple {
    border-color: @cor_roxo !important;
}

.border-green {
    border-color: @cor_verde !important;
}

.border-aero {
    border-color: @cor_aero !important;
}

.border-red {
    border-color: @cor_vermelho !important;
}

.border-dark {
    border-color: @cor_escuro !important;
}

.bg-white {
    background: @cor_branco !important;
    border: 1px solid @cor_branco !important;
    color: @cor_texto_geral;
}

.bg-green {
    background: @cor_verde !important;
    border: 1px solid @cor_verde !important;
    color: @color_2;
}

.bg-red {
    background: @cor_vermelho !important;
    border: 1px solid @cor_vermelho !important;
    color: @color_2;
}

.bg-blue {
    background: @cor_azul !important;
    border: 1px solid @cor_azul !important;
    color: @color_2;
}

.bg-orange {
    background: @cor_laranja !important;
    border: 1px solid @cor_laranja !important;
    color: @color_2;
}

.bg-purple {
    background: @cor_roxo !important;
    border: 1px solid @cor_roxo !important;
    color: @color_2;
}

.bg-blue-sky {
    background: @cor_azul_ceu !important;
    border: 1px solid @cor_azul_ceu !important;
    color: @color_2;
}

.bg-aero {
    background: @cor_aero !important;
    border: 1px solid @cor_aero !important;
    color: @cor_texto_geral;
}

.bg-planocontas {
    background: @color_13 !important;
    border: 1px solid @color_13 !important;
    color: @cor_texto_geral;
}

.bg-white {
    background: @cor_branco !important;
    border: 1px solid @cor_branco !important;
    color: @color_2;
}

.container {
    width: 100%;
    padding: 0;
}

.navbar-nav {
    > li {
        > a {
            color: @color_2 !important;
            color: @color_2 !important;
            font-weight: 500;
            color: @cor_nome_empresa !important;
            margin-left: 0 !important;
            line-height: 32px;
        }
    }
    .open {
        .dropdown-menu {
            position: absolute;
            background: @cor_branco;
            margin-top: 0;
            border: 1px solid #D9DEE4;
            -webkit-box-shadow: none;
            right: 0;
            left: auto;
            //width: 220px;
        }
        .dropdown-menu.msg_list {
            width: 300px;
        }
    }
}

.navbar-brand {
    color: @color_2 !important;
    font-weight: 500;
    color: @cor_nome_empresa !important;
    margin-left: 0 !important;
    line-height: 32px;
}

.top_nav {
    .nav {
        > li {
            > a {
                &:focus {
                    color: @cor_texto_link_cabecalho_hover;
                    background: @cor_fundo_link_cabecalho_hover;
                }
                &:hover {
                    color: @cor_texto_link_cabecalho_hover;
                    background: @cor_fundo_link_cabecalho_hover;
                }
            }
        }
        .open {
            > a {
                background: @cor_rodape_menu_link_hover;
                &:focus {
                    background: @cor_fundo_link_cabecalho_hover;
                }
                &:hover {
                    background: @cor_fundo_link_cabecalho_hover;
                }
            }
        }
    }
    .navbar-right {
        margin: 0;
        width: 70%;
        float: right;
        li {
            display: inline-block;
            float: right;
            position: static;
        }
    }
    .dropdown-menu {
        li {
            width: 100%;
            a {
                width: 100%;
                padding: 12px 20px;
            }
        }
        li.active {
            width: 100%;
            a {
                color: @cor_texto_geral;
                text-decoration: none;
                background-color: @cor_borda_menus;
                outline: 0;
            }
        }
    }
    li {
        a {
            i {
                font-size: 15px;
            }
        }
    }
}

.main_container {
    .top_nav {
        display: block;
        margin-left: 230px;
    }
}

.no-padding {
    padding: 0 !important;
}

.page-title {
    width: 100%;
    height: 65px;
    padding: 10px 0;
    .title_left {
        width: 45%;
        float: left;
        display: block;
        h3 {
            margin: 9px 0;
        }
    }
    .title_right {
        width: 55%;
        float: left;
        display: block;
        .pull-right {
            margin: 10px 0;
        }
    }
}

.fixed_height_320 {
    height: 320px;
}

.fixed_height_390 {
    height: 390px;
}

.fixed_height_200 {
    height: 200px;
}

.overflow_hidden {
    overflow: hidden;
}

.progress-bar-dark {
    background-color: @cor_escuro !important;
}

.progress-bar-gray {
    background-color: @cor_cinza !important;
}

table.no-margin {
    .progress {
        margin-bottom: 0;
    }
}

.main_content {
    padding: 10px 20px;
}

.col-md-55 {
    width: 50%;
    margin-bottom: 10px;
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.center-margin {
    margin: 0 auto;
    float: none !important;
}

.col-xs-1 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-sm-1 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-md-1 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-lg-1 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-2 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-sm-2 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-md-2 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-lg-2 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-3 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-sm-3 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-md-3 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-lg-3 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-4 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-sm-4 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-md-4 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-lg-4 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-5 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-sm-5 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-md-5 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-lg-5 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-6 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-sm-6 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-md-6 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-lg-6 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-7 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-sm-7 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-md-7 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-lg-7 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-8 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-sm-8 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-md-8 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-lg-8 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-9 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-sm-9 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-md-9 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-lg-9 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-10 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-sm-10 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-md-10 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-lg-10 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-11 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-sm-11 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-md-11 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-lg-11 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-xs-12 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-sm-12 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-md-12 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.col-lg-12 {
    position: relative;
    min-height: 1px;
    float: left;
    padding-right: 10px;
    padding-left: 10px;
}

.row {
    margin-right: -10px;
    margin-left: -10px;
}

.grid_slider {
    .col-md-6 {
        padding: 0 40px;
    }
}

h1 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.h1 {
    margin-top: 10px;
    margin-bottom: 10px;
}

h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 400;
}

.h2 {
    margin-top: 10px;
    margin-bottom: 10px;
}

h3 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.h3 {
    margin-top: 10px;
    margin-bottom: 10px;
}

a {
    color: @cor_texto_geral;
    text-decoration: none;
    outline: 0;
    &:visited {
        outline: 0;
    }
    &:focus {
        outline: 0;
        text-decoration: none;
        outline: none;
    }
    &:active {
        outline: 0;
    }
    &:hover {
        text-decoration: none;
    }
}

&:visited {
    outline: 0;
}

&:focus {
    outline: 0;
}

&:active {
    outline: 0;
}

.btn {
    &:focus {
        outline: 0;
    }
    &:active {
        &:focus {
            outline: 0;
        }
    }
    &:active.focus {
        outline: 0;
    }
    margin-bottom: 5px;
    margin-right: 5px;
    border-radius: 0px;
}

.btn.active {
    &:focus {
        outline: 0;
    }
}

.btn.focus {
    outline: 0;
}

.btn.active.focus {
    outline: 0;
}

.navbar {
    margin-bottom: 0;
}

.navbar-header {
    background: @cor_escuro;
}

.navbar-right {
    margin-right: 0;
}

.navbar-static-top {
    position: fixed;
    top: 0;
    width: 100%;
}

.sidebar-header {
    border-bottom: 0;
    margin-top: 46px;
    &:first-of-type {
        margin-top: 0;
    }
}

.nav.side-menu {
    > li {
        position: relative;
        display: block;
        cursor: pointer;
        > a {
            margin-bottom: 6px;
            color: @cor_link_menu;
            font-weight: 500;
            &:hover {
                color: @cor_link_menu_hover !important;
                text-decoration: none;
                background: transparent;
            }
        }
    }
    > li.current-page {
        border-right: @borda_menu_lateral;
    }
    > li.active {
        border-right: @borda_menu_lateral;
        > a {
            text-shadow: @text_shadow_link_menu_ativo;
            background: @cor_fundo_menu_ativo;
            box-shadow: @box_shadow_link_menu_ativo;
            color: @cor_texto_menu_link_hover;

            &:hover {
                color: @cor_texto_menu_link_hover !important;
                text-decoration: none;
            }
        }
    }
}

.nav {
    > li {
        > a {
            &:focus {
                text-decoration: none;
                background: transparent;
                background-color: transparent;
            }
            position: relative;
            display: block;
            padding: 13px 15px 12px;
            &:hover {
                background-color: transparent;
            }
        }
    }
    li.current-page {
        background: @cor_fundo_link_selecionado;
        a {
            color: @cor_texto_menu_link_hover;
        }
    }
    li {
        li {
            li.current-page {
                background: none;
            }
        }
        li.current-page {
            a {
                color: @color_2;
            }
        }
    }
}

.nav.child_menu {
    display: none;
    li {
        &:hover {
            background-color: @cor_fundo_link_selecionado;

            a {
                color: @cor_texto_menu_link_hover;
            }
        }
        padding-left: 36px;
        li {
            &:hover {
                background: none;
            }
            a {
                &:hover {
                    color: @color_2;
                }
            }
            a.active {
                color: @color_2;
            }
        }
        li.active {
            background: none;
        }
    }
    li.active {
        background-color: @cor_fundo_link_selecionado;

    }
    li.current-page {
        a {
            color: @cor_texto_menu_link_hover;
        }
    }
    > li {
        > a {
            color: @cor_link_menu_filho;
            font-weight: 500;
            font-size: 12px;
            padding: 9px;
        }
    }
}

.nav.navbar-nav {
    > li {
        > a {
            color: @cor_nome_usuario !important;
        }
    }
}

.nav.top_menu {
    > li {
        > a {
            position: relative;
            display: block;
            padding: 10px 15px;
            color: @cor_escuro !important;
        }
    }
}

.top_search {
    padding: 0;
    .form-control {
        border-right: 0;
        box-shadow: @box_shadow_form_control;
        border-radius: 25px 0px 0px 25px;
        padding-left: 20px;
        border: @border_form_control;
        &:focus {
            border: @border_form_control_focus;
            border-right: 0;
        }
    }
    .input-group-btn {
        button {
            border-radius: @border_radius_input_group_button;
            border: @border_input_group_button;
            border-left: 0;
            box-shadow: @box_shadow_input_group_button;
            color: @color_16;
            margin-bottom: 0 !important;
            height: 38px !important;
        }
    }
}

.toggle {
    float: left;
    margin: 0;
    padding-top: 16px;
    width: 70px;
    a {
        padding: 15px 15px 0;
        margin: 0;
        cursor: pointer;
        color: @cor_link_toggle;
        i {
            font-size: 26px;
        }

        &:hover {
            color: @cor_link_toggle_hover;
        }
    }
}

.panel_toolbox {
    float: right;
    min-width: 10px;
    > li {
        float: left;
        cursor: pointer;
        > a {
            padding: 6px;
            color: @color_18;
            font-size: 14px;
            &:hover {
                background: @cor_fundo_painel_ferramentas;
            }
        }
    }
}

.line_30 {
    line-height: 30px;
}

.main_menu_side {
    padding: 0;
}

.bs-docs-sidebar {
    .nav {
        > li {
            > a {
                display: block;
                padding: 4px 6px;
            }
        }
    }
}

footer {
    background: @cor_fundo_rodape;
    padding: 0px 20px;
    display: block;
    color: @cor_texto_rodape;
    height: 40px;
    vertical-align: middle;
    div {
        text-align: right;
        vertical-align: middle;
        height: 40px;
        line-height: 40px;
    }
}

.footer_fixed {
    footer {
        position: fixed;
        left: 0px;
        bottom: 0px;
        width: 100%;
    }
}

.tile-stats.sparkline {
    padding: 10px;
    text-align: center;
}

.jqstooltip {
    background: @cor_escuro !important;
    width: 30px !important;
    height: 22px !important;
    text-decoration: none;
}

.tooltip {
    display: block !important;
}

.tiles {
    border-top: 1px solid #ccc;
    margin-top: 15px;
    padding-top: 5px;
    margin-bottom: 0;
}

.tile {
    overflow: hidden;
    &:hover {
        text-decoration: none;
    }
}

.top_tiles {
    margin-bottom: 0;
    .tile {
        h2 {
            font-size: 30px;
            line-height: 30px;
            margin: 3px 0 7px;
            font-weight: bold;
        }
    }
}

article.media {
    width: 100%;
}

* {
    box-sizing: border-box;
    &:before {
        box-sizing: border-box;
    }
    &:after {
        box-sizing: border-box;
    }
}

#integration-list {
    width: 100%;
    margin: 0 auto;
    display: table;
    ul {
        padding: 0;
        margin: 20px 0;
        color: @color_19;
        > li {
            list-style: none;
            border-top: 1px solid #ddd;
            display: block;
            padding: 15px;
            overflow: hidden;
            &:hover {
                background: #efefef;
            }
        }
        &:last-child {
            border-bottom: 1px solid #ddd;
        }
    }
}

.expand {
    display: block;
    text-decoration: none;
    color: @color_19;
    cursor: pointer;
    h2 {
        width: 85%;
        float: left;
    }
}

#left {
    display: table;
}

#right {
    display: table;
}

#sup {
    display: table-cell;
    vertical-align: middle;
    width: 80%;
}

.detail {
    a {
        text-decoration: none;
        color: @color_20;
        border: 1px solid #C0392B;
        padding: 6px 10px 5px;
        font-size: 13px;
        margin-right: 7px;
    }
    margin: 10px 0 10px 0px;
    display: none;
    line-height: 22px;
    height: 150px;
    span {
        margin: 0;
    }
}

.right-arrow {
    width: 10px;
    float: right;
    font-weight: bold;
    font-size: 20px;
}

.accordion {
    .panel {
        margin-bottom: 5px;
        border-radius: 0;
        border-bottom: 1px solid #efefef;
        &:hover {
            background: #F2F5F7;
        }
    }
    .panel-heading {
        background: #F2F5F7;
        padding: 13px;
        width: 100%;
        display: block;
    }
}

.x_panel {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 17px;
    display: inline-block;
    background: @cor_branco;
    border: 1px solid @cor_borda_x_panel;
    -webkit-column-break-inside: avoid;
    -moz-column-break-inside: avoid;
    column-break-inside: avoid;
    opacity: 1;
    transition: all .2s ease;
}

.x_title {
    border-bottom: 2px solid @cor_linha_x_title;
    padding: 1px 5px 6px;
    margin-bottom: 10px;
    .filter {
        width: 40%;
        float: right;
    }
    h2 {
        margin: 5px 0 6px;
        float: left;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        min-height: 20px;
        small {
            margin-left: 10px;
        }
    }
    span {
        color: @color_21;
    }
}

.x_content {
    padding: 0 5px 6px;
    position: relative;
    width: 100%;
    float: left;
    clear: both;
    margin-top: 5px;
    h4 {
        font-size: 16px;
        font-weight: 500;
    }
}

legend {
    padding-bottom: 7px;
    font-size: 18px;
    color: @color_35;
}

.demo-placeholder {
    height: 280px;
}

.profile_details {
    &:nth-child(3n) {
        clear: both;
    }
    .profile_view {
        display: inline-block;
        padding: 10px 0 0;
        background: @cor_branco;
        .divider {
            border-top: 1px solid #e5e5e5;
            padding-top: 5px;
            margin-top: 5px;
        }
        .ratings {
            margin-bottom: 0;
            text-align: left;
            font-size: 16px;
        }
        .bottom {
            background: #F2F5F7;
            padding: 9px 0;
            border-top: 1px solid #E6E9ED;
        }
        .left {
            margin-top: 20px;
            p {
                margin-bottom: 3px;
            }
        }
        .right {
            margin-top: 0px;
            padding: 10px;
        }
        .img-circle {
            border: 1px solid #E6E9ED;
            padding: 2px;
        }
        h2 {
            margin: 5px 0;
        }
        .brief {
            margin: 0;
            font-weight: 300;
        }
    }
    .profile_left {
        background: white;
    }
}

.pagination.pagination-split {
    li {
        display: inline-block;
        margin-right: 3px;
        a {
            border-radius: 4px;
            color: @color_22;
            -moz-border-radius: 4px;
            -webkit-border-radius: 4px;
        }
    }
}

table.tile {
    h3 {
        font-weight: bold;
        vertical-align: middle !important;
    }
    h4 {
        font-weight: bold;
        vertical-align: middle !important;
    }
    span {
        font-weight: bold;
        vertical-align: middle !important;
    }
    th {
        text-align: center;
        border-bottom: 1px solid #E6ECEE;
    }
    td {
        text-align: center;
        padding: 5px 0;
        ul {
            text-align: left;
            padding-left: 0;
            li {
                list-style: none;
                width: 100%;
                a {
                    width: 100%;
                    big {
                        right: 0;
                        float: right;
                        margin-right: 13px;
                    }
                }
            }
        }
    }
}

table.tile_info {
    width: 100%;
    padding: 10px 15px;
    td {
        text-align: left;
        padding: 1px;
        font-size: 15px;
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0;
            line-height: 28px;
        }
        i {
            margin-right: 8px;
            font-size: 17px;
            float: left;
            width: 18px;
            line-height: 28px;
        }
        &:first-child {
            width: 83%;
        }
    }
    span.right {
        margin-right: 0;
        float: right;
        position: absolute;
        right: 4%;
    }
}

td {
    span {
        line-height: 28px;
    }
}

.sidebar-widget {
    overflow: hidden;
}

.error-number {
    font-size: 90px;
    line-height: 90px;
    margin: 20px 0;
}

.col-middle {
    margin-top: 5%;
}

.mid_center {
    width: 370px;
    margin: 0 auto;
    text-align: center;
    padding: 10px 20px;
}

h3.degrees {
    font-size: 22px;
    font-weight: 400;
    text-align: center;
}

.degrees {
    &:after {
        content: "o";
        position: relative;
        top: -12px;
        font-size: 13px;
        font-weight: 300;
    }
}

.daily-weather {
    .day {
        font-size: 14px;
        border-top: 2px solid rgba(115, 135, 156, 0.36);
        text-align: center;
        border-bottom: 2px solid rgba(115, 135, 156, 0.36);
        padding: 5px 0;
    }
}

.weather-days {
    .col-sm-2 {
        overflow: hidden;
        width: 16.66666667%;
    }
}

.weather {
    .row {
        margin-bottom: 0;
    }
}

.bulk-actions {
    display: none;
}

table.countries_list {
    width: 100%;
    td {
        padding: 0 10px;
        line-height: 30px;
        border-top: 1px solid #eeeeee;
    }
}

.dataTables_paginate {
    a {
        padding: 6px 9px !important;
        background: #ddd !important;
        border-color: #ddd !important;
        background: #ff0000;
    }
    float: right;
    text-align: right;
}

.paging_full_numbers {
    a.paginate_active {
        background-color: rgba(38, 185, 154, 0.59) !important;
        border-color: rgba(38, 185, 154, 0.59) !important;
        border: 1px solid #aaa;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        padding: 2px 5px;
        margin: 0 3px;
        cursor: pointer;
        background-color: #99B3FF;
    }
    width: 400px;
    height: 22px;
    line-height: 22px;
    a {
        &:active {
            outline: none;
        }
        &:hover {
            text-decoration: none;
        }
    }
    a.paginate_button {
        border: 1px solid #aaa;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        padding: 2px 5px;
        margin: 0 3px;
        cursor: pointer;
        background-color: #ddd;
        &:hover {
            background-color: #ccc;
            text-decoration: none !important;
        }
        &.current {
            font-weight: bold;
            background-color: @cor_fundo_menu !important;
            //color: @cor_borda_menus !important;
        }
    }
}

button.DTTT_button {
    border: 1px solid #E7E7E7 !important;
    background: #E7E7E7 !important;
    box-shadow: none !important;
}

div.DTTT_button {
    border: 1px solid #E7E7E7 !important;
    background: #E7E7E7 !important;
    box-shadow: none !important;
}

a.DTTT_button {
    border: 1px solid #E7E7E7 !important;
    background: #E7E7E7 !important;
    box-shadow: none !important;
}

table.jambo_table {
    border: 1px solid rgba(221, 221, 221, 0.78);
    thead {
        background: rgba(52, 73, 94, 0.94);
        color: @cor_nome_empresa;
    }
    tbody {
        tr {
            &:hover {
                td {
                    background: rgba(38, 185, 154, 0.07);
                    border-top: 1px solid rgba(38, 185, 154, 0.11);
                    border-bottom: 1px solid rgba(38, 185, 154, 0.11);
                }
            }
        }
        tr.selected {
            background: rgba(38, 185, 154, 0.16);
            td {
                border-top: 1px solid rgba(38, 185, 154, 0.4);
                border-bottom: 1px solid rgba(38, 185, 154, 0.4);
            }
        }
    }
}

.dataTables_wrapper {
    position: relative;
    clear: both;
    zoom: 1;
    .dataTables_processing {
        position: absolute;
        top: 50% !important;
        left: 0% !important;
        width: 100% !important;
        height: 50px !important;
        margin-left: 0px !important;
        margin-top: -35px !important;
        padding: 1em 0;
        border: 1px solid #ddd;
        text-align: center;
        color: @color_23;
        font-size: 14px;
        background-color: @cor_fundo_cabecalho;
        z-index: 9999;
    }
}

.dataTables_processing {
    position: absolute;
    top: 50% !important;
    left: 0% !important;
    width: 100% !important;
    height: 50px !important;
    margin-left: 0px !important;
    margin-top: -35px !important;
    padding: 1em 0;
    border: 1px solid #ddd;
    text-align: center;
    color: @color_23;
    font-size: 14px;
    background-color: @cor_fundo_cabecalho;
    z-index: 9999;
}

.dataTables_length {
    width: 40%;
    float: left;
}

.dataTables_filter {
    width: 50%;
    float: right;
    text-align: right;
    display: none !important;
}

.dataTables_info {
    width: 60%;
    float: left;
}

table.dataTable {
    th.focus {
        outline: 2px solid @cor_verde !important;
        outline-offset: -1px;
    }
    td.focus {
        outline: 2px solid @cor_verde !important;
        outline-offset: -1px;
    }
}

table.display {
    margin: 0 auto;
    clear: both;
    width: 100%;
    thead {
        th {
            padding: 8px 18px 8px 10px;
            border-bottom: 1px solid black;
            font-weight: bold;
            cursor: pointer;
            &:active {
                outline: none;
            }
        }
        td {
            &:active {
                outline: none;
            }
        }
    }
    tfoot {
        th {
            padding: 3px 18px 3px 10px;
            border-top: 1px solid black;
            font-weight: bold;
        }
    }
    tr.heading2 {
        td {
            border-bottom: 1px solid #aaa;
        }
    }
    td {
        padding: 3px 10px;
    }
    td.center {
        text-align: center;
    }
    tr.even {
        td {
            background-color: @cor_linha2_datatable;
        }
    }
    tr.odd {
        td {
            background-color: @cor_linha1_datatable;
        }
    }
    tr.even.row_selected {
        td {
            background-color: @cor_linha2_selecionada_datatable;
        }
    }
    tr.odd.row_selected {
        td {
            background-color: @cor_linha1_selecionada_datatable;
        }
    }
}

.dataTables_scroll {
    clear: both;
}

.dataTables_scrollBody {
    *margin-top: -1px;
    -webkit-overflow-scrolling: touch;
}

.top {
    .dataTables_info {
        float: none;
    }
}

.clear {
    clear: both;
}

.dataTables_empty {
    text-align: center;
}

tfoot {
    input {
        margin: 0.5em 0;
        width: 100%;
        color: @color_24;
    }
    input.search_init {
        color: @color_23;
    }
}

td.group {
    background-color: #d1cfd0;
    border-bottom: 2px solid #A19B9E;
    border-top: 2px solid #A19B9E;
}

td.details {
    background-color: #d1cfd0;
    border: 2px solid #A19B9E;
}

.example_alt_pagination {
    div.dataTables_info {
        width: 40%;
    }
}

div.box {
    height: 100px;
    padding: 10px;
    overflow: auto;
    border: 1px solid #8080FF;
    background-color: #E5E5FF;
}

ul.msg_list {
    li {
        background: #f7f7f7;
        padding: 5px;
        display: -ms-flexbox;
        display: flex;
        margin: 6px 6px 0;
        width: 96% !important;
        &:last-child {
            margin-bottom: 6px;
            padding: 10px;
        }
        a {
            /*padding: 3px 5px !important;*/
            .image {
                img {
                    border-radius: 2px 2px 2px 2px;
                    -webkit-border-radius: 2px 2px 2px 2px;
                    float: left;
                    margin-right: 10px;
                    width: 11%;
                }
            }
            .time {
                font-size: 11px;
                font-style: italic;
                font-weight: bold;
                position: absolute;
                right: 35px;
            }
            .message {
                display: block !important;
                font-size: 11px;
            }
        }
    }
}

.dropdown-menu.msg_list {
    span {
        white-space: normal;
    }
}

.dropdown-menu {
    border: medium none;
    box-shadow: none;
    display: none;
    float: left;
    font-size: 12px;
    left: 0;
    list-style: none outside none;
    padding: 0;
    position: absolute;
    text-shadow: none;
    top: 100%;
    z-index: 9998;
    border: 1px solid #D9DEE4;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    > li {
        > a {
            color: @cor_texto_geral;
            padding-top: 8px;
            padding-bottom: 8px;

            &:hover {
                background-color: @cor_fundo_link_selecionado;
                color: @cor_texto_menu_link_hover;
            }
        }
    }
    a {
        cursor: pointer;
    }
}

.info-number {
    .badge {
        font-size: 10px;
        font-weight: normal;
        line-height: 13px;
        padding: 2px 6px;
        position: absolute;
        right: 2px;
        top: 8px;
    }
}

ul.to_do {
    padding: 0;
    li {
        background: #f3f3f3;
        border-radius: 3px;
        position: relative;
        padding: 7px;
        margin-bottom: 5px;
        list-style: none;
    }
    p {
        margin: 0;
    }
}

.dashboard-widget {
    background: #f6f6f6;
    border-top: 5px solid #79C3DF;
    border-radius: 3px;
    padding: 5px 10px 10px;
    .dashboard-widget-title {
        font-weight: normal;
        border-bottom: 1px solid #c1cdcd;
        margin: 0 0 10px 0;
        padding-bottom: 5px;
        padding-left: 40px;
        line-height: 30px;
        i {
            font-size: 100%;
            margin-left: -35px;
            margin-right: 10px;
            color: @color_25;
            padding: 3px 6px;
            border: 1px solid #abd9ea;
            border-radius: 5px;
            background: @cor_branco;
        }
    }
}

ul.quick-list {
    width: 45%;
    padding-left: 0;
    display: inline-block;
    li {
        padding-left: 10px;
        list-style: none;
        margin: 0;
        padding-bottom: 6px;
        padding-top: 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        i {
            padding-right: 10px;
            color: @color_26;
        }
    }
}

.dashboard-widget-content {
    padding-top: 9px;
    .sidebar-widget {
        width: 50%;
        display: inline-block;
        vertical-align: top;
        background: @cor_branco;
        border: 1px solid #abd9ea;
        border-radius: 5px;
        text-align: center;
        float: right;
        padding: 2px;
        margin-top: 10px;
    }
}

.widget_summary {
    width: 100%;
    display: -ms-inline-flexbox;
    display: inline-flex;
    .w_left {
        float: left;
        text-align: left;
    }
    .w_center {
        float: left;
    }
    .w_right {
        float: left;
        text-align: right;
        span {
            font-size: 20px;
        }
    }
}

.w_20 {
    width: 20%;
}

.w_25 {
    width: 25%;
}

.w_55 {
    width: 55%;
}

h5.graph_title {
    text-align: left;
    margin-left: 10px;
    i {
        margin-right: 10px;
        font-size: 17px;
    }
}

span.right {
    float: right;
    font-size: 14px !important;
}

.tile_info {
    a {
        text-overflow: ellipsis;
    }
}

.sidebar-footer {
    bottom: 0px;
    clear: both;
    display: block;
    padding: 5px 0 0 0;
    position: fixed;
    width: 230px;
    line-height: 1.471;
    background: @cor_rodape_menu;
    a {
        padding: 7px 0 3px;
        text-align: center;
        width: 33.3333%;
        font-size: 17px;
        display: block;
        float: left;
        background: @cor_rodape_menu_link;
        &:hover {
            background: @cor_rodape_menu_link_hover;
        }
        color: @cor_link_menu_rodape;
    }
}

.tile_count {
    margin-bottom: 20px;
    margin-top: 20px;
    .tile_stats_count {
        border-bottom: 1px solid #D9DEE4;
        padding: 0 10px 0 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        position: relative;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            height: 65px;
            border-left: 2px solid #ADB2B5;
            margin-top: 10px;
        }
        .count {
            font-size: 30px;
            line-height: 47px;
            font-weight: 600;
        }
        span {
            font-size: 12px;
        }
        .count_bottom {
            i {
                width: 12px;
            }
        }
    }
}

.dashboard_graph {
    background: @cor_branco;
    padding: 7px 10px;
    .col-md-9 {
        padding: 0;
    }
    .col-md-3 {
        padding: 0;
    }
    .x_title {
        padding: 5px 5px 7px;
        h3 {
            margin: 0;
            font-weight: normal;
        }
    }
    p {
        margin: 0 0 4px;
    }
}

a.user-profile {
    color: @cor_nome_usuario !important;
}

.user-profile {
    img {
        width: 29px;
        height: 29px;
        border-radius: 50%;
        margin-right: 10px;
    }
}

ul.top_profiles {
    height: 330px;
    width: 100%;
    li {
        margin: 0;
        padding: 3px 5px;
        &:nth-child(odd) {
            background-color: #eee;
        }
    }
}

.media {
    .profile_thumb {
        border: 1px solid;
        width: 50px;
        height: 50px;
        margin: 5px 10px 5px 0;
        border-radius: 50%;
        padding: 9px 12px;
        i {
            font-size: 30px;
        }
    }
    .date {
        background: #ccc;
        width: 52px;
        margin-right: 10px;
        border-radius: 10px;
        padding: 5px;
        .month {
            margin: 0;
            text-align: center;
            color: @color_2;
        }
        .day {
            text-align: center;
            color: @color_2;
            font-size: 27px;
            margin: 0;
            line-height: 27px;
            font-weight: bold;
        }
    }
}

.event {
    .media-body {
        a.title {
            font-weight: bold;
        }
        p {
            margin-bottom: 0;
        }
    }
}

h4.graph_title {
    margin: 7px;
    text-align: center;
}

.fontawesome-icon-list {
    .fa-hover {
        a {
            &:hover {
                background-color: #ddd;
                color: @color_2;
                text-decoration: none;
                .fa {
                    font-size: 28px;
                    vertical-align: -6px;
                }
            }
            display: block;
            line-height: 32px;
            height: 32px;
            padding-left: 10px;
            border-radius: 4px;
            .fa {
                width: 32px;
                font-size: 16px;
                display: inline-block;
                text-align: right;
                margin-right: 10px;
            }
        }
    }
}

.tile-stats {
    position: relative;
    display: block;
    margin-bottom: 12px;
    border: 1px solid #E4E4E4;
    -webkit-border-radius: 5px;
    overflow: hidden;
    padding-bottom: 5px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 5px;
    -moz-background-clip: padding;
    border-radius: 5px;
    background-clip: padding-box;
    background: @cor_branco;
    transition: all 300ms ease-in-out;
    &:hover {
        .icon {
            i {
                animation-name: tansformAnimation;
                animation-duration: .5s;
                animation-iteration-count: 1;
                color: @color_28;
                animation-timing-function: ease;
                animation-fill-mode: forwards;
                -webkit-animation-name: tansformAnimation;
                -webkit-animation-duration: .5s;
                -webkit-animation-iteration-count: 1;
                -webkit-animation-timing-function: ease;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-name: tansformAnimation;
                -moz-animation-duration: .5s;
                -moz-animation-iteration-count: 1;
                -moz-animation-timing-function: ease;
                -moz-animation-fill-mode: forwards;
            }
        }
    }
    .icon {
        width: 20px;
        height: 20px;
        color: @cor_texto_bem_vindo;
        position: absolute;
        right: 53px;
        top: 22px;
        z-index: 1;
        i {
            margin: 0;
            font-size: 60px;
            line-height: 0;
            vertical-align: bottom;
            padding: 0;
        }
    }
    .count {
        font-size: 38px;
        font-weight: bold;
        line-height: 1.65857;
        position: relative;
        margin: 0;
        margin-left: 10px;
        z-index: 5;
        padding: 0;
    }
    h3 {
        position: relative;
        margin: 0;
        margin-left: 10px;
        z-index: 5;
        padding: 0;
        color: @cor_texto_bem_vindo;
    }
    p {
        position: relative;
        margin: 0;
        margin-left: 10px;
        z-index: 5;
        padding: 0;
        margin-top: 5px;
        font-size: 12px;
    }
    > .dash-box-footer {
        position: relative;
        text-align: center;
        margin-top: 5px;
        padding: 3px 0;
        color: @color_2;
        color: @color_29;
        display: block;
        z-index: 10;
        background: rgba(0, 0, 0, 0.1);
        text-decoration: none;
        &:hover {
            color: @color_2;
            background: rgba(0, 0, 0, 0.15);
            color: @color_2;
            background: rgba(0, 0, 0, 0.15);
        }
    }
}

.tile_header {
    border-bottom: transparent;
    padding: 7px 15px;
    margin-bottom: 15px;
    background: #E7E7E7;
}

.tile_head {
    h4 {
        margin-top: 0;
        margin-bottom: 5px;
    }
}

.tiles-bottom {
    padding: 5px 10px;
    margin-top: 10px;
    background: rgba(194, 194, 194, 0.3);
    text-align: left;
}

a.star {
    color: @color_30 !important;
}

.mail_content {
    background: none repeat scroll 0 0 @cor_branco;
    border-radius: 4px;
    margin-top: 20px;
    min-height: 500px;
    padding: 10px 11px;
    width: 100%;
}

.list-btn-mail {
    margin-bottom: 15px;
    > i {
        float: left;
        font-size: 18px;
        font-style: normal;
        width: 33px;
    }
    > .cn {
        background: none repeat scroll 0 0 #39B3D7;
        border-radius: 12px;
        color: @cor_branco;
        float: right;
        font-style: normal;
        padding: 0 5px;
    }
}

.list-btn-mail.active {
    border-bottom: 1px solid #39B3D7;
    padding: 0 0 14px;
}

.button-mail {
    margin: 0 0 15px !important;
    text-align: left;
    width: 100%;
}

button {
    margin-bottom: 5px;
    margin-right: 5px;
}

.buttons {
    margin-bottom: 5px;
    margin-right: 5px;
}

.modal-footer {
    .btn {
        & + .btn {
            margin-bottom: 5px;
            margin-right: 5px;
        }
    }
}

.btn-group-vertical {
    .btn {
        margin-bottom: 0;
        margin-right: 0;
    }
}

.btn-group {
    .btn {
        margin-bottom: 0;
        margin-right: 0;
    }
    .parsley-errors-list {
        display: none;
    }
}

.mail_list_column {
    border-left: 1px solid #DBDBDB;
}

.mail_view {
    border-left: 1px solid #DBDBDB;
}

.mail_list {
    width: 100%;
    border-bottom: 1px solid #DBDBDB;
    margin-bottom: 2px;
    display: inline-block;
    .left {
        width: 5%;
        float: left;
        margin-right: 3%;
    }
    .right {
        width: 90%;
        float: left;
    }
    h3 {
        font-size: 15px;
        font-weight: bold;
        margin: 0px 0 6px;
        small {
            float: right;
            color: @color_32;
            font-size: 11px;
            line-height: 20px;
        }
    }
    .badge {
        padding: 3px 6px;
        font-size: 8px;
        background: #BAB7B7;
    }
}

.mail_heading {
    h4 {
        font-size: 18px;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
        margin-top: 20px;
    }
}

.attachment {
    margin-top: 30px;
    ul {
        width: 100%;
        list-style: none;
        padding-left: 0;
        display: inline-block;
        margin-bottom: 30px;
        li {
            float: left;
            width: 150px;
            margin-right: 10px;
            margin-bottom: 10px;
            img {
                height: 150px;
                border: 1px solid #ddd;
                padding: 5px;
                margin-bottom: 10px;
            }
            span {
                float: right;
            }
        }
    }
    .file-name {
        float: left;
    }
    .links {
        width: 100%;
        display: inline-block;
    }
}

.compose {
    padding: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    background: @cor_branco;
    border: 1px solid #D9DEE4;
    border-right: 0;
    border-bottom: 0;
    border-top-left-radius: 5px;
    z-index: 9999;
    display: none;
    .compose-header {
        padding: 5px;
        background: #169F85;
        color: @color_2;
        border-top-left-radius: 5px;
        .close {
            text-shadow: 0 1px 0 @cor_branco;
            line-height: .8;
        }
    }
    .compose-body {
        .editor.btn-toolbar {
            margin: 0;
        }
        .editor-wrapper {
            height: 100%;
            min-height: 50px;
            max-height: 180px;
            border-radius: 0;
            border-left: none;
            border-right: none;
            overflow: auto;
        }
    }
    .compose-footer {
        padding: 10px;
    }
}

.editor.btn-toolbar {
    zoom: 1;
    background: #F7F7F7;
    margin: 5px 2px;
    padding: 3px 0;
    border: 1px solid #EFEFEF;
}

.input-group {
    /*margin-bottom: 10px;*/
}

.ln_solid {
    border-top: 1px solid #e5e5e5;
    color: @cor_branco;
    background-color: @cor_branco;
    height: 1px;
    margin: 20px 0;
}

span.section {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: @color_34;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}

.form-control {
    border-radius: 0;
    width: 100%;
    &:focus {
        border-color: #CCD0D7;
        box-shadow: none !important;
    }
}

.form-horizontal {
    .control-label {
        padding-top: 8px;
    }
    .form-group {
        margin-right: 0;
        margin-left: 0;
    }
}

.form-control-feedback {
    margin-top: 8px;
    height: 23px;
    color: @color_36;
    line-height: 24px;
    font-size: 15px;
}

.form-control-feedback.left {
    border-right: 1px solid #ccc;
    left: 13px;
}

.form-control-feedback.right {
    border-left: 1px solid #ccc;
    right: 13px;
}

.form-control.has-feedback-left {
    padding-left: 45px;
}

.form-control.has-feedback-right {
    padding-right: 45px;
}

.form-group {
    margin-bottom: 10px;
}

.validate {
    margin-top: 10px;
}

.invalid-form-error-message {
    margin-top: 10px;
    padding: 5px;
}

.invalid-form-error-message.filled {
    border-left: 2px solid @cor_vermelho;
}

p.parsley-success {
    color: @color_37;
    background-color: #DFF0D8;
    border: 1px solid #D6E9C6;
}

p.parsley-error {
    color: @color_38;
    background-color: #F2DEDE;
    border: 1px solid #EED3D7;
}

ul.parsley-errors-list {
    list-style: none;
    color: @cor_vermelho;
    padding-left: 0;
}

input.parsley-error {
    background: #FAEDEC;
    border: 1px solid #E85445;
}

textarea.parsley-error {
    background: #FAEDEC;
    border: 1px solid #E85445;
}

select.parsley-error {
    background: #FAEDEC;
    border: 1px solid #E85445;
}

.bad {
    input {
        border: 1px solid #CE5454;
        box-shadow: 0 0 4px -2px #CE5454;
        position: relative;
        left: 0;
        -moz-animation: .7s 1 shake linear;
        -webkit-animation: 0.7s 1 shake linear;
    }
    select {
        border: 1px solid #CE5454;
        box-shadow: 0 0 4px -2px #CE5454;
        position: relative;
        left: 0;
        -moz-animation: .7s 1 shake linear;
        -webkit-animation: 0.7s 1 shake linear;
    }
    textarea {
        border: 1px solid #CE5454;
        box-shadow: 0 0 4px -2px #CE5454;
        position: relative;
        left: 0;
        -moz-animation: .7s 1 shake linear;
        -webkit-animation: 0.7s 1 shake linear;
    }
}

.item {
    input {
        transition: 0.42s;
    }
    textarea {
        transition: 0.42s;
    }
    .alert {
        float: left;
        margin: 0 0 0 20px;
        padding: 3px 10px;
        color: @cor_branco;
        border-radius: 3px 4px 4px 3px;
        background-color: #CE5454;
        max-width: 170px;
        white-space: pre;
        position: relative;
        left: -15px;
        opacity: 0;
        z-index: 1;
        transition: 0.15s ease-out;
        &::after {
            content: '';
            display: block;
            height: 0;
            width: 0;
            border-color: transparent #CE5454 transparent transparent;
            border-style: solid;
            border-width: 11px 7px;
            position: absolute;
            left: -13px;
            top: 1px;
        }
    }
}

.item.bad {
    .alert {
        left: 0;
        opacity: 1;
    }
}

.inl-bl {
    display: inline-block;
}

.well {
    .markup {
        background: @cor_branco;
        color: @color_40;
        position: relative;
        padding: 45px 15px 15px;
        margin: 15px 0 0 0;
        background-color: @cor_branco;
        border-radius: 0 0 4px 4px;
        box-shadow: none;
        &::after {
            content: "Example";
            position: absolute;
            top: 15px;
            left: 15px;
            font-size: 12px;
            font-weight: bold;
            color: @color_36;
            text-transform: uppercase;
            letter-spacing: 1px;
        }
    }
}

.autocomplete-suggestions {
    border: 1px solid #e4e4e4;
    background: #F4F4F4;
    cursor: default;
    overflow: auto;
    strong {
        font-weight: normal;
        color: @color_41;
        font-weight: bolder;
    }
}

.autocomplete-suggestion {
    padding: 2px 5px;
    font-size: 1.2em;
    white-space: nowrap;
    overflow: hidden;
}

.autocomplete-selected {
    background: #f0f0f0;
}

a.btn-success {
    color: @color_2;
}

a.btn-primary {
    color: @color_2;
}

a.btn-warning {
    color: @color_2;
}

a.btn-danger {
    color: @color_2;
}

.btn-success {
    background: @cor_fundo_botao_sucesso;
    border: 1px solid #169F85;
    &:hover {
        background: #169F85;
    }
    &:focus {
        background: #169F85;
    }
    &:active {
        background: #169F85;
    }
}

.btn-success.active {
    background: #169F85;
}

.open {
    .dropdown-toggle.btn-success {
        background: #169F85;
    }
    .dropdown-toggle.btn-dark {
        color: @cor_branco;
        background-color: #394D5F;
        border-color: #394D5F;
    }
}

.btn-dark {
    color: @color_42;
    background-color: #4B5F71;
    border-color: #364B5F;
    &:hover {
        color: @cor_branco;
        background-color: #394D5F;
        border-color: #394D5F;
    }
    &:focus {
        color: @cor_branco;
        background-color: #394D5F;
        border-color: #394D5F;
    }
    &:active {
        color: @cor_branco;
        background-color: #394D5F;
        border-color: #394D5F;
    }
}

.btn-dark.active {
    color: @cor_branco;
    background-color: #394D5F;
    border-color: #394D5F;
}

.btn-round {
    border-radius: 30px;
}

.btn.btn-app {
    position: relative;
    padding: 15px 5px;
    margin: 0 0 10px 10px;
    min-width: 80px;
    height: 60px;
    box-shadow: none;
    border-radius: 0;
    text-align: center;
    color: @color_43;
    border: 1px solid #ddd;
    //background-color: #fafafa;
    font-size: 12px;
    > .fa {
        font-size: 20px;
        display: block;
    }
    > .glyphicon {
        font-size: 20px;
        display: block;
    }
    > .ion {
        font-size: 20px;
        display: block;
    }
    &:hover {
        background: #f4f4f4;
        color: @color_24;
        border-color: #aaa;
    }
    &:active {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    &:focus {
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    }
    > .badge {
        position: absolute;
        top: -3px;
        right: -10px;
        font-size: 10px;
        font-weight: 400;
    }
}

textarea {
    padding: 10px;
    vertical-align: top;
    width: 200px;
    &:focus {
        outline-style: solid;
        outline-width: 2px;
    }
}

.btn_ {
    display: inline-block;
    padding: 3px 9px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: @color_44;
    text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, @cor_branco, #e6e6e6);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
    border-color: #e6e6e6 #e6e6e6 #bfbfbf;
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    border: 1px solid #cccccc;
    border-bottom-color: #b3b3b3;
    border-radius: 4px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.bs-glyphicons {
    margin: 0 -10px 20px;
    overflow: hidden;
    li {
        float: left;
        width: 25%;
        height: 115px;
        padding: 10px;
        font-size: 10px;
        line-height: 1.4;
        text-align: center;
        background-color: #f9f9f9;
        border: 1px solid @cor_branco;
        &:hover {
            color: @color_2;
            background-color: @cor_verde;
        }
    }
    .glyphicon {
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 24px;
    }
    .glyphicon-class {
        display: block;
        text-align: center;
        word-wrap: break-word;
    }
}

.bs-glyphicons-list {
    padding-left: 0;
    list-style: none;
}

.tagsinput {
    border: 1px solid #CCC;
    background: @cor_branco;
    padding: 6px 6px 0;
    width: 300px;
    overflow-y: auto;
    span.tag {
        a {
            font-weight: bold;
            color: @color_46;
            text-decoration: none;
            font-size: 11px;
        }
    }
    input {
        width: 80px;
        margin: 0px;
        font-family: @font-family_3;
        font-size: 13px;
        border: 1px solid transparent;
        padding: 3px;
        background: transparent;
        color: @color_47;
        outline: 0px;
    }
    div {
        display: block;
        float: left;
    }
}

span.tag {
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    display: block;
    float: left;
    padding: 5px 9px;
    text-decoration: none;
    background: @cor_verde;
    color: @color_45;
    margin-right: 5px;
    font-weight: 500;
    margin-bottom: 5px;
    font-family: @font-family_3;
    a {
        color: @color_45 !important;
    }
}

.tags_clear {
    clear: both;
    width: 100%;
    height: 0px;
}

.not_valid {
    background: #FBD8DB !important;
    color: @color_48 !important;
}

ul.bar_tabs {
    overflow: visible;
    background: @cor_fundo_painel_ferramentas;
    height: 25px;
    margin: 21px 0 14px;
    padding-left: 14px;
    position: relative;
    z-index: 1;
    width: 100%;
    border-bottom: 1px solid #E6E9ED;
    > li {
        border: 1px solid #E6E9ED;
        color: @color_34 !important;
        margin-top: -17px;
        margin-left: 8px;
        background: @cor_branco;
        border-bottom: none;
        border-radius: 4px 4px 0 0;
        a {
            padding: 10px 17px;
            background: @cor_fundo_painel_ferramentas;
            margin: 0;
            border-top-right-radius: 0;
            &:hover {
                border: 1px solid transparent;
            }
        }
    }
    > li.active {
        border-right: 6px solid @cor_borda_menus;
        border-top: 0;
        margin-top: -15px;
        a {
            border-bottom: none;
        }
    }
}

ul.bar_tabs.right {
    padding-right: 14px;
    li {
        float: right;
    }
}

ul.timeline {
    li {
        position: relative;
        border-bottom: 1px solid #e8e8e8;
        clear: both;
    }
}

.timeline {
    .block {
        margin: 0;
        border-left: 3px solid #e8e8e8;
        overflow: visible;
        padding: 10px 15px;
        margin-left: 105px;
    }
    .tags {
        position: absolute;
        top: 15px;
        left: 0;
        width: 84px;
    }
    .tag {
        display: block;
        height: 30px;
        font-size: 13px;
        padding: 8px;
        span {
            display: block;
            overflow: hidden;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    h2.title {
        position: relative;
        font-size: 16px;
        margin: 0;
        &:before {
            content: "";
            position: absolute;
            left: -23px;
            top: 3px;
            display: block;
            width: 14px;
            height: 14px;
            border: 3px solid #d2d3d2;
            border-radius: 14px;
            background: #f9f9f9;
        }
    }
    .byline {
        padding: .25em 0;
    }
}

.timeline.widget {
    min-width: 0;
    max-width: inherit;
    .block {
        margin-left: 5px;
    }
}

.tag {
    line-height: 1;
    background: @cor_verde;
    color: @color_2 !important;
    &:after {
        content: " ";
        height: 30px;
        width: 0;
        position: absolute;
        left: 100%;
        top: 0;
        margin: 0;
        pointer-events: none;
        border-top: 14px solid transparent;
        border-bottom: 14px solid transparent;
        border-left: 11px solid @cor_verde;
    }
}

.byline {
    -webkit-font-smoothing: antialiased;
    font-style: italic;
    font-size: .9375em;
    line-height: 1.3;
    color: @color_49;
}

ul.social {
    li {
        border: 0;
    }
}

.social-sidebar {
    float: right;
    background: #EDEDED;
    width: 22%;
}

.social-body {
    float: right;
    border: 1px solid #ccc;
    width: 78%;
}

.thumb {
    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
}

.chat {
    .thumb {
        img {
            width: 27px;
            height: 27px;
            border-radius: 50%;
        }
    }
    .status {
        float: left;
        margin: 16px 0 0 -16px;
        font-size: 14px;
        font-weight: bold;
        width: 12px;
        height: 12px;
        display: block;
        border: 2px solid @cor_branco;
        z-index: 12312;
        border-radius: 50%;
    }
    .status.online {
        background: @cor_verde;
    }
    .status.away {
        background: @cor_laranja;
    }
    .status.offline {
        background: #ccc;
    }
    .media-body {
        padding-top: 5px;
    }
}

.chart {
    position: relative;
    display: inline-block;
    width: 110px;
    height: 110px;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    canvas {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.percent {
    display: inline-block;
    line-height: 110px;
    z-index: 2;
    font-size: 18px;
    &:after {
        content: '%';
        margin-left: 0.1em;
        font-size: .8em;
    }
}

.angular {
    margin-top: 100px;
    .chart {
        margin-top: 0;
    }
}

.widget {
    min-width: 250px;
    max-width: 310px;
    .verticle_bars {
        li {
            .progress.vertical.progress_wide {
                width: 65%;
            }
        }
    }
}

.widget_tally_box {
    .btn-group {
        button {
            text-align: center;
            color: @color_35;
            font-weight: 500;
            background-color: #f5f5f5;
            border: 1px solid #e7e7e7;
        }
    }
    .flex {
        display: -ms-flexbox;
        display: flex;
    }
    p {
        text-align: center;
    }
    span {
        text-align: center;
    }
    .name {
        text-align: center;
        margin: 25px;
    }
    .name_title {
        text-align: center;
        margin: 5px;
    }
    ul.legend {
        margin: 0;
        p {
            text-align: left;
            display: inline-block;
            margin: 0;
        }
        span {
            text-align: left;
        }
        li {
            .icon {
                font-size: 20px;
                float: left;
                width: 14px;
            }
            .name {
                font-size: 14px;
                margin: 5px 0 0 14px;
                text-overflow: ellipsis;
                float: left;
            }
        }
    }
    ul.verticle_bars {
        li {
            height: 140px;
            width: 23%;
        }
    }
}

ul.widget_tally {
    width: 100%;
    li {
        width: 100%;
        padding: 2px 10px;
        border-bottom: 1px solid #ECECEC;
        padding-bottom: 4px;
    }
    .month {
        width: 70%;
        float: left;
    }
    .count {
        width: 30%;
        float: left;
        text-align: right;
    }
}

.pie_bg {
    border-bottom: 1px solid rgba(101, 204, 182, 0.16);
    padding-bottom: 15px;
    border-radius: 4px;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffe6e6e6', GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    padding-bottom: 10px;
    box-shadow: 0 4px 6px -6px #222;
}

ul.widget_profile_box {
    width: 100%;
    height: 42px;
    padding: 3px;
    background: #ececec;
    margin-top: 40px;
    margin-left: 1px;
    li {
        &:first-child {
            width: 25%;
            float: left;
            a {
                float: left;
            }
        }
        &:last-child {
            width: 25%;
            float: right;
            a {
                float: right;
            }
        }
        a {
            font-size: 22px;
            text-align: center;
            width: 35px;
            height: 35px;
            border: 1px solid rgba(52, 73, 94, 0.44);
            display: block;
            border-radius: 50%;
            padding: 0px;
            &:hover {
                color: @cor_verde !important;
                border: 1px solid #26b99a;
            }
        }
        .profile_img {
            width: 85px;
            height: 85px;
            margin: 0;
            margin-top: -28px;
        }
    }
}

ul.count2 {
    width: 100%;
    margin-left: 1px;
    border: 1px solid #ddd;
    border-left: 0;
    border-right: 0;
    padding: 10px 0;
    li {
        width: 30%;
        text-align: center;
        h3 {
            font-weight: 400;
            margin: 0;
        }
        span {
            font-weight: 300;
        }
    }
}

.divider {
    border-bottom: 1px solid #ddd;
    margin: 10px;
}

.divider-dashed {
    border-top: 1px dashed #e7eaec;
    background-color: @cor_branco;
    height: 1px;
    margin: 10px 0;
}

ul.messages {
    padding: 0;
    list-style: none;
    li {
        border-bottom: 1px dotted #e6e6e6;
        padding: 8px 0;
        img.avatar {
            height: 32px;
            width: 32px;
            float: left;
            display: inline-block;
            border-radius: 2px;
            padding: 2px;
            background: #f7f7f7;
            border: 1px solid #e6e6e6;
        }
        .message_date {
            float: right;
            text-align: right;
        }
        .message_wrapper {
            margin-left: 50px;
            margin-right: 40px;
            h4.heading {
                font-weight: 600;
                margin: 0;
                cursor: pointer;
                margin-bottom: 10px;
                line-height: 100%;
            }
            blockquote {
                padding: 0px 10px;
                margin: 0;
                border-left: 5px solid #eee;
            }
        }
    }
}

.tasks {
    li {
        border-bottom: 1px dotted #e6e6e6;
        padding: 8px 0;
    }
}

img.avatar {
    height: 32px;
    width: 32px;
    float: left;
    display: inline-block;
    border-radius: 2px;
    padding: 2px;
    background: #f7f7f7;
    border: 1px solid #e6e6e6;
}

ul.user_data {
    li {
        margin-bottom: 6px;
        p {
            margin-bottom: 0;
        }
        .progress {
            width: 90%;
        }
    }
}

.project_progress {
    .progress {
        margin-bottom: 3px !important;
        margin-top: 5px;
    }
}

.projects {
    .list-inline {
        margin: 0;
    }
}

.profile_title {
    background: @cor_fundo_painel_ferramentas;
    border: 0;
    padding: 7px 0;
    display: -ms-flexbox;
    display: flex;
}

ul.stats-overview {
    border-bottom: 1px solid #e8e8e8;
    padding-bottom: 10px;
    margin-bottom: 10px;
    li {
        display: inline-block;
        text-align: center;
        padding: 0 15px;
        width: 30%;
        font-size: 14px;
        border-right: 1px solid #e8e8e8;
        &:last-child {
            border-right: 0;
        }
        .name {
            font-size: 12px;
        }
        .value {
            font-size: 14px;
            font-weight: bold;
            display: block;
        }
        &:first-child {
            padding-left: 0;
        }
    }
}

ul.project_files {
    li {
        margin-bottom: 5px;
        a {
            i {
                width: 20px;
            }
        }
    }
}

.project_detail {
    p {
        margin-bottom: 10px;
    }
    p.title {
        font-weight: bold;
        margin-bottom: 0;
    }
}

.avatar {
    img {
        border-radius: 50%;
        max-width: 45px;
    }
}

.pricing {
    background: @cor_branco;
    .title {
        background: @cor_verde;
        height: 110px;
        color: @color_2;
        padding: 15px 0 0;
        text-align: center;
        h2 {
            text-transform: capitalize;
            font-size: 18px;
            border-radius: 5px 5px 0 0;
            margin: 0;
            font-weight: 400;
        }
        h1 {
            font-size: 30px;
            margin: 12px;
        }
        span {
            background: rgba(51, 51, 51, 0.28);
            padding: 2px 5px;
        }
    }
}

.pricing_features {
    background: #FAFAFA;
    padding: 20px 15px;
    min-height: 230px;
    font-size: 13.5px;
    ul {
        li {
            margin-top: 10px;
        }
    }
}

.pricing_footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    text-align: center;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    p {
        font-size: 13px;
        padding: 10px 0 2px;
        display: block;
    }
}

.ui-ribbon-container {
    position: relative;
    .ui-ribbon-wrapper {
        position: absolute;
        overflow: hidden;
        width: 85px;
        height: 88px;
        top: -3px;
        right: -3px;
    }
    .ui-ribbon {
        position: relative;
        display: block;
        text-align: center;
        font-size: 15px;
        font-weight: 700;
        color: @color_2;
        transform: rotate(45deg);
        padding: 7px 0;
        left: -5px;
        top: 15px;
        width: 120px;
        line-height: 20px;
        background-color: #555;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
        &:before {
            left: 0;
            bottom: -1px;
            right: 0;
            position: absolute;
            content: " ";
            line-height: 0;
            border-top: 2px solid #555;
            border-left: 2px solid transparent;
            border-right: 2px solid transparent;
        }
        &:after {
            position: absolute;
            content: " ";
            line-height: 0;
            border-top: 2px solid #555;
            border-left: 2px solid transparent;
            border-right: 2px solid transparent;
        }
    }
}

.ui-ribbon-container.ui-ribbon-primary {
    .ui-ribbon {
        background-color: #5b90bf;
        &:after {
            border-top: 2px solid #5b90bf;
        }
        &:before {
            border-top: 2px solid #5b90bf;
        }
    }
}

.thumbnail {
    .image {
        height: 120px;
        overflow: hidden;
    }
    height: 190px;
    overflow: hidden;
}

.caption {
    padding: 9px 5px;
    background: #F7F7F7;
    p {
        margin-bottom: 5px;
    }
}

.view {
    overflow: hidden;
    position: relative;
    text-align: center;
    box-shadow: 1px 1px 2px #e6e6e6;
    cursor: default;
    .mask {
        position: absolute;
        width: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
    }
    .content {
        position: absolute;
        width: 100%;
        overflow: hidden;
        top: 0;
        left: 0;
    }
    img {
        display: block;
        position: relative;
    }
    .tools {
        text-transform: uppercase;
        color: @color_2;
        text-align: center;
        position: relative;
        font-size: 17px;
        padding: 3px;
        background: rgba(0, 0, 0, 0.35);
        margin: 43px 0 0 0;
        a {
            display: inline-block;
            color: @cor_branco;
            font-size: 18px;
            font-weight: 400;
            padding: 0 4px;
        }
    }
    p {
        font-family: @font-family_4;
        font-style: italic;
        font-size: 12px;
        position: relative;
        color: @color_2;
        padding: 10px 20px 20px;
        text-align: center;
    }
    a.info {
        display: inline-block;
        text-decoration: none;
        padding: 7px 14px;
        background: #000;
        color: @color_2;
        text-transform: uppercase;
        box-shadow: 0 0 1px #000;
    }
}

.mask.no-caption {
    .tools {
        margin: 90px 0 0 0;
    }
}

.view-first {
    img {
        transition: all 0.2s linear;
    }
    .mask {
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.5);
        transition: all 0.4s ease-in-out;
    }
    .tools {
        transform: translateY(-100px);
        opacity: 0;
        transition: all 0.2s ease-in-out;
    }
    p {
        transform: translateY(100px);
        opacity: 0;
        transition: all 0.2s linear;
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
        .mask {
            opacity: 1;
        }
        .tools {
            opacity: 1;
            transform: translateY(0px);
        }
        p {
            opacity: 1;
            transform: translateY(0px);
            transition-delay: 0.1s;
        }
    }
}

.tabs-left {
    border-bottom: none;
    padding-top: 2px;
    border-right: 1px solid #F7F7F7;
    > li {
        float: none;
        margin-bottom: 2px;
        margin-right: -1px;
        > a {
            border-radius: 4px 0 0 4px;
            margin-right: 0;
            display: block;
            background: #F7F7F7;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    > li.active {
        > a {
            border-bottom-color: #F7F7F7;
            border-right-color: transparent;
            &:hover {
                border-bottom-color: #F7F7F7;
                border-right-color: transparent;
            }
            &:focus {
                border-bottom-color: #F7F7F7;
                border-right-color: transparent;
            }
        }
    }
}

.tabs-right {
    border-bottom: none;
    padding-top: 2px;
    border-left: 1px solid #F7F7F7;
    > li {
        float: none;
        margin-bottom: 2px;
        margin-left: -1px;
        > a {
            border-radius: 0 4px 4px 0;
            margin-right: 0;
            background: #F7F7F7;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
    > li.active {
        > a {
            border-bottom: 1px solid #F7F7F7;
            border-left-color: transparent;
            &:hover {
                border-bottom: 1px solid #F7F7F7;
                border-left-color: transparent;
            }
            &:focus {
                border-bottom: 1px solid #F7F7F7;
                border-left-color: transparent;
            }
        }
    }
}

.sideways {
    margin-top: 50px;
    border: none;
    position: relative;
    > li {
        height: 20px;
        width: 120px;
        margin-bottom: 100px;
        > a {
            border-bottom: 1px solid #ddd;
            border-right-color: transparent;
            text-align: center;
            border-radius: 4px 4px 0px 0px;
        }
    }
    > li.active {
        > a {
            border-bottom-color: transparent;
            border-right-color: #ddd;
            border-left-color: #ddd;
            &:hover {
                border-bottom-color: transparent;
                border-right-color: #ddd;
                border-left-color: #ddd;
            }
            &:focus {
                border-bottom-color: transparent;
                border-right-color: #ddd;
                border-left-color: #ddd;
            }
        }
    }
}

.sideways.tabs-left {
    left: -50px;
    > li {
        transform: rotate(-90deg);
    }
}

.sideways.tabs-right {
    right: -50px;
    > li {
        transform: rotate(90deg);
    }
}

.morris-hover {
    position: absolute;
    z-index: 1000;
}

.morris-hover.morris-default-style {
    padding: 6px;
    color: @color_43;
    background: rgba(243, 242, 243, 0.8);
    border: solid 2px rgba(195, 194, 196, 0.8);
    font-family: @font-family_5;
    font-size: 12px;
    text-align: center;
    .morris-hover-row-label {
        font-weight: bold;
        margin: 0.25em 0;
    }
    .morris-hover-point {
        white-space: nowrap;
        margin: 0.1em 0;
    }
}

.price {
    font-size: 40px;
    font-weight: 400;
    color: @cor_fundo_botao_sucesso;
    margin: 0;
}

.prod_title {
    border-bottom: 1px solid #DFDFDF;
    padding-bottom: 5px;
    margin: 30px 0;
    font-size: 20px;
    font-weight: 400;
}

.product-image {
    img {
        width: 90%;
    }
}

.prod_color {
    li {
        margin: 0 10px;
        p {
            margin-bottom: 0;
        }
    }
    .color {
        width: 25px;
        height: 25px;
        border: 2px solid rgba(51, 51, 51, 0.28) !important;
        padding: 2px;
        border-radius: 50px;
    }
}

.prod_size {
    li {
        padding: 0;
    }
}

.product_gallery {
    a {
        width: 100px;
        height: 100px;
        float: left;
        margin: 10px;
        border: 1px solid #e5e5e5;
        img {
            width: 100%;
            margin-top: 15px;
        }
    }
}

.product_price {
    margin: 20px 0;
    padding: 5px 10px;
    background-color: @cor_branco;
    text-align: left;
    border: 2px dashed #E0E0E0;
}

.price-tax {
    font-size: 18px;
}

.product_social {
    margin: 20px 0;
    ul {
        li {
            a {
                i {
                    font-size: 35px;
                }
            }
        }
    }
}

.login {
    background: #F7F7F7;
    .fa-paw {
        font-size: 26px;
    }
}

a.hiddenanchor {
    display: none;
}

.login_wrapper {
    right: 0px;
    margin: 0px auto;
    margin-top: 5%;
    max-width: 350px;
    position: relative;
}

.registration_form {
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 21;
    opacity: 0;
    width: 100%;
}

.login_form {
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 22;
}

#signup {
    &:target {
        & ~ .login_wrapper {
            .registration_form {
                z-index: 22;
                animation-name: fadeInLeft;
                animation-delay: .1s;
            }
            .login_form {
                animation-name: fadeOutLeft;
            }
        }
    }
}

#signin {
    &:target {
        & ~ .login_wrapper {
            .login_form {
                z-index: 22;
                animation-name: fadeInLeft;
                animation-delay: .1s;
            }
            .registration_form {
                animation-name: fadeOutLeft;
            }
        }
    }
}

.animate {
    -webkit-animation-duration: 0.5s;
    -webkit-animation-timing-function: ease;
    -webkit-animation-fill-mode: both;
    -moz-animation-duration: 0.5s;
    -moz-animation-timing-function: ease;
    -moz-animation-fill-mode: both;
    -o-animation-duration: 0.5s;
    -o-animation-timing-function: ease;
    -o-animation-fill-mode: both;
    -ms-animation-duration: 0.5s;
    -ms-animation-timing-function: ease;
    -ms-animation-fill-mode: both;
    animation-duration: 0.5s;
    animation-timing-function: ease;
    animation-fill-mode: both;
}

.login_box {
    padding: 20px;
    margin: auto;
}

.left {
    float: left;
}

.alignleft {
    float: left;
    margin-right: 15px;
}

.alignright {
    float: right;
    margin-left: 15px;
}

.clearfix {
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

form {
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

.login_content {
    margin: 0 auto;
    padding: 25px 0 0;
    position: relative;
    text-align: center;
    text-shadow: 0 1px 0 @cor_branco;
    min-width: 280px;
    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .btn-default {
        &:hover {
            text-decoration: none;
        }
    }
    h1 {
        font: normal 25px Helvetica, Arial, sans-serif;
        letter-spacing: -0.05em;
        line-height: 20px;
        margin: 10px 0 30px;
        &:before {
            content: "";
            height: 1px;
            position: absolute;
            top: 10px;
            width: 27%;
            background: #7e7e7e;
            background: linear-gradient(right, #7e7e7e 0%, white 100%);
            left: 0;
            content: "";
            height: 1px;
            position: absolute;
            top: 10px;
            width: 20%;
            background: #7e7e7e;
            background: linear-gradient(right, #7e7e7e 0%, white 100%);
            left: 0;
        }
        &:after {
            content: "";
            height: 1px;
            position: absolute;
            top: 10px;
            width: 27%;
            background: #7e7e7e;
            background: linear-gradient(left, #7e7e7e 0%, white 100%);
            right: 0;
            content: "";
            height: 1px;
            position: absolute;
            top: 10px;
            width: 20%;
            background: #7e7e7e;
            background: linear-gradient(left, #7e7e7e 0%, white 100%);
            right: 0;
        }
    }
    form {
        margin: 20px 0;
        position: relative;
        input[type="text"] {
            border-radius: 0px;
            -ms-box-shadow: 0 1px 0 @cor_branco, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
            -o-box-shadow: 0 1px 0 @cor_branco, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
            box-shadow: 0 1px 0 @cor_branco, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
            border: 1px solid #c8c8c8;
            color: @color_40;
            margin: 0 0 0px;
            width: 100%;
            &:focus {
                -ms-box-shadow: 0 0 2px #ed1c24 inset;
                -o-box-shadow: 0 0 2px #ed1c24 inset;
                box-shadow: 0 0 2px #05473D inset;
                background-color: @cor_branco;
                border: 1px solid #05473D;
                outline: none;
            }
        }
        input[type="email"] {
            border-radius: 0px;
            -ms-box-shadow: 0 1px 0 @cor_branco, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
            -o-box-shadow: 0 1px 0 @cor_branco, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
            box-shadow: 0 1px 0 @cor_branco, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
            border: 1px solid #c8c8c8;
            color: @color_40;
            margin: 0 0 0px;
            width: 100%;
            &:focus {
                -ms-box-shadow: 0 0 2px #ed1c24 inset;
                -o-box-shadow: 0 0 2px #ed1c24 inset;
                box-shadow: 0 0 2px #05473D inset;
                background-color: @cor_branco;
                border: 1px solid #05473D;
                outline: none;
            }
        }
        input[type="password"] {
            border-radius: 0px;
            -ms-box-shadow: 0 1px 0 @cor_branco, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
            -o-box-shadow: 0 1px 0 @cor_branco, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
            box-shadow: 0 1px 0 @cor_branco, 0 -2px 5px rgba(0, 0, 0, 0.08) inset;
            border: 1px solid #c8c8c8;
            color: @color_40;
            margin: 0 0 0px;
            width: 100%;
            &:focus {
                -ms-box-shadow: 0 0 2px #ed1c24 inset;
                -o-box-shadow: 0 0 2px #ed1c24 inset;
                box-shadow: 0 0 2px #05473D inset;
                background-color: @cor_branco;
                border: 1px solid #05473D;
                outline: none;
            }
        }
        div {
            a {
                font-size: 12px;
                margin: 10px 15px 0 0;
            }
        }
        input[type="submit"] {
            float: left;
            margin-left: 38px;
        }
    }
    div {
        .reset_pass {
            margin-top: 13px !important;
            margin-right: 39px;
            float: right;
        }
    }
}

#username {
    background-position: 10px 10px !important;
}

#password {
    background-position: 10px -53px !important;
}

.reset_pass {
    margin-top: 10px !important;
}

.separator {
    border-top: 1px solid #D8D8D8;
    margin-top: 10px;
    padding-top: 10px;
}

/*.button {
    background: #f7f9fa;
    background: linear-gradient(top, #f7f9fa 0%, #f0f0f0 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f9fa', endColorstr='#f0f0f0', GradientType=0);
    -ms-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    -o-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid #CFD5D9;
    padding: 15px 0;
    a {
        background: url(http://cssdeck.com/uploads/media/items/8/8bcLQqF.png) 0 -112px no-repeat;
        color: @color_51;
        font-size: 17px;
        padding: 2px 0 2px 40px;
        text-decoration: none;
        transition: all 0.3s ease;
        &:hover {
            background-position: 0 -135px;
            color: @color_52;
        }
    }
}*/
#content {
    form {
        .submit {
            float: left;
            margin-left: 38px;
        }
    }
}

header {
    width: 100%;
}

#nprogress {
    .bar {
        background: @cor_borda_menus;
    }
    .peg {
        box-shadow: 0 0 10px @cor_borda_menus, 0 0 5px @cor_borda_menus;
    }
    .spinner-icon {
        border-top-color: @cor_borda_menus;
        border-left-color: @cor_borda_menus;
    }
}

.editor-wrapper {
    min-height: 250px;
    background-color: white;
    border-collapse: separate;
    border: 1px solid #cccccc;
    padding: 4px;
    box-sizing: content-box;
    box-shadow: rgba(0, 0, 0, 0.07451) 0px 1px 1px 0px inset;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    overflow: scroll;
    outline: none;
}

.voiceBtn {
    width: 20px;
    color: @color_53;
    background-color: transparent;
    transform: scale(2, 2);
    -webkit-transform: scale(2, 2);
    -moz-transform: scale(2, 2);
    border: transparent;
    cursor: pointer;
    box-shadow: none;
    -webkit-box-shadow: none;
}

div[data-role="editor-toolbar"] {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.select2-container--default {
    .select2-selection--single {
        background-color: @cor_branco;
        border: 1px solid #ccc;
        border-radius: 0;
        min-height: 34px;
        .select2-selection__rendered {
            color: @cor_texto_geral;
            padding-top: 2px;
        }
        .select2-selection__arrow {
            height: 36px;
        }
    }
    .select2-selection--multiple {
        background-color: @cor_branco;
        border: 1px solid #ccc;
        border-radius: 0;
        min-height: 34px;
        .select2-selection__rendered {
            padding-top: 3px;
        }
        .select2-selection__choice {
            margin-top: 0px;
            border: none;
            border-radius: 0;
            padding: 8px;
        }
        .select2-selection__clear {
            margin-top: 8px;
            border: none;
            border-radius: 0;
            padding: 3px 5px;
            font-size: 1.2em;
        }
    }
}

.select2-container--default.select2-container--focus {
    .select2-selection--multiple {
        border: 1px solid #ccc;
    }
}

.switchery {
    width: 32px;
    height: 20px;
    > small {
        width: 20px;
        height: 20px;
    }
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

.cropper {
    .img-container {
        background-color: #f7f7f7;
        width: 100%;
        text-align: center;
        min-height: 200px;
        max-height: 516px;
        margin-bottom: 20px;
        > img {
            max-width: 100%;
        }
    }
    .img-preview {
        background-color: #f7f7f7;
        width: 100%;
        text-align: center;
        float: left;
        margin-right: 10px;
        margin-bottom: 10px;
        overflow: hidden;
        > img {
            max-width: 100%;
        }
    }
    .docs-preview {
        margin-right: -15px;
    }
    .preview-lg {
        width: 263px;
        height: 148px;
    }
    .preview-md {
        width: 139px;
        height: 78px;
    }
    .preview-sm {
        width: 69px;
        height: 39px;
    }
    .preview-xs {
        width: 35px;
        height: 20px;
        margin-right: 0;
    }
    .docs-data {
        > .input-group {
            margin-bottom: 10px;
            > label {
                min-width: 80px;
            }
            > span {
                min-width: 50px;
            }
        }
    }
    .docs-buttons {
        > .btn {
            margin-right: 5px;
            margin-bottom: 10px;
        }
        > .btn-group {
            margin-right: 5px;
            margin-bottom: 10px;
        }
        > .form-control {
            margin-right: 5px;
            margin-bottom: 10px;
        }
    }
    .docs-toggles {
        > .btn {
            margin-bottom: 10px;
        }
        > .btn-group {
            margin-bottom: 10px;
        }
        > .dropdown {
            margin-bottom: 10px;
        }
    }
    .docs-tooltip {
        display: block;
        margin: -6px -12px;
        padding: 6px 12px;
        > .icon {
            margin: 0 -3px;
            vertical-align: top;
        }
    }
    .tooltip-inner {
        white-space: normal;
    }
    .btn-upload {
        .tooltip-inner {
            white-space: nowrap;
        }
    }
    .btn-toggle {
        .tooltip-inner {
            white-space: nowrap;
        }
        padding: 6px;
        > .docs-tooltip {
            margin: -6px;
            padding: 6px;
        }
    }
    .docs-options {
        .dropdown-menu {
            width: 100%;
            > li {
                padding: 3px 20px;
                &:hover {
                    background-color: #f7f7f7;
                }
                > label {
                    display: block;
                }
            }
        }
    }
    .docs-cropped {
        .modal-body {
            text-align: center;
            > img {
                max-width: 100%;
            }
            > canvas {
                max-width: 100%;
            }
        }
        canvas {
            max-width: 100%;
        }
    }
    .docs-diagram {
        .modal-dialog {
            max-width: 352px;
        }
    }
}

.form_wizard {
    .stepContainer {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        border: 0 solid #CCC;
        overflow-x: hidden;
    }
    .loader {
        display: none;
    }
    .msgBox {
        display: none;
    }
}

.wizard_horizontal {
    ul.wizard_steps {
        display: table;
        list-style: none;
        position: relative;
        width: 100%;
        margin: 0 0 20px;
        li {
            display: table-cell;
            text-align: center;
            a {
                display: block;
                position: relative;
                -moz-opacity: 1;
                filter: alpha(opacity=100);
                opacity: 1;
                color: @color_43;
                &:before {
                    content: "";
                    position: absolute;
                    height: 4px;
                    background: #ccc;
                    top: 20px;
                    width: 100%;
                    z-index: 4;
                    left: 0;
                }
                .step_no {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 100px;
                    display: block;
                    margin: 0 auto 5px;
                    font-size: 16px;
                    text-align: center;
                    position: relative;
                    z-index: 5;
                }
            }
            &:hover {
                display: block;
                position: relative;
                -moz-opacity: 1;
                filter: alpha(opacity=100);
                opacity: 1;
                color: @color_43;
            }
            a.disabled {
                .step_no {
                    background: #ccc;
                }
            }
            a.selected {
                &:before {
                    background: @cor_escuro;
                    color: @color_2;
                }
            }
            a.done {
                &:before {
                    background: @cor_verde;
                    color: @color_2;
                }
                .step_no {
                    background: @cor_verde;
                    color: @color_2;
                }
            }
            &:first-child {
                a {
                    &:before {
                        left: 50%;
                    }
                }
            }
            &:last-child {
                a {
                    &:before {
                        right: 50%;
                        width: 50%;
                        left: auto;
                    }
                }
            }
        }
    }
}

.step_no {
    background: @cor_escuro;
    color: @color_2;
    background: @cor_escuro;
    color: @color_2;
}

.wizard_verticle {
    .stepContainer {
        width: 80%;
        float: left;
        padding: 0 10px;
    }
    .wizard_content {
        width: 80%;
        float: left;
        padding-left: 20px;
    }
    ul.wizard_steps {
        display: table;
        list-style: none;
        position: relative;
        width: 20%;
        float: left;
        margin: 0 0 20px;
        li {
            display: list-item;
            text-align: center;
            a {
                height: 80px;
                display: block;
                position: relative;
                -moz-opacity: 1;
                filter: alpha(opacity=100);
                opacity: 1;
                color: @color_43;
                &:first-child {
                    margin-top: 20px;
                }
                &:before {
                    content: "";
                    position: absolute;
                    height: 100%;
                    background: #ccc;
                    top: 20px;
                    width: 4px;
                    z-index: 4;
                    left: 49%;
                }
                .step_no {
                    width: 40px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 100px;
                    display: block;
                    margin: 0 auto 5px;
                    font-size: 16px;
                    text-align: center;
                    position: relative;
                    z-index: 5;
                }
            }
            &:hover {
                display: block;
                position: relative;
                -moz-opacity: 1;
                filter: alpha(opacity=100);
                opacity: 1;
                color: @color_43;
            }
            a.disabled {
                .step_no {
                    background: #ccc;
                }
            }
            a.selected {
                &:before {
                    background: @cor_escuro;
                    color: @color_2;
                }
            }
            a.done {
                &:before {
                    background: @cor_verde;
                    color: @color_2;
                }
                .step_no {
                    background: @cor_verde;
                    color: @color_2;
                }
            }
            &:first-child {
                a {
                    &:before {
                        left: 49%;
                    }
                }
            }
            &:last-child {
                a {
                    &:before {
                        left: 49%;
                        left: auto;
                        width: 0;
                    }
                }
            }
        }
    }
}

.actionBar {
    width: 100%;
    border-top: 1px solid #ddd;
    padding: 10px 5px;
    text-align: right;
    margin-top: 10px;
    .buttonDisabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: .65;
        filter: alpha(opacity=65);
        box-shadow: none;
    }
    a {
        margin: 0 3px;
    }
}

.progress {
    border-radius: 0;
}

.progress-bar-info {
    background-color: @cor_azul;
}

.progress-bar-success {
    background-color: @cor_fundo_botao_sucesso;
}

.progress_summary {
    .progress {
        margin: 5px 0 12px !important;
    }
    .row {
        margin-bottom: 5px;
        .col-xs-2 {
            padding: 0;
        }
    }
    .more_info {
        span {
            text-align: right;
            float: right;
        }
    }
    .data {
        span {
            text-align: right;
            float: right;
        }
    }
    p {
        margin-bottom: 3px;
        width: 100%;
    }
}

.progress_title {
    .left {
        float: left;
        text-align: left;
    }
    .right {
        float: right;
        text-align: right;
        font-weight: 300;
    }
}

.progress.progress_sm {
    border-radius: 0;
    margin-bottom: 18px;
    height: 10px !important;
    .progress-bar {
        height: 10px !important;
    }
}

ul.verticle_bars {
    width: 100%;
    li {
        width: 23%;
        height: 200px;
        margin: 0;
    }
}

.progress.vertical.progress_wide {
    width: 35px;
}

.alert {
    &.alert-danger {
        ul {
            li {
                padding: 3px 0px;
                margin-left: 15px;
            }
        }
    }
}

.alert-success {
    color: @cor_texto_alert;
    background-color: @cor_fundo_alert_sucesso;
    border-color: @cor_borda_alert_sucesso;
}

.alert-info {
    color: @cor_texto_alert;
    background-color: @cor_borda_alert_info;
    border-color: @cor_borda_alert_info;
}

.alert-warning {
    color: @cor_texto_alert;
    background-color: @cor_fundo_alert_aviso;
    border-color: @cor_borda_alert_aviso;
}

.alert-danger {
    color: @cor_texto_alert;
    background-color: @cor_fundo_alert_perigo;
    border-color: @cor_borda_alert_perigo;
}

.alert-error {
    color: @cor_texto_alert;
    background-color: @cor_fundo_alert_erro;
    border-color: @cor_borda_alert_erro;
}

.ui-pnotify.dark {
    .ui-pnotify-container {
        color: @cor_texto_alert;
        background-color: @cor_fundo_notify;
        border-color: @cor_borda_notify;
    }
}

.custom-notifications {
    position: fixed;
    margin: 15px;
    right: 0;
    float: right;
    width: 400px;
    z-index: 4000;
    bottom: 0;
}

ul.notifications {
    float: right;
    display: block;
    margin-bottom: 7px;
    padding: 0;
    width: 100%;
}

.notifications {
    li {
        float: right;
        margin: 3px;
        width: 36px;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
        &:last-child {
            margin-left: 0;
        }
    }
    a {
        display: block;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        padding: 9px 8px;
    }
}

.tabbed_notifications {
    .text {
        padding: 5px 15px;
        height: 140px;
        border-radius: 7px;
        box-shadow: 6px 6px 6px rgba(0, 0, 0, 0.3);
    }
    div {
        p {
            display: inline-block;
        }
    }
    h2 {
        font-weight: bold;
        text-transform: uppercase;
        width: 80%;
        float: left;
        height: 20px;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
    }
    .close {
        padding: 5px;
        color: @color_42;
        float: right;
        opacity: 1;
    }
}

.daterangepicker.dropdown-menu {
    font-size: 13px;
    padding: 0;
    overflow: hidden;
}

.daterangepicker.picker_1 {
    background: @cor_escuro;
    color: @cor_nome_empresa;
    table.table-condensed {
        thead {
            tr {
                &:first-child {
                    background: @cor_verde;
                }
                background: #213345;
            }
        }
    }
}

.daterangepicker {
    table.table-condensed {
        thead {
            tr {
                &:first-child {
                    th {
                        line-height: 28px;
                        text-align: center;
                    }
                }
                line-height: 14px;
            }
        }
        tbody {
            tr {
                &:first-child {
                    td {
                        padding-top: 10px;
                    }
                }
                &:last-child {
                    td {
                        padding-bottom: 10px;
                    }
                }
            }
        }
        th {
            &:first-child {
                padding-left: 12px;
            }
            &:last-child {
                padding-right: 12px;
            }
        }
        td {
            &:first-child {
                padding-left: 12px;
            }
            &:last-child {
                padding-right: 12px;
            }
        }
    }
    table {
        width: 100%;
        margin: 0;
    }
    td {
        text-align: center;
        width: 20px;
        height: 20px;
        cursor: pointer;
        white-space: nowrap;
    }
    th {
        text-align: center;
        width: 20px;
        height: 20px;
        cursor: pointer;
        white-space: nowrap;
    }
    td.off {
        color: @color_23;
    }
    td.disabled {
        color: @color_23;
    }
    td.available {
        &:hover {
            background: #eee;
            color: @cor_escuro;
        }
        & + td.start-date {
            border-radius: 4px 0 0 4px;
        }
    }
    th.available {
        &:hover {
            background: #eee;
            color: @cor_escuro;
        }
    }
    td.in-range {
        background: #E4E7EA;
        border-radius: 0;
        & + td.end-date {
            border-radius: 0 4px 4px 0;
        }
    }
    td.start-date.end-date {
        border-radius: 4px !important;
    }
    td.active {
        background-color: #536A7F;
        color: @color_2;
        &:hover {
            background-color: #536A7F;
            color: @color_2;
        }
    }
    td.week {
        font-size: 80%;
        color: @color_54;
    }
    th.week {
        font-size: 80%;
        color: @color_54;
    }
    select.monthselect {
        font-size: 12px;
        padding: 1px;
        height: auto;
        margin: 0;
        cursor: default;
        height: 30px;
        border: 1px solid #ADB2B5;
        line-height: 30px;
        border-radius: 0px !important;
        margin-right: 2%;
        width: 56%;
    }
    select.yearselect {
        font-size: 12px;
        padding: 1px;
        height: auto;
        margin: 0;
        cursor: default;
        height: 30px;
        border: 1px solid #ADB2B5;
        line-height: 30px;
        border-radius: 0px !important;
        width: 40%;
    }
    select.hourselect {
        width: 50px;
        margin-bottom: 0;
    }
    select.minuteselect {
        width: 50px;
        margin-bottom: 0;
    }
    select.ampmselect {
        width: 50px;
        margin-bottom: 0;
    }
    th.month {
        width: auto;
    }
    .daterangepicker_start_input {
        label {
            color: @color_34;
            display: block;
            font-size: 11px;
            font-weight: normal;
            height: 20px;
            line-height: 20px;
            margin-bottom: 2px;
            text-shadow: @cor_branco 1px 1px 0px;
            text-transform: uppercase;
            width: 74px;
        }
    }
    .daterangepicker_end_input {
        label {
            color: @color_34;
            display: block;
            font-size: 11px;
            font-weight: normal;
            height: 20px;
            line-height: 20px;
            margin-bottom: 2px;
            text-shadow: @cor_branco 1px 1px 0px;
            text-transform: uppercase;
            width: 74px;
        }
    }
    .ranges {
        input {
            font-size: 11px;
        }
        .input-mini {
            background-color: #eee;
            border: 1px solid #ccc;
            border-radius: 4px;
            color: @color_19;
            display: block;
            font-size: 11px;
            height: 30px;
            line-height: 30px;
            vertical-align: middle;
            margin: 0 0 10px 0;
            padding: 0 6px;
            width: 74px;
            &:hover {
                cursor: pointer;
            }
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        li {
            font-size: 13px;
            background: #f5f5f5;
            border: 1px solid #f5f5f5;
            color: @color_55;
            padding: 3px 12px;
            margin-bottom: 8px;
            border-radius: 5px;
            cursor: pointer;
            &:hover {
                background: #536A7F;
                border: 1px solid #536A7F;
                color: @color_2;
            }
        }
        li.active {
            background: #536A7F;
            border: 1px solid #536A7F;
            color: @color_2;
        }
        width: 160px;
        text-align: left;
        margin: 4px;
        .range_inputs {
            > div {
                float: left;
                &:nth-child(2) {
                    padding-left: 11px;
                }
            }
        }
    }
    .calendar {
        display: none;
        max-width: 270px;
    }
    .calendar.single {
        .calendar-date {
            border: none;
        }
    }
    .icon {
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
    }
}

.table-condensed {
    > thead {
        > tr {
            > th {
                padding: 5px 7px;
                text-align: center;
            }
            > td {
                padding: 5px 7px;
                text-align: center;
            }
        }
    }
    > tbody {
        > tr {
            > th {
                padding: 5px 7px;
                text-align: center;
            }
            > td {
                padding: 5px 7px;
                text-align: center;
            }
        }
    }
    > tfoot {
        > tr {
            > th {
                padding: 5px 7px;
                text-align: center;
            }
            > td {
                padding: 5px 7px;
                text-align: center;
            }
        }
    }
}

.daterangepicker.picker_2 {
    table.table-condensed {
        thead {
            tr {
                &:first-child {
                    color: @color_35;
                }
                color: @cor_verde;
            }
        }
    }
}

.daterangepicker.picker_3 {
    table.table-condensed {
        thead {
            tr {
                &:first-child {
                    background: @cor_verde;
                    color: @cor_nome_empresa;
                }
            }
        }
    }
}

.daterangepicker.picker_4 {
    table.table-condensed {
        tbody {
            td {
                background: #ECF0F1;
                color: @cor_escuro;
                border: 1px solid @cor_branco;
                padding: 4px 7px;
            }
            td.active {
                background: #536A7F;
                color: @color_2;
            }
        }
        thead {
            tr {
                &:first-child {
                    background: @cor_escuro;
                    color: @cor_nome_empresa;
                }
            }
        }
    }
}

.xdisplay_input {
    width: 240px;
    overflow: hidden;
    padding: 0;
}

.xdisplay {
    background-color: @cor_branco;
    background-clip: padding-box;
    border: 1px solid #ccc;
    margin-bottom: 20px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: 230px;
    overflow: hidden;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.daterangepicker.opensright {
    .ranges {
        float: right;
    }
    .calendar {
        float: right;
    }
}

.daterangepicker.openscenter {
    .ranges {
        float: right;
    }
    .calendar {
        float: right;
    }
}

.daterangepicker_start_input {
    float: left;
}

.daterangepicker_end_input {
    float: left;
    padding-left: 11px;
}

.daterangepicker.show-calendar {
    .calendar {
        display: block;
    }
}

.daterangepicker.single {
    .ranges {
        float: none;
    }
    .calendar {
        float: none;
    }
}

.daterangepicker.opensleft {
    .ranges {
        float: left;
        margin: 4px;
    }
    .calendar {
        float: left;
        margin: 4px;
    }
}

.fc-state-default {
    background: #f5f5f5;
    color: @cor_texto_geral;
}

.fc-state-down {
    color: @color_34;
    background: #ccc;
}

.fc-state-active {
    color: @color_34;
    background: #ccc;
}

.dropzone {
    min-height: 300px;
    border: 1px solid #e5e5e5;
}

@media (min-width: 480px) {
    .nav_menu {
        position: static;
    }

    .top_nav {
        .navbar-right {
            li {
                position: relative;
            }
        }
    }
}

@media (max-width: 991px) {
    .nav-md {
        .container.body {
            .right_col {
                width: 100%;
                margin: 0;
                width: 100%;
                padding-right: 0;
            }
            .top_nav {
                width: 100%;
                margin: 0;
            }
            .col-md-3.left_col {
                display: none;
            }
        }
    }

    .right_col {
        padding: 10px !important;
    }
}

@media (max-width: 1200px) {
    .x_title {
        h2 {
            width: 62%;
            font-size: 17px;
        }
    }

    .tile {
        zoom: 85%;
        height: inherit;
    }

    .graph {
        zoom: 85%;
        height: inherit;
    }
}

@media (max-width: 1270px) and (min-width: 192px) {
    .x_title {
        h2 {
            small {
                display: none;
            }
        }
    }
}

@media (min-width: 768px) {
    .col-md-55 {
        width: 20%;
    }

    .footer_fixed {
        footer {
            margin-left: 0;
        }
        .nav-sm {
            footer {
                margin-left: 0;
            }
        }
    }

    .tile_count {
        .tile_stats_count {
            .count {
                font-size: 40px;
            }
            span {
                font-size: 13px;
            }
        }
    }

    .bs-glyphicons {
        margin-right: 0;
        margin-left: 0;
        li {
            width: 12.5%;
            font-size: 12px;
        }
    }

    .cropper {
        .img-container {
            min-height: 516px;
        }
    }
}

@media (min-width: 992px) {
    .col-md-55 {
        width: 20%;
    }

    footer {
        margin-left: 230px;
    }

    .tile_count {
        .tile_stats_count {
            margin-bottom: 10px;
            border-bottom: 0;
            padding-bottom: 10px;
            &:first-child {
                &:before {
                    border-left: 0;
                }
            }
        }
    }
}

@media (min-width: 1200px) {
    .col-md-55 {
        width: 20%;
    }
}

@media (min-width: 192px) and (max-width: 1270px) {
    table.tile_info {
        span.right {
            margin-right: 7px;
            float: left;
        }
    }
}

@media (min-width: 992px) and (max-width: 1100px) {
    .tile_count {
        .tile_stats_count {
            .count {
                font-size: 30px;
            }
        }
    }
}

@media (max-width: 767px) {
    .mail_list {
        margin-bottom: 5px;
        display: inline-block;
    }
}

@media (max-width: 400px) {
    .cropper {
        .btn-group-crop {
            margin-right: -15px !important;
            > .btn {
                padding-left: 5px;
                padding-right: 5px;
            }
            .docs-tooltip {
                margin-left: -5px;
                margin-right: -5px;
                padding-left: 5px;
                padding-right: 5px;
            }
        }
    }
}

.nav_title {
    border: 0;

    & p {
        font-weight: bold;
        font-size: 0.7em;
        font-family: inherit;
        vertical-align: middle;
        height: 50px;
        line-height: 50px;

        & img {
            float: left;
            margin: 5px;
            width: 50px;
        }
    }
}

.select2-result-repository__title,
.select2-result-repository__description {
    padding-top: 3px;
    padding-bottom: 3px;
}

.select2-result-repository__title {
    font-weight: bold;
}

.resizable_textarea {
    max-width: 100%;
}

.tt-menu {
    width: auto;
    min-width: 100%;
    padding: 5px;
    background-color: #F7F7F7;

    .tt-dataset {
        .tt-suggestion {
            padding: 5px;

            &:hover, &:focus, &:active, &.tt-cursor {
                background-color: #73879C;
                color: @cor_branco;
                cursor: move;
            }
        }
    }
}

.twitter-typeahead {
    width: 100%;
}

.colunaAcoes {
    width: 60px;
}

.buttons-columnVisibility {
    text-decoration: line-through;
    font-weight: bold;
    &.active {
        text-decoration: none;
        font-weight: normal;
    }
}

.gritter-item-wrapper.dt-button-info {
    padding: 12px 11px 8px;
    z-index: 1999;
    position: fixed;
    top: 30%;
    left: 50%;
    background-color: @cor_fundo_menu;

    & > h2 {
        margin-top: 5px;
        margin-left: 5px;
    }
}

.daterangepicker .calendar {
    max-width: 300px;
}

.btn-opcoes {
    min-width: 15px !important;
}

.tableTools-container {
    margin-bottom: 5px;

    .dt-buttons {
        background-color: @cor_fundo_menu;
        border-color: lighten(@cor_fundo_menu, 20%);

        .dt-button {
            border: 1px solid @cor_rodape_menu_link;
        }
    }
}

.campo-arquivo {
    padding-left: 10px;
    padding-right: 10px;
    display: inline-block;
    position: relative;

    & i {
        font-size: 2em;
        padding-left: 10px;
        padding-right: 10px;
        float: left;
    }

    & span {
        height: 26px;
        vertical-align: middle;
        display: inline-block;
        margin: 0;
        padding: 0;
        float: left;
        line-height: 2;

        &:last-of-type {
            padding-left: 15px;
            color: #172d44;
            font-style: italic;
            display: none;

            &:before {
                content: "\f016";
                font-family: FontAwesome;
                margin-right: 5px;
            }
        }
    }
}

.cke, .cke_combo_button, .cke_toolgroup {
    border-radius: 0 !important;
}

.input-group-addon {
    border-radius: 0px;
}

.tab-pane {
    padding-top: 10px;
}

.form-inline {
    .form-group {
        margin-bottom: 10px;
        vertical-align: middle;
        margin-left: 5px;
        margin-right: 5px;
    }

    div[class^="col-md-"] {
        .form-group, .form-control, .input-group {
            width: 100%;
        }
    }
}

.dataTable {
    tr.even {
        td {
            color: @cor_texto_linha2_datatable;
            background-color: @cor_linha2_datatable;
        }
    }
    tr.odd {
        td {
            color: @cor_texto_linha1_datatable;
            background-color: @cor_linha1_datatable;
        }
    }
    tr.even.selected {
        td {
            color: @cor_texto_linha2_selecionada_datatable;
            background-color: @cor_linha2_selecionada_datatable;
        }
    }
    tr.odd.selected {
        td {
            color: @cor_texto_linha1_selecionada_datatable;
            background-color: @cor_linha1_selecionada_datatable;
        }
    }
}

.form-control {
    color: @cor_inputs;
}

.control-label {
    color: @cor_labels;
}

.fc-event {
    font-size: .85em;
    line-height: 1.3;
    border-radius: 1px;
    border: 1px solid @cor_borda_evento_agenda;
    background-color: @cor_fundo_evento_agenda;
    font-weight: 400;
    color: @cor_texto_evento_agenda;

    &:hover {
        color: darken(@cor_texto_evento_agenda, 20%);
    }
}

.ui-widget {
    .fc-event {
        color: @cor_texto_evento_agenda;
    }
}

.fc-unthemed {
    .fc-today {
        background: @cor_dia_atual_agenda;
    }
}

.fc-button-group {
    button {
        border-radius: 0px !important;
    }
}

.evento-finalizado {
    background-color: @cor_fundo_evento_finalizado_agenda;
    color: @cor_texto_evento_finalizado_agenda;
    border: 0px;
}

.evento-atrasado {
    background-color: @cor_fundo_evento_atrasado_agenda;
    color: @cor_texto_evento_atrasado_agenda;
    border: 0px;
}

.evento-hoje {
    background-color: @cor_fundo_evento_hoje_agenda;
    color: @cor_texto_evento_hoje_agenda;
    border: 0px;
}

.evento-cpa {
    background-color: @cor_fundo_evento_cpa_agenda;
    color: @cor_texto_evento_cpa_agenda;
    border: 0px;
}

.evento-cpa:before {
    content: "\f146";
    font-family: @font_awesome;
}

.evento-cre {
    background-color: @cor_fundo_evento_cre_agenda;
    color: @cor_texto_evento_cre_agenda;
    border: 0px;
}

.evento-cre:before {
    content: "\f0fe";
    font-family: @font_awesome;
}

.meu-evento::before {
    color: @cor_icone_meu_evento;
    content: "\f007";
    font-family: @font_awesome;
}

/***************** Nestable *******************/
.cf:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
}

.small {
    color: #666;
    font-size: 0.875em;
}

.large {
    font-size: 1.25em;
}

/**
 * Nestable
 */
.dd {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    max-width: 600px;
    list-style: none;
    font-size: 13px;
    line-height: 20px;
}

.dd-list {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.dd-list .dd-list {
    padding-left: 30px;
}

.dd-collapsed .dd-list {
    display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
    min-height: 20px;
    font-size: 13px;
    line-height: 20px;
}

.dd-handle {
    display: block;
    height: 30px;
    margin: 5px 0;
    padding: 5px 10px;
    color: #333;
    text-decoration: none;
    font-weight: bold;
    border: 1px solid #ccc;
    background: #fafafa;
    background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
    background: -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
    background: linear-gradient(top, #fafafa 0%, #eee 100%);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.dd-handle:hover {
    color: #2ea8e5;
    background: #fff;
}

.dd-item > button {
    display: block;
    position: relative;
    cursor: pointer;
    float: left;
    width: 25px;
    height: 20px;
    margin: 5px 0;
    padding: 0;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 0;
    background: transparent;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    font-weight: bold;
}

.dd-item > button:before {
    content: '+';
    display: block;
    position: absolute;
    width: 100%;
    text-align: center;
    text-indent: 0;
}

.dd-item > button[data-action="collapse"]:before {
    content: '-';
}

.dd-placeholder,
.dd-empty {
    margin: 5px 0;
    padding: 0;
    min-height: 30px;
    background: #f2fbff;
    border: 1px dashed #b6bcbf;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.dd-empty {
    border: 1px dashed #bbb;
    min-height: 100px;
    background-color: #e5e5e5;
    background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
    -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-image: -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
    -moz-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff),
    linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
    background-size: 60px 60px;
    background-position: 0 0, 30px 30px;
}

.dd-dragel {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
}

.dd-dragel > .dd-item .dd-handle {
    margin-top: 0;
}

.dd-dragel .dd-handle {
    -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, .1);
}

/**
 * Nestable Extras
 */
.nestable-lists {
    display: block;
    clear: both;
    padding: 30px 0;
    width: 100%;
    border: 0;
    border-top: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
}

#nestable-menu {
    padding: 0;
    margin: 20px 0;
}

#nestable-output,
#nestable2-output {
    width: 100%;
    height: 7em;
    font-size: 0.75em;
    line-height: 1.333333em;
    font-family: Consolas, monospace;
    padding: 5px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

#nestable2 .dd-handle {
    color: #fff;
    border: 1px solid #999;
    background: #bbb;
    background: -webkit-linear-gradient(top, #bbb 0%, #999 100%);
    background: -moz-linear-gradient(top, #bbb 0%, #999 100%);
    background: linear-gradient(top, #bbb 0%, #999 100%);
}

#nestable2 .dd-handle:hover {
    background: #bbb;
}

#nestable2 .dd-item > button:before {
    color: #fff;
}

@media only screen and (min-width: 700px) {
    .dd {
        float: left;
        width: 48%;
    }

    .dd + .dd {
        margin-left: 2%;
    }
}

.dd-hover > .dd-handle {
    background: #2ea8e5 !important;
}

/**
 * Nestable Draggable Handles
 */
.dd3-content {
    display: block;
    height: 30px;
    margin: 5px 0;
    padding: 5px 10px 5px 40px;
    color: #333;
    text-decoration: none;
    font-weight: bold;
    border: 1px solid #ccc;
    background: #fafafa;
    background: -webkit-linear-gradient(top, #fafafa 0%, #eee 100%);
    background: -moz-linear-gradient(top, #fafafa 0%, #eee 100%);
    background: linear-gradient(top, #fafafa 0%, #eee 100%);
    -webkit-border-radius: 3px;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.dd3-content:hover {
    color: #2ea8e5;
    background: #fff;
}

.dd-dragel > .dd3-item > .dd3-content {
    margin: 0;
}

.dd3-item > button {
    margin-left: 30px;
}

.dd3-handle {
    position: absolute;
    margin: 0;
    left: 0;
    top: 0;
    cursor: pointer;
    width: 30px;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    border: 1px solid #aaa;
    background: #ddd;
    background: -webkit-linear-gradient(top, #ddd 0%, #bbb 100%);
    background: -moz-linear-gradient(top, #ddd 0%, #bbb 100%);
    background: linear-gradient(top, #ddd 0%, #bbb 100%);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.dd3-handle:before {
    content: '≡';
    display: block;
    position: absolute;
    left: 0;
    top: 3px;
    width: 100%;
    text-align: center;
    text-indent: 0;
    color: #fff;
    font-size: 20px;
    font-weight: normal;
}

.dd3-handle:hover {
    background: #ddd;
}

/***************** FIM Nestable *******************/

//Campo OnOff
.switchery {
    &.switchery-small {
        background-color: @cor_borda_menus !important;
        border-color: @cor_borda_menus !important;
        box-shadow: @cor_borda_menus 0px 0px 0px 11px inset !important;
    }
}

.negrito {
    font-weight: bold;
}

.widget-loading {
    color: @cor_nome_usuario;
}

.importacao-noticia {
    .titulo {
        color: @cor_fundo_link_selecionado;
        font-weight: bold;
        padding-left: 15px;
        padding-top: 15px;

        p {
            font-size: 1.1em;
            color: @cor_borda_menus;
        }
    }
}

.noticia-completa {
    padding: 20px;

    p {
        padding: 10px 5px !important;
        line-height: 20px !important;
    }

    a {
        font-weight: bold;
    }

    .titulo {
        color: @cor_fundo_link_selecionado;
        font-weight: bold;
        padding: 5px;
    }
}

input[type="checkbox"] {
    margin-top: 0px;
}

.nomeUsuarioLogoffViaID {
    padding: 20px;
    font-weight: bold;
    font-size: 1.2em;
    color: @cor_linha_links;
}

.select2-container--default {
    .select2-selection--multiple {
        .select2-selection__choice__remove {
            color: #585858;
            margin-right: 0px;
            padding-right: 5px;
            font-size: 1.2em;
        }
    }

}

.imgBandeira {
    max-width: 200px;
    -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75);
}

.limpar_arquivo {
    display: block;
    padding-left: 0;
    margin-left: 0;
}